import React, { Fragment, useState, useEffect } from "react";
import { API } from "@aws-amplify/api";
import styled from "styled-components";
import TodayRecommend from "../AdminComponents/BannerManageItem/TodayRecommend";
function BannerManage() {
  return (
    <Fragment>
      <Container>
        <ManageBox>
          <ManageBoxTitle>오늘의 추천 편집</ManageBoxTitle>
          <TodayRecommend />
        </ManageBox>
      </Container>
    </Fragment>
  );
}

export default BannerManage;
const a = styled.div``;
const ManageBoxTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: black;

  box-sizing: border-box;
`;

const ManageBox = styled.div`
  width: 100%;
  min-height: 300px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  background-color: white;
  padding: 30px;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 50px;
  box-sizing: border-box;
`;
