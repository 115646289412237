import React, { useEffect, useState, Fragment } from "react";
import { API } from "@aws-amplify/api";

import PostPresenter from "./PostPresenter";
import { useSelector } from "react-redux";
import { retrieveCurrentAuthenticatedUser } from "../../utils";
function PostContainer({ match }) {
  const [Posts, setPosts] = useState([]);
  const Id = match.params.PostId.split(":")[1];
  const UserName = useSelector((state) => state.user.Data);
  const [LoginStatus, setLoginStatus] = useState(false);
  useEffect(() => {
    GetAlcoholPost();
    retrieveCurrentAuthenticatedUser().then((user) => {
      // setUserInfo(JSON.stringify(userInfo));
      if (user.status > 400) {
        // console.log("fail");
      }
      if (user.status === 200) {
        setLoginStatus(true);
      }
    });
  }, []);
  const GetAlcoholPost = async () => {
    const ApiResponse = await API.get("AlcoholPostApi", "/items/");
    // console.log(ApiResponse);
    setPosts(ApiResponse);
    // ApiResponse.map((data) => {
    //   if (data.id === Id) {
    //     setPosts(data);
    //   }
    // });
  };
  // console.log(match);
  // console.log(Posts);
  return (
    <Fragment>
      <PostPresenter
        PostId={Id}
        Posts={Posts}
        UserInfo={UserName}
        LoginStatus={LoginStatus}
      />
    </Fragment>
  );
}

export default PostContainer;
