import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
////////////////////////////////////////////////////////////////
import styled, { keyframes } from "styled-components";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineLoading } from "react-icons/ai";
////////////////////////////////////////////////////////////////
import SingleComment from "./SingleComment";
import ReviewPresenter from "../PostUtil/ReviewPresenter";
import { useSelector } from "react-redux";
////////////////////////////////////////////////////////////////
import { API } from "@aws-amplify/api";
////////////////////////////////////////////////////////////////

function MyComment({ UserInfo, LoginStatus, PostId, UserAge, UserGender }) {
  const history = useHistory();
  const [text, setText] = useState("");
  // const [CommentsList, setCommentsList] = useState(Comments);
  const [Comments, setComments] = useState([]);
  // console.log("filter" + Comments);
  const [IsLoading, setIsLoading] = useState(false);
  const UserEmail = useSelector((state) => state.user.Email);
  useEffect(() => {
    GetComment();
  }, []);
  const GetComment = async () => {
    const ApiResponse = await API.get("CommentApi", "/items/", PostId);
    if (ApiResponse) {
      setIsLoading(true);
    }
    setComments(ApiResponse);
  };
  // const ReviewBoxCheck = Review.map((data) => {
  //   if (data.User===UserInfo) {
  //     setReviewed(true);
  //   } else setReviewed(false);
  //   return null;
  // });
  const getText = (e) => {
    setText(e.target.value);
  };
  ///delete///
  // const ApiResponse = await API.del(
  //   "CommentApi",
  //   "/items/object/" + 1593435341707
  // );
  const onSubmit = async (e) => {
    let date = new Date();
    let UploadTime =
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + date.getDate()).slice(-2) +
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2);
    if (LoginStatus) {
      e.preventDefault();
      const ApiResponse = await API.post("CommentApi", "/items/", {
        body: {
          id: Number(Date.now()),
          text: text,
          user: UserInfo,
          postId: PostId,
          email: UserEmail,
          time: UploadTime,
          age: Number(UserAge),
          gender: UserGender,
        },
      });
      // console.log(ApiResponse);
      // let NewComment = { user: UserInfo, text: text };
      // setComments(Comments.concat(NewComment));
      setText("");
      window.location.reload();
    } else {
      alert("로그인 후 이용가능합니다.");
      history.push("/Signin");
    }
  };
  const checkNoComment = Comments.filter((v) => {
    return v.postId === PostId;
  });
  // console.log("checkNoComment" + checkNoComment);
  return (
    <Fragment>
      <NonMobileWrapper>
        <ReviewPresenter PostId={PostId} />
      </NonMobileWrapper>
      <Wrapper>
        <MyReplyBox>
          <MyReplyContainer>
            <MyReply>
              <IconContainer>
                <MyIcon>
                  <AiOutlineUser size={32} />
                </MyIcon>
              </IconContainer>
              <Input
                placeholder="댓글을 남기세요."
                onChange={getText}
                value={text}
              ></Input>
            </MyReply>
          </MyReplyContainer>
          <ButtonBox>
            <Button onClick={onSubmit}>등록</Button>
          </ButtonBox>
        </MyReplyBox>
      </Wrapper>

      {IsLoading && (
        <SingleComment
          CommentsList={Comments}
          setComments={setComments}
          PostId={PostId}
          UserInfo={UserInfo}
          UserAge={UserAge}
          UserGender={UserGender}
          checkNoComment={checkNoComment}
        />
      )}
      {!IsLoading && (
        <LoadingContainer>
          <Loading>
            <AiOutlineLoading size={32} />
          </Loading>
        </LoadingContainer>
      )}
    </Fragment>
  );
}

export default MyComment;
const NonMobileWrapper = styled.div`
  @media (min-width: 640px) {
    display: none;
  }
`;
const MyReplyBox = styled.div``;
const ReviewedDataBox = styled.div`
  width: 300px;
  min-height: 200px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
`;
const rotation = keyframes`
    0% {
      transform: rotate(0deg);
      opacity: 0.1;
    }
    25% {
      transform: rotate(90deg);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.1;
    }
    75% {
      transform: rotate(360deg);
      opacity: 1;
    }
  `;
const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Loading = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotation} 1s infinite linear;
`;
const IconContainer = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
  @media (max-width: 640px) {
    display: none;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  /* border: 1px solid red; */
  /* padding-right: 100px; */
  box-sizing: border-box;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Button = styled.button`
  cursor: pointer;
  outline: none;
  width: 90px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ededed;
  color: gray;
  padding-bottom: 0px;
  padding-top: 0px;
  font-size: 16px;
  font-family: "Noto Sans KR", sans-serif;
  &:hover {
    opacity: 0.8;
  }
`;

const Input = styled.textarea`
  resize: none;
  margin-left: 20px;
  min-height: 40px;
  width: 100%;
  height: 70px;
  outline: none;
  word-break: break-all;
  border-radius: 4px;
  border: 1px solid #ededed;
  padding: 8px;
  font-size: 16px;
  &::placeholder {
    color: lightgray;
    font-size: 16px;
    font-family: "Noto Sans KR", sans-serif;
  }
  &:hover {
    &::placeholder {
      color: gray;
    }
  }
  @media (max-width: 640px) {
    margin-left: 0px;
  }
`;

const MyReplyContainer = styled.div``;

const MyReply = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
`;
const MyIcon = styled.div`
  background-color: lightgray;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;
