import React, { Fragment, useState, useEffect } from "react";
import { FcAbout } from "react-icons/fc";
import { RiCheckboxBlankLine } from "react-icons/ri";
import { RiCheckboxLine } from "react-icons/ri";
import styled, { keyframes } from "styled-components";
import { API } from "@aws-amplify/api";
import { Review } from "./PostDummy";
function PostTaste({
  setReviewedNum,
  PostId,
  setReviewData,
  ReviewData,
  ReviewedNum,
}) {
  const [IsLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // console.log("PostId" + PostId);
    GetReviewData();
  }, []);

  const GetReviewData = async () => {
    const ApiResponse = await API.get("AlcoholReviewApi", "/items/");
    // if (ApiResponse) {
    //   setIsLoading(true);
    // }

    const PostReview = ApiResponse.filter((data) => data.postId === PostId);
    setReviewData(PostReview);
    setReviewedNum(PostReview.length);
  };
  let Sweet = ReviewData.reduce((prev, curr) => prev + curr.sweetClicked, 0);
  let Acid = ReviewData.reduce((prev, curr) => prev + curr.acidClicked, 0);
  let Alcohol = ReviewData.reduce(
    (prev, curr) => prev + curr.alcoholClicked,
    0
  );
  let Weight = ReviewData.reduce((prev, curr) => prev + curr.weightClicked, 0);
  let Carbonate = ReviewData.reduce(
    (prev, curr) => prev + curr.carbonatedClicked,
    0
  );

  const value1 = !(Sweet / ReviewData.length) ? 0 : Sweet / ReviewData.length;
  const value2 = !(Acid / ReviewData.length) ? 0 : Acid / ReviewData.length;
  const value3 = !(Alcohol / ReviewData.length)
    ? 0
    : Alcohol / ReviewData.length;
  const value4 = !(Weight / ReviewData.length) ? 0 : Weight / ReviewData.length;
  const value5 = !(Carbonate / ReviewData.length)
    ? 0
    : Carbonate / ReviewData.length;
  // console.log(value1);
  // console.log(value2);
  // console.log(value3);
  // console.log(value4);
  // console.log(value5);
  return (
    <Fragment>
      {ReviewData.length >= 1 && (
        <TasteContainer>
          {/*<PinContainer>
          <FcAbout size={40} />
        </PinContainer>*/}

          <TasteBox>
            <Fragment>
              <BasicInfoWrapper>
                <TasteWrapper>
                  <TasteTitle>부재료 유무</TasteTitle>
                  <IconWraper>
                    <IconBox>
                      <CheckOn />
                    </IconBox>
                  </IconWraper>
                </TasteWrapper>
                <TasteWrapper>
                  <TasteTitle>첨가물 유무</TasteTitle>
                  <IconWraper>
                    <IconBox>
                      <CheckOff />
                    </IconBox>
                  </IconWraper>
                </TasteWrapper>
              </BasicInfoWrapper>
              <ValueContainer>
                <TasteTitle>당도</TasteTitle>
                <ValueBar>
                  <Value value={value1} />
                </ValueBar>
              </ValueContainer>
              <ValueContainer>
                <TasteTitle>산도</TasteTitle>
                <ValueBar>
                  <Value value={value2} />
                </ValueBar>
              </ValueContainer>
              <ValueContainer>
                <TasteTitle>쓴맛</TasteTitle>
                <ValueBar>
                  <Value value={value3} />
                </ValueBar>
              </ValueContainer>
              <ValueContainer>
                <TasteTitle>탄산감</TasteTitle>
                <ValueBar>
                  <Value value={value4} />
                </ValueBar>
              </ValueContainer>
              <ValueContainer>
                <TasteTitle>무게감</TasteTitle>
                <ValueBar>
                  <Value value={value5} />
                </ValueBar>
              </ValueContainer>
              <ValueContainer>
                <ReviewNumBox>
                  총 {ReviewedNum}명이 평가한 내용입니다
                </ReviewNumBox>
              </ValueContainer>
              {/*<Detail>
                <DetailTitle onClick={() => setDetailCheck(false)}>
                  상세 정보
                </DetailTitle>
              </Detail>*/}
            </Fragment>

            {/*
          {!DetailCheck && (
            <Fragment>
              <CircleChart />
              <Detail>
                <DetailTitle onClick={() => setDetailCheck(true)}>
                  뒤로 가기
                </DetailTitle>
              </Detail>
            </Fragment>
          )}*/}
          </TasteBox>
        </TasteContainer>
      )}
    </Fragment>
  );
}
export default PostTaste;
const ReviewNumBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 12px;
  color: #84868e;
`;
const BasicInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Move = (v) => keyframes`
from{
    width:0%
}
to {
    width:${v}%
}
`;
const CheckOn = styled(RiCheckboxLine)`
  width: 30px;
  height: 30px;
  color: #84868e;
  @media (max-width: 640px) {
    width: 25px;
    height: 25px;
  }
`;
const CheckOff = styled(RiCheckboxBlankLine)`
  width: 30px;
  height: 30px;
  color: #84868e;
  @media (max-width: 640px) {
    width: 25px;
    height: 25px;
  }
`;
const IconWraper = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TasteWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const Value = styled.div`
  width: ${(props) => props.value}%;
  height: 100%;
  background-image: linear-gradient(30deg, rgb(67, 115, 110), rgb(5, 38, 38));
  border-radius: 20px;
  animation: ${(props) => Move(props.value)} 1.5s backwards;
`;
const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  @media (max-width: 640px) {
    width: 30px;
  }
`;
const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ValueBar = styled.div`
  width: 100%;
  height: 20px;
  border: 1px solid #f2f2f2;
  border-radius: 20px;
  @media (max-width: 640px) {
    height: 20px;
  }
`;
const TasteTitle = styled.div`
  margin: 15px 0px;
  font-size: 16px;
  width: 120px;
  color: #84868e;
  font-weight: bold;
  @media (max-width: 640px) {
    font-size: 13px;
  }
`;
const TasteBox = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* height: 100%; */
  box-sizing: border-box;
`;
const PinContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const TasteContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 43px;
  /* margin-right: 50px; */
  box-sizing: border-box;
`;
