import PostUploadPresenter from "./PostUploadPresenter";
import React, { Fragment, useEffect, useState } from "react";
import { API } from "@aws-amplify/api";
import styled, { keyframes } from "styled-components";
import { AiOutlineLoading } from "react-icons/ai";
import Header from "../Header";
function PostUploadContainer({ match }) {
  const [Post, setPosts] = useState([]);
  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    GetAlcoholPost();
  }, []);
  const GetAlcoholPost = async () => {
    const ApiResponse = await API.get("AlcoholPostApi", "/items/");
    setPosts(ApiResponse);
    setLoading(false);
  };
  const Id = match.params.PostId.split(":")[1];

  return (
    <Fragment>
      {Loading && (
        <LoadingContainer>
          <LoadingIcon>
            <AiOutlineLoading size={32} />
          </LoadingIcon>
        </LoadingContainer>
      )}
      {!Loading && <PostUploadPresenter PostId={Id} Post={Post} />}
    </Fragment>
  );
}

export default PostUploadContainer;
const rotation = keyframes`
    0% {
      transform: rotate(0deg);
      opacity: 0.1;
    }
    25% {
      transform: rotate(90deg);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.1;
    }
    75% {
      transform: rotate(360deg);
      opacity: 1;
    }
  `;
const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoadingIcon = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotation} 1s infinite linear;
`;
