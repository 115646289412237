import * as Yup from "yup";

import {
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { confirmForgotPw, forgotPw } from "../utils";
import {
  labelAuthCode,
  labelEmail,
  labelPassword,
  labelPasswordConfirm,
} from "../labels";

import { Loader } from "../components";
import PropTypes from "prop-types";
import { common } from "@material-ui/core/colors";
import styled from "styled-components";
import theme from "../theme";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

const Form = styled.form`
  width: "100%";
  margin-top: ${theme.spacing(1)};
`;
const ButtonLoaderWrapper = styled.div`
  position: relative;
`;
const BoldButton = styled(Button)`
  font-weight: bold;
`;
const ButtonGrid = styled(Grid)`
  margin: ${theme.spacing(2, 0, 1)};
`;
const AlertMessage = styled(Chip)`
  width: 100%;
`;

const sendConfirmationCode = async (username) => {
  const { data, status } = await forgotPw({ username });
  const { code, message } = data || {};

  // console.log('sendConfirmationCode/forgotPw', { status, data});
  if (status >= 400) {
    if (code.indexOf("InvalidParameterException") > -1) {
      if (
        message.indexOf("registered") > -1 ||
        message.indexOf("verified") > -1
      ) {
        return {
          status,
          errormsg: "가입 시 이메일 인증이 되지 않은 아이디입니다.",
        };
      }
    } else if (code.indexOf("LimitExceededException") > -1) {
      return {
        status,
        errormsg:
          "한 계정으로 너무 많은 시도가 이루어졌습니다. 잠시 후에 다시 시도해보세요.",
      };
    } else if (code.indexOf("UsernotFoundException") > -1) {
      return {
        status,
        errorMsg: "존재하지 않는 사용자입니다.",
      };
    }
  }
  return {
    status: 200,
    data,
  };
};

/**
 * Stop enter submitting the form.
 * @param keyEvent Event triggered when the user presses a key.
 */
const onKeyDown = (keyEvent) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};

const FindPw = (props) => {
  const { onCancel } = props;

  const history = useHistory;
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const userEmail = useRef(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [mailSendStatus, setMailSendStatus] = useState("발송");

  const formikStep0 = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(`올바른 ${labelEmail} 형식이 아닙니다`)
        .required(`${labelEmail}은 필수 입력 항목입니다.`),
    }),
    onSubmit: () => {},
  });

  const formikStep1 = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
      authcode: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "최소 9글자 이상 입력되어야 합니다.")
        .required(`${labelPassword}은 필수 입력 항목입니다.`),
      passwordConfirm: Yup.string()
        .required(`${labelPasswordConfirm}은 필수 입력 항목입니다.`)
        .oneOf(
          [Yup.ref("password"), null],
          `${labelPassword}와 일치해야 합니다.`
        ),
      authCode: Yup.string().required(
        `${labelAuthCode}가 입력되지 않았습니다.`
      ),
    }),
    onSubmit: () => {},
  });

  const resendAction = async () => {
    const { errormsg, status } = await sendConfirmationCode(userEmail.current);
    if (status >= 400) {
      setAlertMessage(errormsg);
    }
    setMailSendStatus("재발송");
  };

  const gotoStep1 = async () => {
    setIsLoading(true);
    // console.log('gotoStep1', formikStep0);
    formikStep0.submitForm();
    if (!formikStep0.isValid) {
      setIsLoading(false);
      return;
    }
    const { errormsg, status } = await sendConfirmationCode(
      formikStep0.values.email
    );

    setIsLoading(false);

    if (status >= 400) {
      formikStep0.setFieldError("email", errormsg);
      return;
    }
    userEmail.current = formikStep0.values.email;
    setStep(1);
  };

  const gotoStep2 = async () => {
    setIsLoading(true);
    // console.log('gotoStep2', formikStep1);
    formikStep1.submitForm();
    if (!formikStep1.isValid) {
      setIsLoading(false);
      return;
    }

    const { status, data } = await confirmForgotPw({
      username: userEmail.current,
      code: formikStep1.values.authcode,
      newPw: formikStep1.values.password,
      clientMetadata: {
        forgotPw: "yes",
      },
    });
    const { code } = data || {};

    setIsLoading(false);

    // console.log('confirmForgotPw', { status, data });
    if (status >= 400) {
      if (code && code.indexOf("CodeMismatchException") > -1) {
        formikStep1.setFieldError(
          "authcode",
          "잘못된 인증번호가 입력되었습니다."
        );
      } else if (code && code.indexOf("UserNotFoundException") > -1) {
        setAlertMessage("잘못된 요청입니다. 처음 단계부터 다시 진행해 주세요.");
      } else {
        setAlertMessage("서버로부터 에러가 발생되었습니다.");
      }
      return;
    }
    setStep(2);
  };

  // rendering per step
  if (step === 0) {
    return (
      <Form noValidate onKeyDown={onKeyDown}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="email"
          label={labelEmail}
          type="text"
          id="email"
          autoComplete="email"
          error={!!formikStep0.errors.email}
          helperText={formikStep0.errors.email}
          onChange={formikStep0.handleChange}
          onBlur={formikStep0.handleBlur}
          value={formikStep0.values.email}
          autoFocus
        />
        {alertMessage && (
          <AlertMessage color="secondary" label={alertMessage} />
        )}
        <ButtonGrid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <BoldButton
            color="primary"
            size="large"
            onClick={onCancel}
          >{`취소`}</BoldButton>
          <ButtonLoaderWrapper>
            <BoldButton
              color="primary"
              size="large"
              disabled={isLoading}
              onClick={gotoStep1}
            >{`다음`}</BoldButton>
            {isLoading && <Loader size={24} />}
          </ButtonLoaderWrapper>
        </ButtonGrid>
      </Form>
    );
  } else if (step === 1) {
    return (
      <Form noValidate>
        <Box
          pl={2}
          mb={1}
          borderRadius={10}
          bgcolor={common.black}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box
            color={common.white}
          >{`인증코드가 ${mailSendStatus} 되었습니다.`}</Box>
          <BoldButton
            color="primary"
            size="large"
            onClick={resendAction}
          >{`재발송`}</BoldButton>
        </Box>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="authcode"
          label={labelAuthCode}
          type="text"
          id="authcode"
          error={formikStep1.touched.authcode && !!formikStep1.errors.authcode}
          helperText={
            formikStep1.touched.authcode && formikStep1.errors.authcode
          }
          onChange={formikStep1.handleChange}
          onBlur={formikStep1.handleBlur}
          value={formikStep1.values.authcode}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label={labelPassword}
          type="password"
          id="password"
          autoComplete="current-password"
          error={formikStep1.touched.password && !!formikStep1.errors.password}
          helperText={
            formikStep1.touched.password && formikStep1.errors.password
          }
          onChange={formikStep1.handleChange}
          onBlur={formikStep1.handleBlur}
          value={formikStep1.values.password}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="passwordConfirm"
          label={labelPasswordConfirm}
          type="password"
          id="passwordConfirm"
          autoComplete="current-password"
          error={
            formikStep1.touched.passwordConfirm &&
            !!formikStep1.erros.passwordConfirm
          }
          helperText={
            formikStep1.touched.passwordConfirm &&
            formikStep1.erros.passwordConfirm
          }
          onChange={formikStep1.handleChange}
          onBlur={formikStep1.handleBlur}
          value={formikStep1.values.passwordConfirm}
        />
        {alertMessage && (
          <AlertMessage color="secondary" label={alertMessage} />
        )}
        <ButtonGrid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <BoldButton
            color="primary"
            size="large"
            onClick={onCancel}
          >{`취소`}</BoldButton>
          <ButtonLoaderWrapper>
            <BoldButton
              color="primary"
              size="large"
              disabled={isLoading}
              onClick={gotoStep2}
            >{`비밀번호 변경하기`}</BoldButton>
            {isLoading && <Loader size={24} />}
          </ButtonLoaderWrapper>
        </ButtonGrid>
      </Form>
    );
  } else {
    const toLoginPage = () => {
      history.push("/");
    };
    return (
      <>
        <Typography component="h3" variant="subtitle1" gutterBottom>
          회원님의 비밀번호가 정상적으로 변경되었습니다.
        </Typography>
        <ButtonGrid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <BoldButton
            color="primary"
            size="large"
            onClick={toLoginPage}
          >{`로그인 페이지로`}</BoldButton>
        </ButtonGrid>
      </>
    );
  }
};

FindPw.propTypes = {
  onCancel: PropTypes.func,
};

export { FindPw };
