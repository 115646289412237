import React, { Fragment } from "react";
import styled from "styled-components";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaApple } from "react-icons/fa";

function Footer() {
  return (
    <Fragment>
      <FooterContainer>
        <TextContainer>
          <InfoLinkBox>
            <InfoLink>사업자정보 확인 | 이용약관 | 개인정보처리방침</InfoLink>
            <SnsLink>
              <SnsIconBox>
                <SnsIcon>
                  <FaFacebookF size={18} color="white" />
                </SnsIcon>
                <SnsIcon>
                  <FaInstagram size={18} color="white" />
                </SnsIcon>
                <SnsIcon>
                  <FaApple size={18} color="white" />
                </SnsIcon>
              </SnsIconBox>
            </SnsLink>
          </InfoLinkBox>
          <BasicInfoBox>
            <BasicInfo>
              (주) 내술노트 | 사업자등록번호 : 123-12-12345
            </BasicInfo>
          </BasicInfoBox>
          <Right>© 내술노트 corp. All rights reserved</Right>
        </TextContainer>
      </FooterContainer>
    </Fragment>
  );
}

export default Footer;
const a = styled.div``;
const BasicInfo = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #666666;
`;
const BasicInfoBox = styled.div`
  margin-bottom: 20px;
`;

const InfoLinkBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 640px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

const SnsIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SnsIcon = styled.div`
  cursor: pointer;
  margin: 0px 10px;
  width: 40px;
  height: 40px;
  background-color: lightgray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SnsLink = styled.div``;

const InfoLink = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 20px;
`;
const TextContainer = styled.div`
  font-size: 24px;
  width: 1080px;
`;
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  height: 230px;
  background-color: white;
  padding: 0px 10px;
  box-sizing: border-box;
  @media (max-width: 640px) {
    border-top: 1px solid #f2f2f2;
  }
`;
const Right = styled.div`
  margin-top: 50px;
  width: 100%;
  font-size: 13px;
  font-weight: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-weight: bold; */
`;
