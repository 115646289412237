import React, { useState, Fragment, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "@aws-amplify/api";
import { AiOutlineLoading } from "react-icons/ai";

function NoticeExplorePresenter() {
  let Admin = useSelector((state) => state.user.Admin);
  const [IsLoading, setIsLoading] = useState(false);
  const [SearchTerm, setSearchTerm] = useState("");
  const [Posts, setPosts] = useState([]);
  useEffect(() => {
    GetNoticePost();
  }, []);
  const GetNoticePost = async () => {
    const ApiResponse = await API.get("NoticeApi", "/items/");
    setPosts(ApiResponse);
    setIsLoading(true);
  };
  const PostsFilter = Posts.filter(
    (data) =>data.article.includes(SearchTerm)
  );
  const PostSort = PostsFilter.sort((a, b) =>
    a.id < b.id ? -1 : a.id > b.id ? 1 : 0
  );
  const RanderNotices = PostSort.map((data) => {
    return (
      <Fragment>
        <NoticeTable>
          <TableIndex>
            {PostSort.findIndex((i) => i.id == data.id) + 1}
          </TableIndex>
          <TableTitle to={`/Notice/:${data.id}`}>{data.title}</TableTitle>
          <TableDate>{data.time}</TableDate>
        </NoticeTable>
        <Hr />
      </Fragment>
    );
  });
  const onSubmit = (e) => {
    e.preventDefault();
    alert("검색어 : " + "" + SearchTerm);
  };
  const KeyboardEvt = (e) => {
    if (e && e.keyCode === 13) {
      onSubmit();
    }
  };
  return (
    <Fragment>
      <NoticeContainer>
        <NoticeWrapper>
          <NoticeTitle to="/Notice">공지사항</NoticeTitle>
          <NoticeSearchContainer onSubmit={onSubmit}>
            <SearchBar
              placeholder="검색어를 입력해주세요"
              value={SearchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={KeyboardEvt}
            />
            {/*<SearchButton onClick={onSubmit}>검색</SearchButton>*/}
          </NoticeSearchContainer>
          {IsLoading && (
            <NoticeTableTitle>
              <TableIndex>No</TableIndex>
              <TableTitle>제목</TableTitle>
              <TableDate>날짜</TableDate>
            </NoticeTableTitle>
          )}
          {!IsLoading && (
            <Fragment>
              <LoadingContainer>
                <LoadingIcon>
                  <AiOutlineLoading size={32} />
                </LoadingIcon>
              </LoadingContainer>
            </Fragment>
          )}
          {RanderNotices}
        </NoticeWrapper>
        {Admin && (
          <ButtonContainer>
            <UploadButton to="/NoticeUpload">글쓰기</UploadButton>
          </ButtonContainer>
        )}
      </NoticeContainer>
    </Fragment>
  );
}

export default NoticeExplorePresenter;
const rotation = keyframes`
    0% {
      transform: rotate(0deg);
      opacity: 0.1;
    }
    25% {
      transform: rotate(90deg);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.1;
    }
    75% {
      transform: rotate(360deg);
      opacity: 1;
    }
  `;
const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoadingIcon = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotation} 1s infinite linear;
`;
const SearchButton = styled.div`
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 35px;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  background-color: gray;
`;
const SearchBar = styled.input`
  outline: none;
  font-size: 16px;
  width: 200px;
  height: 35px;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 3px;
  box-sizing: border-box;
  margin-right: 10px;
  padding: 0px 10px;
  &::placeholder {
    font-size: 14px;
  }
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const UploadButton = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 45px;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  background-color: gray;
`;
const Hr = styled.div`
  width: 100%;
  height: 1px;
  background-color: lightgray;
`;
const TableDate = styled.div`
  width: 100px;
  text-align: center;
`;
const TableTitle = styled(Link)`
  color: black;
  text-decoration: none;
`;
const TableIndex = styled.div`
  width: 50px;
  text-align: center;
`;
const NoticeTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
`;
const NoticeTableTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  margin-top: 20px;
  width: 100%;
  height: 50px;
  border: 1px solid black;
  border-left: none;
  border-right: none;
  box-sizing: border-box;
`;
const NoticeSearchContainer = styled.form`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0px 30px;
  background-color: #f4f4f2;
  border-radius: 10px;
  margin-top: 20px;
  box-sizing: border-box;
`;
const NoticeTitle = styled(Link)`
text-decoration:none;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: black;
`;
const NoticeWrapper = styled.div`
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* border: 2px solid red; */
  box-sizing: border-box;
`;
const NoticeContainer = styled.div`
  margin-top: 80px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px red solid; */
  box-sizing: border-box;
  padding: 20px 20px;
`;
