import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import TermsForm from "./SignUpComponents/TermsForm";
import { UserFormConfirm } from "./SignUpComponents/UserFormConfirm";
// import { UserFormRegister } from "./SignUpBackup/UserFormRegister";
import UserFormRegister from "./SignUpComponents/UserFormRegister";
import { useHistory, Link } from "react-router-dom";

function SignUp() {
  const userProfileObj = {
    email: "",
    password: "",
    passwordConfirm: "",
    name: "",
  };
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [userProfile, setUserProfile] = useState(userProfileObj);
  const onCancel = () => {
    history.push("/signin");
  };

  const goNextStep = (profile) => {
    setUserProfile(profile);
    setStep(2);
  };

  const onBackAction = () => {
    setStep(1);
  };

  const goFinish = () => {
    setStep(3);
  };

  return (
    <Fragment>
      <Helmet>
        <title>내술 노트 | 회원 가입</title>
      </Helmet>
      <SignUpContainer>
        <SignUpWrapper>
          {step === 0 && <TermsForm setStep={setStep} />}
          {step === 1 && (
            <UserFormRegister
              userProfile={userProfile}
              onCancel={onCancel}
              goNextStep={goNextStep}
            />
          )}
          {step === 2 && (
            <UserFormConfirm
              userProfile={userProfile}
              onBackAction={onBackAction}
              goFinish={goFinish}
            />
          )}
          {step === 3 && (
            <FinishBox>
              <FinishTitle>회원가입이 정상적으로 완료 되었습니다.</FinishTitle>
              <LoginLink to="/Signin">로그인 하기</LoginLink>
            </FinishBox>
          )}
        </SignUpWrapper>
      </SignUpContainer>
    </Fragment>
  );
}

export default SignUp;
const FinishBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const FinishTitle = styled.div``;
const LoginLink = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 140px;
  height: 40px;
  border: 1px solid gray;
  background-color: white;
  color: black;
`;
const SignUpWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  box-sizing: border-box;
  width: 450px;
  min-height: 600px;
  padding: 40px;
  background-color: #fafafa;
  @media (max-width: 640px) {
    padding: 40px 15px;
  }
`;
const SignUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  /* border: 1px solid red; */
  max-width: 1000px;
  min-height: 600px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Noto Sans KR", sans-serif;
`;
