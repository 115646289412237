import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Term1Article } from "./TermsArticle";
import { Term2Article } from "./TermsArticle";
function TermsForm({ setStep }) {
  const [Term1, setTerm1] = useState(false);
  const [Term2, setTerm2] = useState(false);
  const [TermsAll, setTermsAll] = useState(false);
  const TermAll = () => {
    setTerm1(!Term1);
    setTerm2(!Term2);
    setTermsAll(!TermsAll);
  };
  const NextStep = () => {
    if (Term1 === true && Term2 === true) {
      setStep(1);
    } else {
      alert("이용약관 및 개인정보 처리방침에 동의하셔야 가입이 가능합니다.");
    }
  };
  return (
    <Fragment>
      <FormConatainer>
        <Title>약관 동의</Title>
        <CheckList>
          <CheckBasic onClick={TermAll}>
            <CheckButton color={TermsAll} />
            <CheckTitle>
              이용약관, 개인정보 수집 및 이용에 모두 동의합니다.
            </CheckTitle>
          </CheckBasic>
        </CheckList>
        <CheckList>
          <CheckBasic onClick={() => setTerm1(!Term1)}>
            <CheckButton color={Term1} />
            <CheckTitle>
              이용약관 동의{" "}
              <div style={{ color: "red", marginLeft: "10px" }}>(필수)</div>
            </CheckTitle>
          </CheckBasic>
          <CheckArticle>
            <Term1Article />
          </CheckArticle>
        </CheckList>
        <CheckList>
          <CheckBasic onClick={() => setTerm2(!Term2)}>
            <CheckButton color={Term2} />
            <CheckTitle>
              개인정보 수집 및 이용{" "}
              <div style={{ color: "red", marginLeft: "10px" }}>(필수)</div>
            </CheckTitle>
          </CheckBasic>
          <CheckArticle>
            <Term2Article />
          </CheckArticle>
        </CheckList>
        <ButtonArea>
          <Button>취소</Button>
          <Button onClick={NextStep}>가입하기</Button>
        </ButtonArea>
      </FormConatainer>
    </Fragment>
  );
}

export default TermsForm;
const ButtonArea = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  border: 1px solid lightgray;
  outline: none;
  width: 160px;
  height: 50px;
  background-color: white;
`;
const CheckBasic = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;
const CheckTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
`;
const CheckArticle = styled.div`
  width: 100%;
  height: 100px;
  overflow: scroll;
  background-color: white;
`;
const CheckButton = styled.div`
  border: 1px solid gray;
  width: 15px;
  height: 15px;
  margin-right: 20px;
  background-color: ${(props) => (props.color ? "gray" : "white")};
`;
const CheckList = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;
`;

const FormConatainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* border: 1px solid blue; */
  background-color: #fafafa;
  padding: 10px;
`;
