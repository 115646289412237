import React, { Fragment } from "react";
import NoticePresenter from "./NoticePresenter";
function NoticeContainer({ match }) {
  const NoticeId = match.params.NoticeId.split(":")[1];
  // console.log(NoticeId);
  return (
    <Fragment>
      <NoticePresenter NoticeId={NoticeId} />
    </Fragment>
  );
}

export default NoticeContainer;
