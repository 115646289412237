import React, { Fragment, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { getDashedValue, signUp } from "../../utils";
import { AiOutlineCheckCircle } from "react-icons/ai";
function UserFormRegister({ userProfile, onCancel, goNextStep }) {
  const [Gender, setGender] = useState("");
  const [Email, setEmail] = useState("");
  const [Name, setName] = useState("");
  const [Age, setAge] = useState("20");
  const [PassWord, setPassWord] = useState("");
  const [PassWordConfirm, setPassWordConfirm] = useState("");
  // console.log(Gender, Email, Name, PassWord, PassWordConfirm, Age);
  const onNextStep = async () => {
    if (PassWord === PassWordConfirm) {
      const { data, status } = await signUp({
        username: Email,
        name: Name,
        password: PassWord,
        email: Email,
        gender: Gender,
        age: Age,
      });

      // console.log("onNetStep/SignUp", { status, data });

      if (status === 200) {
        goNextStep({
          name: Name,
          password: PassWord,
          PasswordConfirm: PassWordConfirm,
          email: Email,
          gender: Gender,
          age: Age,
        });
      } else if (data.code === "UsernameExistsException") {
        alert("입력된 이메일이 이미 존재합니다.");
      } else if (data.code === "Invalid Password Exception") {
        alert(
          "유효하지 않은 비밀번호입니다. 문자와 숫자가 섞여 있어야 합니다."
        );
      }
    } else {
      alert("패스워드가 동일하지 않습니다.");
    }
  };
  return (
    <Fragment>
      <Container>
        <ContainerTitle>회원가입</ContainerTitle>
        <FormBox>
          <FormInput
            placeholder="이메일을 입력해주세요"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
          ></FormInput>
          {Email === "" ? (
            <AlertText>이메일이 입력되지 않았습니다.</AlertText>
          ) : (
            <AlertText>
              <AiOutlineCheckCircle size={14} style={{ color: "green" }} />
            </AlertText>
          )}

          <FormInput
            placeholder="비밀번호를 입력해주세요"
            value={PassWord}
            type="password"
            onChange={(e) => setPassWord(e.target.value)}
          ></FormInput>

          <FormInput
            placeholder="비밀번호 확인"
            type="password"
            value={PassWordConfirm}
            onChange={(e) => setPassWordConfirm(e.target.value)}
          ></FormInput>
          {PassWordConfirm === PassWord && PassWord !== "" ? (
            <AlertText>
              <AiOutlineCheckCircle size={14} style={{ color: "green" }} />
            </AlertText>
          ) : (
            <AlertText>비밀번호가 동일하지 않습니다.</AlertText>
          )}
          <FormInput
            placeholder="이름을 입력해주세요"
            value={Name}
            onChange={(e) => setName(e.target.value)}
          ></FormInput>
          {Name === "" ? (
            <AlertText>이름이 입력되지 않았습니다.</AlertText>
          ) : (
            <AlertText>
              <AiOutlineCheckCircle size={14} style={{ color: "green" }} />
            </AlertText>
          )}
        </FormBox>
        <AgeBox onChange={(e) => setAge(e.target.value)}>
          <option value={20}>20대</option>
          <option value={30}>30대</option>
          <option value={40}>40대</option>
          <option value={50}>50대</option>
        </AgeBox>
        <GenderBox>
          <GenderSelect
            value={Gender}
            check={"남"}
            onClick={() => setGender("남")}
          >
            남자
          </GenderSelect>
          <GenderSelect
            value={Gender}
            check={"여"}
            onClick={() => setGender("여")}
          >
            여자
          </GenderSelect>
          {Gender === "" ? (
            <AlertText>성별이 선택되지 않았습니다.</AlertText>
          ) : (
            <AlertText>
              <AiOutlineCheckCircle size={14} style={{ color: "green" }} />
            </AlertText>
          )}
        </GenderBox>

        <SubmitButton onClick={onNextStep}>가입하기</SubmitButton>
      </Container>
    </Fragment>
  );
}

export default UserFormRegister;
const a = styled.div``;
const AlertText = styled.div`
  margin-left: 10px;
  font-size: 12px;
  color: red;
`;

const SubmitButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  margin-top: 30px;
  border: 1px solid #c0c0c0;
  width: 90px;
  height: 45px;
  box-sizing: border-box;
  background-color: white;
`;

const AgeBox = styled.select`
  width: 100%;
  height: 50px;
  border: 1px solid #c0c0c0;
  border-radius: 3px;
  margin: 10px 0px;
  padding: 0px 10px;
  font-size: 15px;
  box-sizing: border-box;
`;

const GenderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0px;
  width: 100%;
`;
const GenderSelect = styled.button`
  cursor: pointer;
  outline: none;
  background-color: ${(props) =>
    props.value == props.check ? "#c0c0c0" : "white"};
  font-size: 16px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 70px;
  border: 1px solid #c0c0c0;
  border-radius: 3px;
`;

const FormInput = styled.input`
  width: 100%;
  height: 50px;
  border: 1px solid #c0c0c0;
  border-radius: 3px;
  margin: 10px 0px;
  padding: 0px 10px;
  font-size: 15px;
  box-sizing: border-box;
  &::placeholder {
    padding: 0px 10px;
  }
`;

const FormBox = styled.div`
  margin: 30px 0px;
  width: 100%;
  box-sizing: border-box;
`;
const FormData = styled.div``;
const FormDataTitle = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  color: #000;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const ContainerTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #000;
`;
