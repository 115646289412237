import React, { Fragment } from "react";
import styled from "styled-components";
function Architecture({
  SweetClicked,
  setSweetClicked,
  AcidClicked,
  setAcidClicked,
  AlcoholClicked,
  setAlcoholClicked,
}) {
  return (
    <Fragment>
      <BarContainer>
        <TasteBox>
          <MainTitle>당도</MainTitle>
          <CheckContainer>
            <ArchBar>
              <Choose
                value={20}
                sweet={SweetClicked}
                onClick={() => setSweetClicked(20)}
              >
                없음
              </Choose>
              <Choose
                value={40}
                sweet={SweetClicked}
                onClick={() => setSweetClicked(40)}
              >
                적음
              </Choose>
              <Choose
                value={60}
                sweet={SweetClicked}
                onClick={() => setSweetClicked(60)}
              >
                보통
              </Choose>
              <Choose
                value={80}
                sweet={SweetClicked}
                onClick={() => setSweetClicked(80)}
              >
                충분
              </Choose>
              <Choose
                value={100}
                sweet={SweetClicked}
                onClick={() => setSweetClicked(100)}
              >
                많음
              </Choose>
            </ArchBar>
            <MixDetail>
              {SweetClicked == 20 && (
                <DetailText>술이 달지 않습니다.</DetailText>
              )}
              {SweetClicked == 40 && (
                <DetailText>술이 안 달지는 않습니다.</DetailText>
              )}
              {SweetClicked == 60 && (
                <DetailText>술이 적당히 달다고 느껴집니다.</DetailText>
              )}
              {SweetClicked == 80 && (
                <DetailText>술이 달다고 느껴집니다.</DetailText>
              )}
              {SweetClicked == 100 && (
                <DetailText>술이 많이 달다고 느껴집니다.</DetailText>
              )}
            </MixDetail>
          </CheckContainer>
        </TasteBox>

        <TasteBox>
          <MainTitle>산도</MainTitle>
          <CheckContainer>
            <ArchBar>
              <ChooseAcid
                value={20}
                acid={AcidClicked}
                onClick={() => setAcidClicked(20)}
              >
                없음
              </ChooseAcid>
              <ChooseAcid
                value={40}
                acid={AcidClicked}
                onClick={() => setAcidClicked(40)}
              >
                적음
              </ChooseAcid>
              <ChooseAcid
                value={60}
                acid={AcidClicked}
                onClick={() => setAcidClicked(60)}
              >
                보통
              </ChooseAcid>
              <ChooseAcid
                value={80}
                acid={AcidClicked}
                onClick={() => setAcidClicked(80)}
              >
                충분
              </ChooseAcid>
              <ChooseAcid
                value={100}
                acid={AcidClicked}
                onClick={() => setAcidClicked(100)}
              >
                많음
              </ChooseAcid>
            </ArchBar>
            <MixDetail>
              {AcidClicked == 20 && (
                <DetailText>술에 신맛이 없습니다.</DetailText>
              )}
              {AcidClicked == 40 && (
                <DetailText>술에 신맛이 조금 느껴집니다.</DetailText>
              )}
              {AcidClicked == 60 && (
                <DetailText>술에 적당한 신맛이 느껴집니다.</DetailText>
              )}
              {AcidClicked == 80 && (
                <DetailText>술이 시다고 느껴집니다.</DetailText>
              )}
              {AcidClicked == 100 && (
                <DetailText>술이 많이 시다고 느껴집니다.</DetailText>
              )}
            </MixDetail>
          </CheckContainer>
        </TasteBox>
        <TasteBox>
          <MainTitle>알코올 쓴맛</MainTitle>
          <CheckContainer>
            <ArchBar>
              <ChooseAlcohol
                value={33}
                alcohol={AlcoholClicked}
                onClick={() => setAlcoholClicked(33)}
              >
                없음
              </ChooseAlcohol>
              <ChooseAlcohol
                value={66}
                alcohol={AlcoholClicked}
                onClick={() => setAlcoholClicked(66)}
              >
                보통
              </ChooseAlcohol>
              <ChooseAlcohol
                value={100}
                alcohol={AlcoholClicked}
                onClick={() => setAlcoholClicked(100)}
              >
                많음
              </ChooseAlcohol>
            </ArchBar>
            <MixDetail>
              {AlcoholClicked == 33 && (
                <DetailText>술에 쓴맛이 없습니다.</DetailText>
              )}
              {AlcoholClicked == 66 && (
                <DetailText>술의 쓴맛이 보통입니다.</DetailText>
              )}
              {AlcoholClicked == 100 && (
                <DetailText>술이 쓰다고 느껴집니다.</DetailText>
              )}
            </MixDetail>
          </CheckContainer>
        </TasteBox>
      </BarContainer>
    </Fragment>
  );
}

export default Architecture;
const MixDetail = styled.div`
  margin-top: 30px;
`;
const DetailText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #4d75f2;
`;
const CheckContainer = styled.div`
  margin-top: 40px;
  width: 100%;
`;
const AlcoholTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  font-size: 16px;
  @media (max-width: 640px) {
    font-size: 11px;
  }
`;
const AlcoholBarTitle = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 10px;
  @media (max-width: 640px) {
    width: 330px;
  }
  /* border: 2px solid lightgray;
  border-radius: 20px; */
`;
const Alcohol = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 10px;
`;
const AlcoholBar = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 10px;
  border: 2px solid lightgray;
  border-radius: 20px;
  @media (max-width: 640px) {
    width: 330px;
  }
  /* overflow: hidden; */
`;
const MainTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;
const TasteBox = styled.div`
  width: 100%;
  margin: 30px 0px;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  font-size: 16px;
  @media (max-width: 640px) {
    font-size: 11px;
    text-align: center;
  }
`;
const BarTitle = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 10px;
  @media (max-width: 640px) {
    width: 330px;
  }
  /* border: 2px solid lightgray;
  border-radius: 20px; */
`;
const ChooseAlcohol = styled.div`
  cursor: pointer;
  width: 80px;
  height: 80px;
  /* border: 2px solid #f5f6f8; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 50%;
  color: ${(props) => (props.alcohol === props.value ? "white" : "#b5bbc7")};
  background-color: ${(props) =>
    props.alcohol === props.value ? "#4d75f2" : "#f5f6f8"};
  @media (max-width: 640px) {
    width: 55px;
    height: 55px;
    font-size: 14px;
  }
`;
const ChooseAcid = styled.div`
  cursor: pointer;
  width: 80px;
  height: 80px;
  /* border: 2px solid #f5f6f8; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 50%;
  color: ${(props) => (props.acid === props.value ? "white" : "#b5bbc7")};
  background-color: ${(props) =>
    props.acid === props.value ? "#4d75f2" : "#f5f6f8"};
  @media (max-width: 640px) {
    width: 55px;
    height: 55px;
    font-size: 14px;
  }
`;
const Choose = styled.div`
  @media (max-width: 640px) {
    width: 55px;
    height: 55px;
    font-size: 14px;
  }
  cursor: pointer;
  width: 80px;
  height: 80px;
  /* border: 2px solid #f5f6f8; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 50%;
  color: ${(props) => (props.sweet === props.value ? "white" : "#b5bbc7")};
  background-color: ${(props) =>
    props.sweet === props.value ? "#4d75f2" : "#f5f6f8"};
`;
const Bar = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 10px;
`;
const ArchBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BarContainer = styled.div`
  margin: 30px 0px;
  width: 100%;
  min-height: 200px;
  display: flex;
  padding: 0px 95px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 640px) {
    padding: 0px 10px;
  }
`;
