import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { MdArrowBack } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
function CircleChart({
  Flavor,
  Flavor2,
  Flavor3,
  setFlavor,
  setFlavorAdd,
  FlavorAdd,
  Clicked,
  setClicked,
}) {
  const [InputValue, setInputValue] = useState("");
  const onInput = (e) => {
    setInputValue(e.target.value);
  };
  const ConfirmInput = (e) => {
    e.preventDefault();
    setFlavor(InputValue);
  };
  const onReset = () => {
    setFlavor("");
    setInputValue("");
    setClicked("");
  };
  const KeyboardEvt = (e) => {
    if (e && e.keyCode === 13) {
      ConfirmInput();
    }
  };
  return (
    <Fragment>
      <Chart>
        {Clicked === "" && Flavor === "" && (
          <Fragment>
            <Part1 onClick={() => setClicked("견과류,#ffeec3")}>
              <Title1>견과류</Title1>
            </Part1>
            <Part2 onClick={() => setClicked("꽃,#ffe5a5")}>
              <Title2>꽃</Title2>
            </Part2>
            <Part3 onClick={() => setClicked("채소,#ffda80")}>
              <Title3>채소</Title3>
            </Part3>
            <Part4 onClick={() => setClicked("기타,#ffd266")}>
              <Title4>기타</Title4>
            </Part4>
            <Part5 onClick={() => setClicked("향신료,#ffcb4e")}>
              <Title5>향신료</Title5>
            </Part5>
            <Part6 onClick={() => setClicked("유제품,#ffbe23")}>
              <Title6>유제품</Title6>
            </Part6>
            <Part7 onClick={() => setClicked("과일,#d49500")}>
              <Title7>과일</Title7>
            </Part7>
            <Part8 onClick={() => setClicked("곡물,#fff7e2")}>
              <Title8>곡물</Title8>
            </Part8>
          </Fragment>
        )}
        {Clicked === "견과류,#ffeec3" && (
          <PartClicked color={"white"}>
            <Part1 onClick={() => setFlavor("땅콩")}>
              <Title1>땅콩</Title1>
            </Part1>
            <Part2 onClick={() => setFlavor("구운땅콩")}>
              <Title2>구운땅콩</Title2>
            </Part2>
            <Part3 onClick={() => setFlavor("밤")}>
              <Title3>밤</Title3>
            </Part3>
            <Part4 onClick={() => setFlavor("잣")}>
              <Title4>잣</Title4>
            </Part4>
            <Part5 onClick={() => setFlavor("호두")}>
              <Title5>호두</Title5>
            </Part5>
            <Part6 onClick={() => setFlavor("아몬드")}>
              <Title6>아몬드</Title6>
            </Part6>
            <Part7>
              <Title7>-</Title7>
            </Part7>
            <Part8>
              <Title8>-</Title8>
            </Part8>
            <Goback onClick={() => setClicked("")}>
              <MdArrowBack size={24} />
            </Goback>
          </PartClicked>
        )}
        {Clicked === "꽃,#ffe5a5" && (
          <PartClicked color={"white"}>
            <Part1 onClick={() => setFlavor("아카시아")}>
              <Title1>아카시아</Title1>
            </Part1>
            <Part2 onClick={() => setFlavor("흰꽃")}>
              <Title2>흰꽃</Title2>
            </Part2>
            <Part3 onClick={() => setFlavor("연꽃")}>
              <Title3>연꽃</Title3>
            </Part3>
            <Part4 onClick={() => setFlavor("매화")}>
              <Title4>매화</Title4>
            </Part4>
            <Part5 onClick={() => setFlavor("장미")}>
              <Title5>장미</Title5>
            </Part5>
            <Part6 onClick={() => setFlavor("카네이션")}>
              <Title6>카네이션</Title6>
            </Part6>
            <Part7>
              <Title7>-</Title7>
            </Part7>
            <Part8>
              <Title8>-</Title8>
            </Part8>
            <Goback onClick={() => setClicked("")}>
              <MdArrowBack size={24} />
            </Goback>
          </PartClicked>
        )}
        {Clicked === "채소,#ffda80" && (
          <PartClicked color={"white"}>
            <Part1 onClick={() => setFlavor("고구마")}>
              <Title1>고구마</Title1>
            </Part1>
            <Part2 onClick={() => setFlavor("밤")}>
              <Title2>밤</Title2>
            </Part2>
            <Part3 onClick={() => setFlavor("호박")}>
              <Title3>호박</Title3>
            </Part3>
            <Part4 onClick={() => setFlavor("버섯")}>
              <Title4>버섯</Title4>
            </Part4>
            <Part5 onClick={() => setFlavor("솔")}>
              <Title5>솔</Title5>
            </Part5>
            <Part6 onClick={() => setFlavor("고추")}>
              <Title6>고추</Title6>
            </Part6>
            <Part7 onClick={() => setFlavor("거름")}>
              <Title7>거름</Title7>
            </Part7>
            <Part8 onClick={() => setFlavor("꿀")}>
              <Title8>꿀</Title8>
            </Part8>
            <Goback onClick={() => setClicked("")}>
              <MdArrowBack size={24} />
            </Goback>
          </PartClicked>
        )}
        {Clicked === "기타,#ffd266" && (
          <InputClicked color={"#ffd266"} onSubmit={ConfirmInput}>
            <Input
              value={InputValue}
              onChange={onInput}
              onKeyPress={KeyboardEvt}
              placeholder="직접 입력하세요"
            />
            <InputButtonBox>
              <GoBackInput onClick={() => setClicked("")}>
                <MdArrowBack size={18} />
              </GoBackInput>
              <Enter onClick={ConfirmInput}>추가</Enter>
            </InputButtonBox>
          </InputClicked>
        )}
        {Clicked === "향신료,#ffcb4e" && (
          <PartClicked color={"white"}>
            <Part1 onClick={() => setFlavor("생강")}>
              <Title1>생강</Title1>
            </Part1>
            <Part2 onClick={() => setFlavor("후추")}>
              <Title2>후추</Title2>
            </Part2>
            <Part3 onClick={() => setFlavor("바닐라")}>
              <Title3>바닐라</Title3>
            </Part3>
            <Part4 onClick={() => setFlavor("간장")}>
              <Title4>간장</Title4>
            </Part4>
            <Part5 onClick={() => setFlavor("메주")}>
              <Title5>메주</Title5>
            </Part5>
            <Part6 onClick={() => setFlavor("로즈마리")}>
              <Title6>로즈마리</Title6>
            </Part6>
            <Part7 onClick={() => setFlavor("민트")}>
              <Title7>민트</Title7>
            </Part7>
            <Part8>
              <Title8>-</Title8>
            </Part8>
            <Goback onClick={() => setClicked("")}>
              <MdArrowBack size={24} />
            </Goback>
          </PartClicked>
        )}
        {Clicked === "유제품,#ffbe23" && (
          <PartClicked color={"white"}>
            <Part1 onClick={() => setFlavor("우유")}>
              <Title1>우유</Title1>
            </Part1>
            <Part2 onClick={() => setFlavor("요구르트")}>
              <Title2>요구르트</Title2>
            </Part2>
            <Part3 onClick={() => setFlavor("치즈")}>
              <Title3>치즈</Title3>
            </Part3>
            <Part4 onClick={() => setFlavor("연유")}>
              <Title4>연유</Title4>
            </Part4>
            <Part5 onClick={() => setFlavor("크림")}>
              <Title5>크림</Title5>
            </Part5>
            <Part6>
              <Title6>-</Title6>
            </Part6>
            <Part7>
              <Title7>-</Title7>
            </Part7>
            <Part8>
              <Title8>-</Title8>
            </Part8>

            <Goback onClick={() => setClicked("")}>
              <MdArrowBack size={24} />
            </Goback>
          </PartClicked>
        )}
        {Clicked === "과일,#d49500" && (
          <PartClicked color={"white"}>
            <Part1 onClick={() => setFlavor("청포도")}>
              <Title1>청포도</Title1>
            </Part1>
            <Part2 onClick={() => setFlavor("청사과")}>
              <Title2>청사과</Title2>
            </Part2>
            <Part3 onClick={() => setFlavor("레몬")}>
              <Title3>레몬</Title3>
            </Part3>
            <Part4 onClick={() => setFlavor("자몽")}>
              <Title4>자몽</Title4>
            </Part4>
            <Part5 onClick={() => setFlavor("자두")}>
              <Title5>자두</Title5>
            </Part5>
            <Part6 onClick={() => setFlavor("사과")}>
              <Title6>사과</Title6>
            </Part6>
            <Part7 onClick={() => setFlavor("복숭아")}>
              <Title7>복숭아</Title7>
            </Part7>
            <Part8 onClick={() => setFlavor("살구")}>
              <Title8>살구</Title8>
            </Part8>
            <Goback onClick={() => setClicked("")}>
              <MdArrowBack size={24} />
            </Goback>
          </PartClicked>
        )}
        {Clicked === "곡물,#fff7e2" && (
          <PartClicked color={"white"}>
            <Part1 onClick={() => setFlavor("생쌀")}>
              <Title1>생쌀</Title1>
            </Part1>
            <Part2 onClick={() => setFlavor("밥")}>
              <Title2>밥</Title2>
            </Part2>
            <Part3 onClick={() => setFlavor("엿기름")}>
              <Title3>엿기름</Title3>
            </Part3>
            <Part4 onClick={() => setFlavor("밀가루")}>
              <Title4>밀가루</Title4>
            </Part4>
            <Part5 onClick={() => setFlavor("빵반죽")}>
              <Title5>빵반죽</Title5>
            </Part5>
            <Part6>
              <Title6>-</Title6>
            </Part6>
            <Part7>
              <Title7>-</Title7>
            </Part7>
            <Part8>
              <Title8>-</Title8>
            </Part8>
            <Goback onClick={() => setClicked("")}>
              <MdArrowBack size={24} />
            </Goback>
          </PartClicked>
        )}
        {Flavor !== "" && (
          <FlavorClicked color={"#ffbe23"}>
            <Title>{Flavor}</Title>
            <ButtonContainer>
              <Edit onClick={onReset}>수정하기</Edit>
              {FlavorAdd === "1" && Flavor2 === "" && (
                <Edit onClick={() => setFlavorAdd("2")}>추가하기</Edit>
              )}
              {FlavorAdd === "2" && Flavor3 === "" && (
                <Edit onClick={() => setFlavorAdd("3")}>추가하기</Edit>
              )}
              {FlavorAdd === "3" && <div></div>}
            </ButtonContainer>
          </FlavorClicked>
        )}
      </Chart>
    </Fragment>
  );
}

export default CircleChart;
const ButtonContainer = styled.div`
  display: flex;
`;
const PlusButton = styled.div`
  cursor: pointer;
  margin-left: 20px;
  width: 35px;
  height: 35px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18);
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InputButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Input = styled.input`
  outline: none;
  font-size: 16px;
  color: black;
  width: 150px;
  height: 40px;
  background-color: white;
  border-radius: 5px;
  /* border: 2px solid #f5f6f8; */
  /* border-left: none;
  border-right: none;
  border-top: none; */
  border: none;
  margin-bottom: 20px;
  padding: 5px;
  font-weight: bold;
  box-sizing: border-box;
  &::placeholder {
    color: #b5bbc7;
    font-size: 16px;
    text-align: center;
  }
  @media (max-width: 640px) {
    font-size: 16px;
    &::placeholder {
      font-size: 14px;
    }
  }
`;
const GoBackInput = styled.div`
  margin-right: 10px;
  cursor: pointer;
  width: 50px;
  height: 35px;
  /* box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18); */
  border-radius: 5px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;
const Enter = styled.div`
  cursor: pointer;
  width: 70px;
  height: 35px;
  /* box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18); */
  border-radius: 5px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;
const InputClicked = styled.form`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  background-color: ${(props) => props.color};
`;
const FlavorClicked = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  background-color: ${(props) => props.color};
`;
const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 10px;
`;
const Edit = styled.div`
  margin: 10px 10px;
  cursor: pointer;
  width: 70px;
  height: 35px;
  border: none;
  border-radius: 5px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;
const Goback = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
`;
const PartClicked = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  background-color: ${(props) => props.color};
`;
const Title1 = styled.div`
  position: relative;
  font-weight: 600;
  left: 90px;
  bottom: 30px;
`;
const Title2 = styled.div`
  position: relative;
  font-weight: 600;
  left: 90px;
  top: 35px;
`;
const Title3 = styled.div`
  position: relative;
  font-weight: 600;
  left: 40px;
  top: 90px;
`;
const Title4 = styled.div`
  position: relative;
  font-weight: 600;
  right: 40px;
  top: 90px;
`;
const Title5 = styled.div`
  position: relative;
  font-weight: 600;
  right: 90px;
  top: 35px;
`;
const Title6 = styled.div`
  position: relative;
  font-weight: 600;
  right: 90px;
  bottom: 30px;
`;
const Title7 = styled.div`
  position: relative;
  font-weight: 600;
  right: 40px;
  bottom: 90px;
`;
const Title8 = styled.div`
  position: relative;
  font-weight: 600;
  left: 40px;
  bottom: 90px;
`;

const Chart = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  margin: 10px;
`;

const Part1 = styled.div`
  cursor: pointer;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  transition: 0.2s cubic-bezier(0.74, 1.13, 0.83, 1.2);
  &:hover {
    transform: scale(1.2);
    transform-origin: center center;
  }
  background-color: #ffeec3;
  clip-path: polygon(50% 50%, 50% 50%, 100% 50%, 100% 0);
  @media (max-width: 640px) {
    &:hover {
      transform: scale(1);
    }
  }
`;
const Part2 = styled.div`
  cursor: pointer;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  transition: 0.2s cubic-bezier(0.74, 1.13, 0.83, 1.2);
  &:hover {
    transform: scale(1.2);
    transform-origin: center center;
  }
  background-color: #ffe5a5;
  clip-path: polygon(50% 50%, 50% 50%, 100% 100%, 100% 50%);
  @media (max-width: 640px) {
    &:hover {
      transform: scale(1);
    }
  }
`;
const Part3 = styled.div`
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  transition: 0.2s cubic-bezier(0.74, 1.13, 0.83, 1.2);
  &:hover {
    transform: scale(1.2);
    transform-origin: center center;
  }
  background-color: #ffda80;
  clip-path: polygon(50% 50%, 50% 100%, 100% 100%);
  @media (max-width: 640px) {
    &:hover {
      transform: scale(1);
    }
  }
`;
const Part4 = styled.div`
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  transition: 0.2s cubic-bezier(0.74, 1.13, 0.83, 1.2);
  &:hover {
    transform: scale(1.2);
    transform-origin: center center;
  }
  background-color: #ffd266;
  clip-path: polygon(50% 50%, 0 100%, 50% 100%);
  @media (max-width: 640px) {
    &:hover {
      transform: scale(1);
    }
  }
`;
const Part5 = styled.div`
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  transition: 0.2s cubic-bezier(0.74, 1.13, 0.83, 1.2);
  &:hover {
    transform: scale(1.2);
    transform-origin: center center;
  }
  background-color: #ffcb4e;
  clip-path: polygon(50% 50%, 0 50%, 0 100%);
  @media (max-width: 640px) {
    &:hover {
      transform: scale(1);
    }
  }
`;
const Part6 = styled.div`
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  transition: 0.2s cubic-bezier(0.74, 1.13, 0.83, 1.2);
  &:hover {
    transform: scale(1.2);
    transform-origin: center center;
  }
  background-color: #ffbe23;
  /* clip-path: polygon(50% 50%, 0% 50%, 0 100%); */
  clip-path: polygon(50% 50%, 50% 50%, 0% 0%, 0% 50%);
  @media (max-width: 640px) {
    &:hover {
      transform: scale(1);
    }
  }
`;
const Part7 = styled.div`
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  transition: 0.2s cubic-bezier(0.74, 1.13, 0.83, 1.2);
  &:hover {
    transform: scale(1.2);
    transform-origin: center center;
  }
  background-color: #d49500;
  clip-path: polygon(50% 50%, 50% 50%, 0% 0%, 50% 0%);
  @media (max-width: 640px) {
    &:hover {
      transform: scale(1);
    }
  }
`;
const Part8 = styled.div`
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  transition: 0.2s cubic-bezier(0.74, 1.13, 0.83, 1.2);
  &:hover {
    transform: scale(1.2);
    transform-origin: center center;
  }
  background-color: #fff7e2;
  clip-path: polygon(50% 50%, 50% 0%, 0% 0%, 100% 0%);
  @media (max-width: 640px) {
    &:hover {
      transform: scale(1);
    }
  }
`;
