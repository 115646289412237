import React, { useRef, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
/////////////////////////////////////////////////////////////////////////////////
import styled, { keyframes, css } from "styled-components";
import { BsSearch } from "react-icons/bs";
import AutoSearch from "./AutoSearch";
import AutoSearchHearder from "./AutoSearchHearder";
/////////////////////////////////////////////////////////////////////////////////
function SearchBarHeader({ KeywordBoxOnOff, KeywordBoxOff }) {
  const history = useHistory();
  const inputSearch = useRef("");
  if (!window.localStorage.getItem("latestSearchKeyword")) {
    window.localStorage.setItem("latestSearchKeyword", "KeyWordList$%");
  }
  const [text, setText] = useState("");
  const [BarOpen, setBarOpen] = useState(false);

  const onSubmit = (e) => {
    // alert(inputSearch.current);
    e.preventDefault();
    // console.log("onEnter");
    if (KeywordBoxOnOff) {
      KeywordBoxOnOff();
    }
    let Keywords = window.localStorage.getItem("latestSearchKeyword");
    if (Keywords === null) {
      window.localStorage.setItem("latestSearchKeyword", text);
    } else {
      window.localStorage.setItem(
        "latestSearchKeyword",
        Keywords + "," + text
      );
    }

    history.push(`/Search?term=${text}`);
    setText("");
  };

  const onChangeSearch = (e) => {
    // inputSearch.current = e.target.value;
    setText(e.target.value);
  };

  const KeyboardEvt = (e) => {
    if (e && e.keyCode === 13 && e.target.value !== "") {
      onSubmit();
    }
  };
  // onBlur={KeywordBoxOff}

  return (
    <Fragment>
      <SearchWrapper>
        <SearchBarContainer onSubmit={onSubmit}>
          {BarOpen && (
            <SearchBox
              placeholder="검색어를 입력하세요."
              onChange={onChangeSearch}
              onKeyPress={KeyboardEvt}
              onClick={KeywordBoxOnOff}
              value={text}
            />
          )}
          <SearchIcon onClick={() => setBarOpen(!BarOpen)}>
            <BsSearch size={20} />
          </SearchIcon>
        </SearchBarContainer>
        {text.length > 1 && (
          <AutoSearchBox>
            <AutoSearchHearder text={text} />
          </AutoSearchBox>
        )}
      </SearchWrapper>
    </Fragment>
  );
}
export default SearchBarHeader;
const SearchWrapper = styled.div``;
const AutoSearchBox = styled.div`
  position: relative;
  z-index: 100;
  top: 13px;
  right: 0;
`;
const BarPopUp = keyframes`
from{
    opacity:0;
}
to {
    opacity:1;
}
`;

const SearchIcon = styled.div`
  cursor: pointer;
  color: black;
`;

const SearchBox = styled.input`
  margin-right: 10px;
  outline: none;
  border: none;
  box-sizing: border-box;
  border: 1px solid #f2f2f2;
  border-radius: 30px;
  width: 160px;
  height: 90%;
  padding: 0px 20px;
  font-size: 14px;
  /* font-family: "RIDIBatang", sans-serif; */
  background-color: transparent;
  color: black;
  font-weight: bold;
  animation: ${BarPopUp} 0.7s backwards;

  &::placeholder {
    font-size: 14px;
    color: black;
    font-weight: bold;
    @media (max-width: 850px) {
      font-size: 18px;
    }
  }
`;
const SearchBarContainer = styled.form`
  position: relative;
  margin-right: 30px;
  margin-top: 5px;
  /* border-bottom: 2px solid black; */
  /* border-radius: 30px; */
  /* padding: 0px 15px; */
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* width: 400px; */
  height: 45px;
  box-sizing: border-box;
  @media (max-width: 640px) {
    display: none;
  }
`;
