import React, { Fragment } from "react";
import SearchPresenter from "./SearchPresenter";
import Helmet from "react-helmet";
function SearchContainer({ location }) {
  const Term = location.search.split("=")[1];
  return (
    <Fragment>
      <Helmet>
        <title>내술 노트 | 검색 :: {Term}</title>
      </Helmet>
      <SearchPresenter Term={Term} />
    </Fragment>
  );
}

export default SearchContainer;
