import React, { Fragment, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { API } from "@aws-amplify/api";
import { Link } from "react-router-dom";
import { AiOutlineLoading } from "react-icons/ai";
import { Review } from "../Post/PostUtil/PostDummy";
function BookPresenter({ Email }) {
  useEffect(() => {
    GetAlcoholReview();
    GetAlcoholPost();
  }, []);
  const [ReviewedData, setReviewedData] = useState([]);
  const [Posts, setPosts] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [ReviewFilter, setReviewFilter] = useState("1");

  const GetAlcoholPost = async () => {
    const ApiResponse = await API.get("AlcoholPostApi", "/items/");
    setPosts(ApiResponse);
    setLoading(false);
  };
  const GetAlcoholReview = async () => {
    const ApiResponse = await API.get("AlcoholReviewApi", "/items/");
    setReviewedData(ApiResponse);
  };
  const ReviewedItem = ReviewedData.filter((data) => data.email == Email)
    .length;
  // 전체 리뷰중 나의 이메일과 같은 객체들만 반환한 배열의 길이를 담는다.

  for (let i = Posts.length; i > 0; i--) {
    const Check = ReviewedData.some(
      (data) => Posts[i - 1].id == data.postId && data.email === Email
    );
    if (Check) {
      Posts[i - 1].reviewed = true;
    }
    if (!Check) {
      Posts[i - 1].reviewed = false;
    }
  }
  // 전체 Posts를 순회하면서 ReviewData중 Posts의 id와 리뷰데이터의postId와 해당포스트의 id와 같고 email이 로그인한 유저의 이메일이 같은지 체크하고, 같다면 true, 다르다면 false를 반환하고, 해당 boolean값에 따라 Posts객체마다 reviewed 키를 생성한뒤, 해당 boolean값을 value로 삽입하여 reviewed값이 추가된 Posts배열을 만든다.
  // console.log(Posts);
  const PostSort = Posts.sort((a, b) =>
    a.name > b.name ? 1 : a.name < b.name ? -1 : 0
  );
  // Posts를 name의 순서에 따라 (가나다 순)으로 sort하여 PostSort에 담는다.
  const RanderOnlyReviewedBook = PostSort.map((data) => {
    if (data.reviewed) {
      return (
        <Fragment>
          <PostBox to={`/ReviewEdit/:${data.id}`}>
            <Image src={data.image}></Image>
            <Product>{data.name}</Product>
            <Company>{data.company}</Company>
          </PostBox>
        </Fragment>
      );
    }
  });
  const RanderOnlyUnReviewedBook = PostSort.map((data) => {
    if (!data.reviewed) {
      return (
        <Fragment>
          <PostBoxNon to={`/Post/:${data.id}`}>
            <ImageNon src={data.image}></ImageNon>
            <ProductNon>{data.name}</ProductNon>
            <CompanyNon>{data.company}</CompanyNon>
          </PostBoxNon>
        </Fragment>
      );
    }
  });

  const RanderReviewedBook = PostSort.map((data) => {
    if (data.reviewed) {
      return (
        <Fragment>
          <PostBox to={`/ReviewEdit/:${data.id}`}>
            <Image src={data.image}></Image>
            <Product>{data.name}</Product>
            <Company>{data.company}</Company>
          </PostBox>
        </Fragment>
      );
    }
    if (!data.reviewed) {
      return (
        <Fragment>
          <PostBoxNon to={`/Post/:${data.id}`}>
            <ImageNon src={data.image}></ImageNon>
            <ProductNon>{data.name}</ProductNon>
            <CompanyNon>{data.company}</CompanyNon>
          </PostBoxNon>
        </Fragment>
      );
    }
  });
  // PostSort를 .map으로 순회하면서, 해당 객체에 reviewed값이 true이면 활성화된 PostBox, false면 비활성화 디자인이 적용된 PostBox를 리턴한다.

  return (
    <Fragment>
      <BookContainer>
        <BookTitle>리뷰 도감</BookTitle>
        {!Loading && (
          <Fragment>
            <ReviewNumberArea>
              전체 {Posts.length}개 중 {ReviewedItem}개 작성
            </ReviewNumberArea>
            <ReviewFilterBox>
              <ReviewFilterButton
                ReviewFilter={ReviewFilter}
                value="1"
                onClick={() => setReviewFilter("1")}
              >
                전체보기
              </ReviewFilterButton>
              <ReviewFilterButton
                ReviewFilter={ReviewFilter}
                value="2"
                onClick={() => setReviewFilter("2")}
              >
                리뷰함
              </ReviewFilterButton>
              <ReviewFilterButton
                ReviewFilter={ReviewFilter}
                value="3"
                onClick={() => setReviewFilter("3")}
              >
                리뷰 안 함
              </ReviewFilterButton>
            </ReviewFilterBox>
            <BookBox>
              <GridContainer>
                {ReviewFilter == "1" && (
                  <Fragment>{RanderReviewedBook}</Fragment>
                )}
                {ReviewFilter === "2" && (
                  <Fragment>{RanderOnlyReviewedBook}</Fragment>
                )}
                {ReviewFilter === "3" && (
                  <Fragment>{RanderOnlyUnReviewedBook}</Fragment>
                )}
              </GridContainer>
            </BookBox>
          </Fragment>
        )}
        {Loading && (
          <Fragment>
            <LoadingContainer>
              <LoadingIcon>
                <AiOutlineLoading size={32} />
              </LoadingIcon>
            </LoadingContainer>
          </Fragment>
        )}
      </BookContainer>
    </Fragment>
  );
}

export default BookPresenter;
const rotation = keyframes`
    0% {
      transform: rotate(0deg);
      opacity: 0.1;
    }
    25% {
      transform: rotate(90deg);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.1;
    }
    75% {
      transform: rotate(360deg);
      opacity: 1;
    }
  `;
const ReviewFilterButton = styled.button`
  cursor: pointer;
  outline: none;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  margin-left: 10px;
  padding: 10px;
  font-weight: bold;
  background-color: ${(props) =>
    props.value == props.ReviewFilter ? "5B9FF4" : "#fff"};
`;
const ReviewFilterBox = styled.div`
  margin: 20px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoadingIcon = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotation} 1s infinite linear;
`;
const ReviewNumberArea = styled.div``;
const Product = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: black;
`;
const Company = styled.div`
  color: black;
`;
const ProductNon = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: gray;
`;
const CompanyNon = styled.div`
  color: lightgray;
`;
const Image = styled.div`
  width: 150px;
  height: 180px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
`;
const ImageNon = styled.div`
  width: 150px;
  height: 180px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  -webkit-filter: grayscale(100%);
  filter: gray;
`;

const PostBoxNon = styled(Link)`
  opacity: 0.3;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  min-height: 200px;
  margin: 5px 5px;
  padding: 5px 5px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  box-sizing: border-box;
  transition: transform 0.3s;
  /* -webkit-transform: translate3d(0, 0, 0); */
  &:hover {
    opacity: 0.8;
    /* transform: perspective(600px) rotateY(15deg); */
  }
`;
const PostBox = styled(Link)`
  text-decoration: none;

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  min-height: 200px;
  margin: 5px 5px;
  padding: 5px 5px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  box-sizing: border-box;
  transition: transform 0.3s;
  /* -webkit-transform: translate3d(0, 0, 0); */
  &:hover {
    opacity: 0.8;
    /* transform: perspective(600px) rotateY(15deg); */
  }
`;
const GridContainer = styled.div`
  margin-top: 10px;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(1, 220px);
  grid-template-columns: repeat(4, 220px);
  @media (max-width: 850px) {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 650px) {
    width: 100%;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 420px) {
    width: 80%;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(1, 1fr);
  }
`;
const BookTitle = styled.div`
  margin: 30px 0px;
  font-size: 24px;
  font-weight: bold;
  color: black;
`;
const BookBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /*
  min-width: 800px;
  height: 500px;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #cecece;
  border-radius: 5px;
  overflow: scroll; */
`;
const BookContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 800px; */
  margin-top: 70px;
`;
