import React from "react";
////////////////////////////////////////////////////////////////////
import { GiDiceSixFacesOne } from "react-icons/gi";
import { GiDiceSixFacesTwo } from "react-icons/gi";
import { GiDiceSixFacesThree } from "react-icons/gi";
import { GiDiceSixFacesFour } from "react-icons/gi";
import { GiDiceSixFacesFive } from "react-icons/gi";
import { GiDiceSixFacesSix } from "react-icons/gi";
////////////////////////////////////////////////////////////////////
function LevelCheck({ User }) {
  // const User = 3;
  // console.log(1);
  if (User === "김어진") {
    return <GiDiceSixFacesOne size={18} />;
  } else if (User === 2) {
    return <GiDiceSixFacesTwo size={18} />;
  } else if (User === 3) {
    return <GiDiceSixFacesThree size={18} />;
  } else if (User === 4) {
    return <GiDiceSixFacesFour size={18} />;
  } else if (User !== "김어진") {
    return <GiDiceSixFacesFive size={18} />;
  } else if (User === 6) {
    return <GiDiceSixFacesSix size={18} />;
  }
}

export default LevelCheck;
