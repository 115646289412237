import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { API } from "@aws-amplify/api";
///////////////////////////////////////////////////////////////
function TestField() {
  const [user, setuser] = useState("");
  const [data, setdata] = useState("");
  const [DataGroup, setDataGroup] = useState([]);
  ///////////////////////////////////////////////////////////////
  // console.log(data);
  // console.log(user);
  //   const DataBoxId = 1;
  ///////////////////////////////////////////////////////////////
  useEffect(() => {
    GetComment();
  }, []);
  /////////////////////////초기 데이터 렌더링/////////////////////////
  const GetComment = async () => {
    const ApiResponse = await API.get("Api이름", "/xxxx/");
    setDataGroup(ApiResponse);
  };
  /////////////////////////////수정//////////////////////////////
  const Modify = async (e) => {
    const ApiResponse = await API.post("Api이름", "/xxxx/", {
      body: {
        id: e.target.value,
        user: user,
        data: data,
      },
    });
    // console.log(ApiResponse);
    window.location.reload();
  };
  /////////////////////////////삭제//////////////////////////////
  const Delete = async (e) => {
    let id = e.target.value;
    const ApiResponse = await API.del("Api이름", "/xxxx/" + id);
    // console.log(ApiResponse);
    window.location.reload();
  };
  //////////////////////////////전송/////////////////////////////
  const Submit = async (e) => {
    const ApiResponse = await API.post("Api이름", "/xxxx/", {
      body: {
        id: Number(Date.now()),
        user: user,
        data: data,
      },
    });
    // console.log(ApiResponse);
  };

  /////////////////////////데이터 더미///////////////////////////////
  //   const datas = [
  //     { id: "1", user: "김어진", data: "123" },
  //     { id: "2", user: "김어진", data: "12345" },
  //     { id: "3", user: "김어진", data: "12345" },
  //   ];
  ///////////////////////////////////////////////////////////////
  const RanderData = DataGroup.map((data) => {
    return (
      <Fragment>
        <DataShowBox>
          <DataShow>유저 : {data.user}</DataShow>
          <DataShow>데이터 : {data.data}</DataShow>
          <TaskBox>
            <TaskButton onClick={Modify} value={data.id}>
              수정
            </TaskButton>
            <TaskButton onClick={Delete} value={data.id}>
              삭제
            </TaskButton>
          </TaskBox>
        </DataShowBox>
      </Fragment>
    );
  });
  //////////////////////////////////////////////////////////////////
  return (
    <Fragment>
      <TestFieldContainer>
        <TitleBox>
          <Title>테스트 필드</Title>
        </TitleBox>
        <DataBox>
          <PostData
            placeholder="유저 입력"
            value={user}
            onChange={(e) => setuser(e.target.value)}
          />
          <PostData
            placeholder="데이터 입력"
            value={data}
            onChange={(e) => setdata(e.target.value)}
          />
          <PostButton onClick={Submit}>전송</PostButton>
        </DataBox>
        <DataBox>{RanderData}</DataBox>
      </TestFieldContainer>
    </Fragment>
  );
}

export default TestField;
const TaskBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TaskButton = styled.button`
  cursor: pointer;
  width: 60px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  font-size: 14px;
  margin: 10px;
`;
const DataShowBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18);
  width: 300px;
  min-height: 70px;
  padding: 10px;
  box-sizing: border-box;
`;
const DataShow = styled.div``;
const PostButton = styled.button`
  cursor: pointer;
  width: 70px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  font-size: 18px;
`;
const PostData = styled.input`
  width: 200px;
  height: 30px;
  margin: 20px 0px;
`;
const DataBox = styled.div`
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18);
  padding: 30px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;
const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Title = styled.div`
  font-size: 24px;
`;
const TestFieldContainer = styled.div`
  /* border: 1px solid red; */
  max-width: 1000px;
  min-height: 500px;
  margin: 100px auto;
  padding: 30px;
  box-sizing: border-box;
`;
