import React, { Fragment, useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useHistory } from "react-router-dom";
/////////////////////////////////////////////////////////////////////////////////
import styled, { keyframes } from "styled-components";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { MdCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { MdClose } from "react-icons/md";
/////////////////////////////////////////////////////////////////////////////////
import CircleChart from "./UploadUtil/CircleChart";
import ColorSelectLogic from "./UploadUtil/ColorSelectLogic";
import Architecture from "./UploadUtil/Architecture";
import Texture from "./UploadUtil/Texture";
import Evaluate from "./UploadUtil/Evaluate";
import { useSelector } from "react-redux";
import { API } from "@aws-amplify/api";
import { Review } from "../Post/PostUtil/PostDummy";
/////////////////////////////////////////////////////////////////////////////////
function PostUploadPresenter({ PostId, Post }) {
  let UserEmail = useSelector((state) => state.user.Email);
  let UserName = useSelector((state) => state.user.Data);
  const history = useHistory();
  const [SCheck, setSCheck] = useState("0");
  const [CCheck, setCCheck] = useState("0");
  const [ColorClicked, setColorClicked] = useState("");
  const [MixClicked, setMixClicked] = useState("");
  const [SweetClicked, setSweetClicked] = useState(0);
  const [AcidClicked, setAcidClicked] = useState(0);
  const [AlcoholClicked, setAlcoholClicked] = useState(0);
  const [WeightClicked, setWeightClicked] = useState(0);
  const [CarbonatedClicked, setCarbonatedClicked] = useState(0);
  const [BalanceClicked, setBalanceClicked] = useState(0);
  const [ComplexityClicked, setComplexityClicked] = useState(0);
  const [Flavor1, setFlavor1] = useState("");
  const [Flavor2, setFlavor2] = useState("");
  const [Flavor3, setFlavor3] = useState("");
  const [Market, setMarket] = useState("");
  const [Name, setName] = useState("");
  const [Image, setImage] = useState("");
  const [Price, setPrice] = useState("");
  const [Finish, setFinish] = useState(false);
  const [FlavorAdd, setFlavorAdd] = useState("1");
  const [FocusOn, setFocusOn] = useState("");
  const [Clicked1, setClicked1] = useState("");
  const [Clicked2, setClicked2] = useState("");
  const [Clicked3, setClicked3] = useState("");
  const [ReviewedData, setReviewedData] = useState([]);
  const [Good, setGood] = useState("");
  const [Comment, setComment] = useState("");
  const [Bad, setBad] = useState("");
  useEffect(() => {
    GetReviewed();
  }, []);
  const onClickFlavor1 = () => {
    setFlavorAdd("");
    setClicked1("");
    setFlavor1("");
  };
  const onClickFlavor2 = () => {
    setFlavorAdd("1");
    setFlavor2("");
    setClicked2("");
  };
  const onClickFlavor3 = () => {
    setFlavorAdd("2");
    setFlavor3("");
    setClicked3("");
  };
  const GetReviewed = async () => {
    const ApiResponse = await API.get("AlcoholReviewApi", "/items/");
    setReviewedData(ApiResponse);
  };

  const SubmitonClick = async (e) => {
    e.preventDefault();
    if (
      Price == "" ||
      Market == "" ||
      ColorClicked == "" ||
      MixClicked == "" ||
      SweetClicked == "" ||
      AcidClicked == "" ||
      AlcoholClicked == "" ||
      WeightClicked == "" ||
      Flavor1 == "" ||
      BalanceClicked == "" ||
      ComplexityClicked == "" ||
      Comment.length < 10
    ) {
      alert("모든 항목 평가 및 코멘트를 최소 10자 이상 작성해주세요.");
    } else {
      await setFinish(true);
      let id = Number(Date.now());
      // console.log(id);
      let date = new Date();
      let UploadTime =
        ("00" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + date.getDate()).slice(-2) +
        " " +
        ("00" + date.getHours()).slice(-2) +
        ":" +
        ("00" + date.getMinutes()).slice(-2);
      const ApiResponse = await API.post("AlcoholReviewApi", "/items/", {
        body: {
          id: id,
          user: UserName,
          email: UserEmail,
          postId: PostId,
          name: Name,
          price: Price,
          shop: Market,
          scheck: SCheck,
          ccheck: CCheck,
          colorClicked: ColorClicked,
          mixClicked: MixClicked,
          sweetClicked: SweetClicked,
          acidClicked: AcidClicked,
          alcoholClicked: AlcoholClicked,
          weightClicked: WeightClicked,
          carbonatedClicked: CarbonatedClicked,
          flavor1: Flavor1,
          flavor2: Flavor2,
          flavor3: Flavor3,
          balanceClicked: BalanceClicked,
          complexityClicked: ComplexityClicked,
          comment: Comment,
          click1: Clicked1,
          click2: Clicked2,
          click3: Clicked3,
        },
      });

      history.push(`/Post/:${PostId}`);
    }
  };
  //   Ⅰ.
  // Ⅱ.
  // Ⅲ.
  const RanderBasicValue = Post.map((data) => {
    if (PostId == data.id) {
      return (
        <InfoBox>
          <MainTitle>
            <MainValue>
              기본 정보
              <QuestionIcon
                onMouseEnter={() => {
                  setFocusOn("1");
                }}
                onMouseLeave={() => {
                  setFocusOn("");
                }}
              >
                <AiOutlineQuestionCircle
                  size={24}
                  style={{ color: "#b5bbc7" }}
                />
              </QuestionIcon>
            </MainValue>
            {FocusOn === "1" && (
              <QuestionBox>
                <ul>
                  <li>순미주 : 쌀, 국(누룩, 곡자), 물로만 빚은 술</li>
                  <p />
                  <li>
                    첨가물 : 아스파탐, 아세설팜칼륨, 스테비오사이드 등 -
                    합성감미료
                  </li>
                </ul>
              </QuestionBox>
            )}
          </MainTitle>
          <BasicInfoContainer>
            <BasicPic src={data.image} />
            <BasicInfo>
              <TextInput value={data.name} />
              <TextInput
                placeholder="구입처"
                value={Market}
                onChange={(e) => {
                  setMarket(e.target.value);
                }}
              />
              <TextInput
                placeholder="구입가격"
                value={Price}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
              />
            </BasicInfo>
            <CheckBoxContainer>
              <BoxWrapper>
                <CheckTitle>순미주 여부</CheckTitle>
                <CheckBoxWrapper>
                  {SCheck === "1" && (
                    <MdCheckBox
                      size={26}
                      onClick={() => setSCheck("0")}
                      style={{ color: "#b5bbc7" }}
                    />
                  )}
                  {SCheck === "0" && (
                    <MdCheckBoxOutlineBlank
                      size={26}
                      onClick={() => setSCheck("1")}
                      style={{ color: "#b5bbc7" }}
                    />
                  )}
                </CheckBoxWrapper>
              </BoxWrapper>
              <BoxWrapper>
                <CheckTitle>첨가물 여부</CheckTitle>
                <CheckBoxWrapper>
                  {CCheck === "1" && (
                    <MdCheckBox
                      size={26}
                      onClick={() => setCCheck("0")}
                      style={{ color: "#b5bbc7" }}
                    />
                  )}
                  {CCheck === "0" && (
                    <MdCheckBoxOutlineBlank
                      size={26}
                      onClick={() => setCCheck("1")}
                      style={{ color: "#b5bbc7" }}
                    />
                  )}
                </CheckBoxWrapper>
              </BoxWrapper>
            </CheckBoxContainer>
          </BasicInfoContainer>
        </InfoBox>
      );
    }
  });
  // {!Finish && (
  //   <Prompt
  //     when={prompt}
  //     message="작성중인 게시물이 있습니다. 나가시겠습니까?"
  //   />
  // )}
  return (
    <Fragment>
      <UploadContainer>
        <UploadBox>
          <TasteNote>테이스팅 노트</TasteNote>
          {RanderBasicValue}
          <InfoBox>
            <MainTitle>
              <MainValue>
                외관
                <QuestionIcon
                  onMouseEnter={() => {
                    setFocusOn("2");
                  }}
                  onMouseLeave={() => {
                    setFocusOn("");
                  }}
                >
                  <AiOutlineQuestionCircle
                    size={24}
                    style={{ color: "#b5bbc7" }}
                  />
                </QuestionIcon>
              </MainValue>
              {FocusOn === "2" && (
                <QuestionBox>외관 추가정보~~~~~~~~~~~~~~~~</QuestionBox>
              )}
            </MainTitle>

            <ColorSelectLogic
              setColorClicked={setColorClicked}
              setMixClicked={setMixClicked}
              ColorClicked={ColorClicked}
              MixClicked={MixClicked}
            />
          </InfoBox>
          <InfoBox>
            <MainTitle>
              <MainValue>
                구조
                <QuestionIcon
                  onMouseEnter={() => {
                    setFocusOn("3");
                  }}
                  onMouseLeave={() => {
                    setFocusOn("");
                  }}
                >
                  <AiOutlineQuestionCircle
                    size={24}
                    style={{ color: "#b5bbc7" }}
                  />
                </QuestionIcon>
              </MainValue>
              {FocusOn === "3" && (
                <QuestionBox>
                  <ul>
                    <li>
                      당도 : 입에서 느껴지는 잔당감으로 판별. 합성감미료의 단
                      맛은 삼킨 후에도 입 안을 코팅하는 듯한 단 맛으로,
                      자연적으로 발생하는 잔당(Residual Sugar) 와는 관능적으로
                      차이가 있다.
                    </li>
                    <p />
                    <li>
                      산미 : 신 맛의 정도(산도 와는 다른 기준임, 산도는 삼킨 후
                      입에 고이는 침의 양과 정도로 판별)
                    </li>
                    <p />
                    <li>
                      알코올 느낌 : 객관적인 알코올의 양은 Alcohol Warmth로 표현
                      되며 입 안과 식도를 타고 넘어가는 뜨거운 느낌으로 판별.
                      내술노트에서 ‘알코올 느낌’ 은 알코올의 양과는 별개의
                      알코올 부즈에 가까운 느낌으로 보면 되겠고, 술에서 느껴지는
                      알코올의 향 또는 맛의 강도를 판별.
                    </li>
                  </ul>
                </QuestionBox>
              )}
            </MainTitle>

            <Architecture
              SweetClicked={SweetClicked}
              setSweetClicked={setSweetClicked}
              AcidClicked={AcidClicked}
              setAcidClicked={setAcidClicked}
              AlcoholClicked={AlcoholClicked}
              setAlcoholClicked={setAlcoholClicked}
            />
          </InfoBox>
          <InfoBox>
            <MainTitle>
              <MainValue>
                감촉
                <QuestionIcon
                  onMouseEnter={() => {
                    setFocusOn("4");
                  }}
                  onMouseLeave={() => {
                    setFocusOn("");
                  }}
                >
                  <AiOutlineQuestionCircle
                    size={24}
                    style={{ color: "#b5bbc7" }}
                  />
                </QuestionIcon>
              </MainValue>
              {FocusOn === "4" && (
                <QuestionBox>
                  <ul>
                    <li>
                      무게감 : 바디감과 거의 같은 의미. 총체적인 술의 질감과
                      무게감의 정성적 합.
                    </li>
                  </ul>
                </QuestionBox>
              )}
            </MainTitle>
            <Texture
              WeightClicked={WeightClicked}
              setWeightClicked={setWeightClicked}
              CarbonatedClicked={CarbonatedClicked}
              setCarbonatedClicked={setCarbonatedClicked}
            />
          </InfoBox>
          <InfoBoxFlavor>
            <MainTitle>
              <MainValue>
                풍미
                <QuestionIcon
                  onMouseEnter={() => {
                    setFocusOn("5");
                  }}
                  onMouseLeave={() => {
                    setFocusOn("");
                  }}
                >
                  <AiOutlineQuestionCircle
                    size={24}
                    style={{ color: "#b5bbc7" }}
                  />
                </QuestionIcon>
              </MainValue>
              {FocusOn === "5" && (
                <QuestionBox>풍미 추가정보~~~~~~~~~~~~~~~~</QuestionBox>
              )}
            </MainTitle>

            <ChartContainer>
              <ChartBox control={Flavor1}>
                {FlavorAdd === "1" && (
                  <Fragment>
                    <div></div>
                    <CircleChart
                      Flavor={Flavor1}
                      Flavor2={Flavor2}
                      setFlavor={setFlavor1}
                      setFlavorAdd={setFlavorAdd}
                      FlavorAdd={FlavorAdd}
                      Clicked={Clicked1}
                      setClicked={setClicked1}
                    />
                  </Fragment>
                )}
                {FlavorAdd === "2" && (
                  <AddFlavorBox>
                    <Fragment>
                      <div></div>
                      <CircleChart
                        Flavor={Flavor2}
                        Flavor3={Flavor3}
                        setFlavor={setFlavor2}
                        setFlavorAdd={setFlavorAdd}
                        FlavorAdd={FlavorAdd}
                        Clicked={Clicked2}
                        setClicked={setClicked2}
                      />
                    </Fragment>
                  </AddFlavorBox>
                )}
                {FlavorAdd === "3" && (
                  <AddFlavorBox>
                    <Fragment>
                      <div></div>
                      <CircleChart
                        Flavor={Flavor3}
                        setFlavor={setFlavor3}
                        setFlavorAdd={setFlavorAdd}
                        FlavorAdd={FlavorAdd}
                        Clicked={Clicked3}
                        setClicked={setClicked3}
                      />
                    </Fragment>
                  </AddFlavorBox>
                )}
              </ChartBox>
              {Flavor1 !== "" && (
                <SelectedBox control={Flavor1}>
                  <SelectedArea>
                    {Flavor1 !== "" && (
                      <SelectedFlavor onClick={() => setFlavorAdd("1")}>
                        <SelectedInfo>
                          <SelectColor color={Clicked1.split(",")[1]} />
                          {Clicked1.split(",")[0]} - {Flavor1}
                        </SelectedInfo>
                        {Flavor2 === "" && (
                          <DeleteBox>
                            <DeleteFlavorButton onClick={onClickFlavor1}>
                              <MdClose size={20} />
                            </DeleteFlavorButton>
                          </DeleteBox>
                        )}
                      </SelectedFlavor>
                    )}
                    {Flavor2 !== "" && (
                      <SelectedFlavor onClick={() => setFlavorAdd("2")}>
                        <SelectedInfo>
                          <SelectColor color={Clicked2.split(",")[1]} />
                          {Clicked2.split(",")[0]} - {Flavor2}
                        </SelectedInfo>
                        {Flavor3 === "" && (
                          <DeleteBox>
                            <DeleteFlavorButton onClick={onClickFlavor2}>
                              <MdClose size={20} />
                            </DeleteFlavorButton>
                          </DeleteBox>
                        )}
                      </SelectedFlavor>
                    )}
                    {Flavor3 !== "" && (
                      <SelectedFlavor onClick={() => setFlavorAdd("3")}>
                        <SelectedInfo>
                          <SelectColor color={Clicked3.split(",")[1]} />
                          {Clicked3.split(",")[0]} - {Flavor3}
                        </SelectedInfo>
                        <DeleteBox>
                          <DeleteFlavorButton onClick={onClickFlavor3}>
                            <MdClose size={20} />
                          </DeleteFlavorButton>
                        </DeleteBox>
                      </SelectedFlavor>
                    )}
                  </SelectedArea>
                </SelectedBox>
              )}
            </ChartContainer>
          </InfoBoxFlavor>
          <InfoBox>
            <MainTitle>
              <MainValue>
                평가
                <QuestionIcon
                  onMouseEnter={() => {
                    setFocusOn("6");
                  }}
                  onMouseLeave={() => {
                    setFocusOn("");
                  }}
                >
                  <AiOutlineQuestionCircle
                    size={24}
                    style={{ color: "#b5bbc7" }}
                  />
                </QuestionIcon>
              </MainValue>
              {FocusOn === "6" && (
                <QuestionBox>
                  <ul>
                    <li>
                      음용성 : 얼마나 부담없이 계속 마실 수 있는가? 의 척도로,
                      가격과는 무관한 술 자체의 풍미로만 판별함. 향이나 맛의
                      강도가 강할 수록 대체로 음용성은 떨어진다.
                    </li>
                  </ul>
                </QuestionBox>
              )}
            </MainTitle>
            <Evaluate
              BalanceClicked={BalanceClicked}
              setBalanceClicked={setBalanceClicked}
              ComplexityClicked={ComplexityClicked}
              setComplexityClicked={setComplexityClicked}
            />
          </InfoBox>
          <InfoBox>
            <MainTitle>
              <MainValue>
                코멘트
                <QuestionIcon
                  onMouseEnter={() => {
                    setFocusOn("6");
                  }}
                  onMouseLeave={() => {
                    setFocusOn("");
                  }}
                >
                  <AiOutlineQuestionCircle
                    size={24}
                    style={{ color: "#b5bbc7" }}
                  />
                </QuestionIcon>
              </MainValue>
              {FocusOn === "6" && <QuestionBox></QuestionBox>}
            </MainTitle>

            <CommentWrapper>
              <TextLengthBox Length={Comment.length}>
                {Comment.length}자 / 최소 10자 이상
              </TextLengthBox>
              <CommentBox
                placeholder="리뷰하신 술에 대한 코멘트를 적어주세요."
                onChange={(e) => setComment(e.target.value)}
                value={Comment}
              ></CommentBox>
            </CommentWrapper>
          </InfoBox>
          <SubmitContainer onClick={SubmitonClick}>
            <SubmitButton>평가 완료</SubmitButton>
          </SubmitContainer>
        </UploadBox>
      </UploadContainer>
    </Fragment>
  );
}

export default PostUploadPresenter;
const TextLengthBox = styled.div`
  font-size: 13px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: ${(props) => (props.Length >= 10 ? "#4d75f2" : "red")};
  margin-right: 20px;
`;
const SubTitle = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: bold;
`;
const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 95px;
  margin-top: 50px;
  @media (max-width: 640px) {
    padding: 0px 10px;
  }
`;
const CommentBox = styled.textarea`
  display: flex;
  justify-content: center;
  resize: none;
  margin-left: 0px;
  margin-right: 0px;
  border: none;
  outline: none;
  margin-top: 10px;
  width: 100%;
  height: 200px;
  font-size: 16px;
  background-color: #f5f6f8;
  padding: 20px;
  border-radius: 6px;
  font-weight: 450;
  box-sizing: border-box;
  &::placeholder {
    font-size: 18px;
    color: #b5bbc7;
    font-weight: bold;
  }
  @media (max-width: 640px) {
    &::placeholder {
      font-size: 16px;
      color: #b5bbc7;
      font-weight: bold;
    }
  }
  @media (max-width: 640px) {
    padding: 20px;
  }
`;
const FadeIn = keyframes`
    0% {
     opacity:0;
    1OO% {
      opacity: 1;
    }
  `;

const MainValue = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const SelectedArea = styled.div`
  min-width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const SelectedInfo = styled.div`
  min-width: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #ebebeb;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 10px;
`;
const SelectColor = styled.div`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
`;
const SelectedFlavor = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${FadeIn} 1s backwards;
`;
const SelectedBox = styled.div`
  width: 50%;
  display: ${(props) => (props.control === "" ? "none" : "")};
  margin-left: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: 640px) {
    margin-top: 20px;
    width: 100%;
    margin-left: 0px;
    align-items: center;
  }
`;
const ChartContainer = styled.div`
  display: flex;
  padding: 0px 100px;
  @media (max-width: 640px) {
    flex-direction: column;
    padding: 0px;
  }
`;
const ChartBox = styled.div`
  margin-top: 50px;
  width: ${(props) => (props.control === "" ? "100%" : "50%")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 640px) {
    width: 100%;
  }
`;
const QuestionBox = styled.div`
  margin-left: 10px;
  min-width: 320px;
  max-width: 600px;
  min-height: 50px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px 50px 10px 10px;
  font-size: 16px;
  background-color: white;
  @media (max-width: 640px) {
    padding: 5px 45px 5px 5px;
    margin-top: 10px;
    margin-left: 0px;
    /* min-width: 230px; */
    max-width: 230px;
    box-sizing: border-box;
  }
`;
const QuestionIcon = styled.div`
  width: 50px;
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 640px) {
    justify-content: center;
  }
`;
const DeleteBox = styled.div`
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const DeleteFlavorButton = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  /* box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18);
  border-radius: 50%; */
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AddFlavorBox = styled.div``;

const SubmitButton = styled.div`
  cursor: pointer;
  width: 100px;
  height: 40px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 450;
`;
const SubmitContainer = styled.div`
  width: 100%;
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TasteNote = styled.div`
  font-weight: 600;
  margin: 30px 0px;
  /* text-align: left; */
  font-size: 34px;
`;
const CheckTitle = styled.div`
  font-size: 16px;
  /* margin-right: 30px; */
`;
const CheckBoxWrapper = styled.div`
  width: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 640px) {
    justify-content: center;
  }
`;
const BoxWrapper = styled.div`
  margin-bottom: 50px;
  /* width: 200px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 640px) {
    margin-bottom: 30px;
  }
`;
const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 200px;
  height: 200px;
  @media (max-width: 640px) {
    margin-top: 20px;
    height: auto;
  }
`;
const TextInput = styled.input`
  /* margin: 10px 0px; */
  width: 150px;
  font-family: "Noto Sans KR", sans-serif;
  outline: none;
  /* border: 1px solid gray; */
  border: none;
  text-align: center;
  background-color: #f5f6f8;
  border-radius: 6px;
  /* border-bottom: none; */
  /* border-top: none;
  border-right: none;
  border-left: none; */
  padding: 10px;
  height: 45px;
  font-size: 16px;
  color: black;
  font-weight: 450;
  box-sizing: border-box;
  &::placeholder {
    text-align: center;
    font-size: 16px;
    color: #b5bbc7;
  }
  @media (max-width: 640px) {
    width: 180px;
    &::placeholder {
      text-align: center;
    }
  }
`;
const BasicInfo = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 640px) {
    margin: 20px 0px;
    width: 100%;
  }
`;
const BasicInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
const BasicPic = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background-color: white;
  background-image: url(${(props) => props.src});
  background-size: 200px 200px;
  background-repeat: no-repeat;
  background-position: center;
`;
const MainTitle = styled.div`
  position: absolute;
  z-index: 40;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  font-size: 22px;
  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

const InfoBoxFlavor = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  background-color: white;
  border: 1px solid #ebebeb;
  /* border-radius: 10px; */
  border-bottom: none;
  border-right: none;
  border-left: none;
  box-sizing: border-box;
  padding: 20px 0px;
  min-height: 300px;
  width: 100%;
  margin: 10px 0px;
  /* @media (max-width: 640px) {
    padding: 10px;
    min-height: 300px;
  } */
`;
const InfoBox = styled.div`
  background-color: white;
  border: 1px solid #ebebeb;
  /* border-radius: 10px; */
  border-bottom: none;
  border-right: none;
  border-left: none;
  font-weight: 450;
  box-sizing: border-box;
  padding: 20px 0px;
  min-height: 300px;
  width: 100%;
  margin: 10px 0px;
  /* @media (max-width: 640px) {
    padding: 10px;
    min-height: 300px;
  } */
`;
const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 700px;
  /* border: 1px solid #ebebeb; */
  padding: 10px 50px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px 10px;
    box-shadow: none;
  }
  @media (max-width: 640px) {
    box-shadow: none;
    padding: 10px 15px;
    min-height: 400px;
  }
`;
const UploadContainer = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px solid red; */
  box-sizing: border-box;
  padding: 0px 50px;
  @media (max-width: 768px) {
    padding: 0px 10px;
  }
  @media (max-width: 640px) {
    width: 100%;
    padding: 0px;
    margin-top: 60px;
  }
`;
