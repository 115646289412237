import React, { Fragment } from "react";
import Helmet from "react-helmet";
import NoticeExplorePresenter from "./NoticeExplorePresenter";
function NoticeExploreContainer() {
  return (
    <Fragment>
      <Helmet>
        <title>내술 노트 | 공지사항</title>
      </Helmet>
      <NoticeExplorePresenter />
    </Fragment>
  );
}

export default NoticeExploreContainer;
