import {
  AUTH_USER,
  TEST_USER,
  USER_EMAIL,
  USER_PHONE,
  USER_ADMIN,
  USER_AGE,
  USER_GENDER,
} from "./types";
import { retrieveCurrentAuthenticatedUser } from "../utils";
import { Admin } from "../Admin";
// export async function loginUser(status) {
//   return {
//     type: LOGIN_USER,
//     payload: "success",
//     // payload: userInfo.data.attributes.name,
//   };
// }
export function UserGender(status) {
  let getUserData = retrieveCurrentAuthenticatedUser().then((response) => {
    if (response.status > 400) {
      return null;
    }
    if (response.status === 200) {
      return response.data.attributes.gender;
    }
  });
  return {
    type: USER_GENDER,
    payload: getUserData,
  };
}
export function UserAge(status) {
  let getUserData = retrieveCurrentAuthenticatedUser().then((response) => {
    if (response.status > 400) {
      return null;
    }
    if (response.status === 200) {
      return response.data.attributes["custom:age"];
    }
  });
  return {
    type: USER_AGE,
    payload: getUserData,
  };
}
export function AuthUser(status) {
  let getUserData = retrieveCurrentAuthenticatedUser().then((response) => {
    if (response.status > 400) {
      return response.status;
    }
    if (response.status === 200) {
      // console.log(response);
      return response.status;
    }
  });

  return {
    type: AUTH_USER,
    payload: getUserData,
  };
}

export function TestUser(status) {
  let getUserData = retrieveCurrentAuthenticatedUser().then((response) => {
    if (response.status > 400) {
      return null;
    }
    if (response.status === 200) {
      return response.data.attributes.name;
    }
  });
  return {
    type: TEST_USER,
    payload: getUserData,
  };
}
export function UserEmail(status) {
  let getUserData = retrieveCurrentAuthenticatedUser().then((response) => {
    if (response.status > 400) {
      return null;
    }
    if (response.status === 200) {
      return response.data.attributes.email;
    }
  });
  return {
    type: USER_EMAIL,
    payload: getUserData,
  };
}
export function UserPhone(status) {
  const getUserData = retrieveCurrentAuthenticatedUser().then((response) => {
    if (response.status > 400) {
      return null;
    }
    else if (response.status === 200) {
      return response.data.attributes.phone_number;
    }
  });
  return {
    type: USER_PHONE,
    payload: getUserData,
  };
}

export function UserAdmin(status) {
  let getUserData = retrieveCurrentAuthenticatedUser().then((response) => {
    if (response.status > 400) {
      return null;
    }
    if (response.status === 200) {
      if (Admin.find((data) => data.email === response.data.attributes.email)) {
        return true;
      } else {
        return false;
      }
    }
  });
  return {
    type: USER_ADMIN,
    payload: getUserData,
  };
}

// name: response.data.attributes.name,
//       email: response.data.attributes.email,
//       phone: response.data.attributes.phone_number,

// export function LogOutUser(status) {
//   const getAuth = retrieveCurrentAuthenticatedUser().then(
//     (response) => response.status
//   );
//   return {
//     type: LOGOUT_USER,
//     payload: getAuth,
//   };
// }
