import React, { Fragment } from "react";
import BookPresenter from "./BookPresenter";
import { useSelector } from "react-redux";
import Helmet from "react-helmet";
function BookContainer() {
  let UserEmail = useSelector((state) => state.user.Email);
  return (
    <Fragment>
      <Helmet>
        <title>내술 노트 | 리뷰 도감</title>
      </Helmet>
      <BookPresenter Email={UserEmail} />
    </Fragment>
  );
}

export default BookContainer;
