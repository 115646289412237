import React, { Fragment, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { AiOutlineUser } from "react-icons/ai";
// import { Review, Comments } from "../PostUtil/PostDummy";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { GrStatusWarning } from "react-icons/gr";
import { GiFemale } from "react-icons/gi";
import { GiMale } from "react-icons/gi";
import { FiFilter } from "react-icons/fi";

import { API } from "@aws-amplify/api";

import ReplyComment from "./ReplyComment";
// import LikeLogic from "./LikeLogic";
import { useSelector } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";

function SingleComment({ CommentsList, PostId, UserInfo }) {
  useEffect(() => {
    setLoad(true);
  }, []);

  const [Load, setLoad] = useState(false);
  const [OpenFilter, setOpenFilter] = useState(true);

  const [sex, setSex] = useState("");
  const [age, setAge] = useState(0);
  const [FilteredArray, setFilteredArray] = useState([]);
  const [EditText, setEditText] = useState("");
  const [EditOpen, setEditOpen] = useState(false);
  const [EditTarget, setEditTarget] = useState("");
  const [DotOpen, setDotOpen] = useState(false);
  const [DotTarget, setDotTarget] = useState("");
  const [ReviewTarget, setReviewTarget] = useState("");
  const [ReviewUserTarget, setReviewUserTarget] = useState("");
  const [ReplyTarget, setReplyTarget] = useState("");
  const [ReplyText, setReplyText] = useState("");
  const [ReplyEditorOpen, setReplyEditorOpen] = useState(false);
  const [ReviewedData, setReviewedData] = useState([]);
  const [ReviewOpen, setReviewOpen] = useState(false);
  const UserEmail = useSelector((state) => state.user.Email);
  // console.log(Load);

  // console.log(ReviewedData);
  const FilterResetFunc = () => {
    setSex("");
    setAge(0);
  };
  const OpenReviewAction = async (e) => {
    setReviewOpen(!ReviewOpen);
    setReviewTarget(e.target.value.split(",")[0]);
    setReviewUserTarget(e.target.value.split(",")[1]);
    // console.log(ReviewTarget);
  };

  const ReplyOpenControl = (e) => {
    setReplyTarget(e.target.value);
    setReplyEditorOpen(!ReplyEditorOpen);
  };
  const DotOpenControl = (e) => {
    setDotTarget(e.target.value);
    setDotOpen(!DotOpen);
  };
  const EditOpenControl = (e) => {
    // console.log(e.target.value);
    // console.log(e.target.value);
    setEditTarget(e.target.value);
    setEditOpen(!EditOpen);
  };
  const DeleteAction = async (e) => {
    let id = Number(e.target.value);
    // console.log(id);
    const ApiResponse = await API.del("NoticeCommentApi", "/items/" + id);
    // console.log(ApiResponse);
    window.location.reload();
  };
  const EditAction = async (e) => {
    let id = Number(e.target.value);
    let date = new Date();
    let UploadTime =
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + date.getDate()).slice(-2) +
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2);
    // console.log(id);
    const ApiResponse = await API.post("NoticeCommentApi", "/items/", {
      body: {
        id: id,
        text: EditText,
        user: UserInfo,
        postId: PostId,
        time: UploadTime,
        email: UserEmail,
        Edited: "수정됨",
      },
    });
    // console.log(ApiResponse);
    window.location.reload();
  };
  const ReplyAction = async (e) => {
    let id = Number(e.target.value);
    let date = new Date();
    let UploadTime =
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + date.getDate()).slice(-2) +
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2);
    // console.log(id);
    const ApiResponse = await API.post("NoticeCommentApi", "/items/", {
      body: {
        id: Number(Date.now()),
        text: ReplyText,
        replyId: id,
        user: UserInfo,
        postId: PostId,
        email: UserEmail,
        time: UploadTime,
      },
    });
    // console.log(ApiResponse);
    window.location.reload();
  };

  const CommentsSort = CommentsList.sort((a, b) =>
    a.id < b.id ? -1 : a.id > b.id ? 1 : 0
  );
  // console.log(CommentsSort);

  // console.log(CommentsSort);
  // console.log(FilteredArray);
  // console.log("ArrayLength" + FilteredArray.length);

  const RanderComments = CommentsSort.map((data) => {
    if (PostId === data.postId) {
      if (!data.replyId) {
        const ReviewedTag = ReviewedData.map((reviewData) => {
          if (reviewData.email === data.email && reviewData.postId == PostId) {
            return (
              <ReviewCheck value={data.id + "," + reviewData.user}>
                {/*onClick={OpenReviewAction} */}
                {/*리뷰항목 표시, 일단 Disable */}
                평가함
              </ReviewCheck>
            );
          }
          return null;
        });
        return (
          <MyReplyContainer>
            <MyReply>
              <CommentBox>
                <IconContainer>
                  <UserData>
                    <UserInfomation>
                      <MyIcon>
                        <AiOutlineUser size={32} />
                      </MyIcon>
                      {/*<LevelCheck User={data.user} />*/}
                      <User>{data.user}</User>{" "}
                      {/*
                    {data.sex === "male" && <GiMale size={16} />}
                    {data.sex === "female" && <GiFemale size={16} />}
                    <Dot>•</Dot>
                    {data.age < 60 && <UserAge>{data.age}대</UserAge>}
                    {data.age >= 60 && <UserAge>60대 이상</UserAge>}
                    나이표시, 일단 Disable
                  */}
                      {ReviewedTag}
                    </UserInfomation>
                    {DotOpen && DotTarget == data.id && data.user === UserInfo && (
                      <IconBox>
                        <EditIcon onClick={EditOpenControl} value={data.id}>
                          <AiEdit size={20} />
                        </EditIcon>
                        <DeleteIcon onClick={DeleteAction} value={data.id}>
                          <DeIcon size={20} />
                        </DeleteIcon>
                      </IconBox>
                    )}
                    {data.user === UserInfo && (
                      <DotsFuncWrapper onClick={DotOpenControl} value={data.id}>
                        <ThreeDots />
                      </DotsFuncWrapper>
                    )}
                  </UserData>
                </IconContainer>

                <TextData>
                  <Fragment>
                    <ValueBox>
                      <ValueWrapper>
                        <Text>{data.text}</Text>
                      </ValueWrapper>
                    </ValueBox>
                    {/* 
                    <LikeLogicWrapper>
                      <LikeLogic
                        PostId={PostId}
                        UserInfo={UserInfo}
                        CommentId={data.id}
                      />
                    </LikeLogicWrapper>
                    */}
                    {UserInfo && (
                      <Fragment>
                        <ReplyWrapper>
                          <PostReply value={data.id} onClick={ReplyOpenControl}>
                            댓글 달기
                          </PostReply>
                          <TimeValue>
                            {data.time} {data.Edited}
                          </TimeValue>
                        </ReplyWrapper>
                      </Fragment>
                    )}
                    {ReplyEditorOpen && ReplyTarget == data.id && (
                      <Fragment>
                        <InputWrapper>
                          <Input
                            placeholder="댓글을 입력하세요."
                            onChange={(e) => {
                              setReplyText(e.target.value);
                            }}
                            value={ReplyText}
                          ></Input>
                          <ButtonBox>
                            <Button onClick={ReplyAction} value={data.id}>
                              등록
                            </Button>
                          </ButtonBox>
                        </InputWrapper>
                      </Fragment>
                    )}
                    {EditOpen && EditTarget == data.id && (
                      <Fragment>
                        <InputWrapper>
                          <Input
                            placeholder={data.text}
                            onChange={(e) => {
                              setEditText(e.target.value);
                            }}
                            value={EditText}
                          ></Input>
                          <ButtonBox>
                            <Button onClick={() => setEditOpen(false)}>
                              취소
                            </Button>
                            <Button onClick={EditAction} value={data.id}>
                              수정
                            </Button>
                          </ButtonBox>
                        </InputWrapper>
                      </Fragment>
                    )}
                  </Fragment>
                </TextData>
              </CommentBox>
            </MyReply>

            <ReplyComment
              CommentsList={CommentsList}
              PostId={PostId}
              UserInfo={UserInfo}
              CommentId={data.id}
            />

            <Hr />
          </MyReplyContainer>
        );
      }
    }
  });

  return (
    <Fragment>
      <SingleContainer>
        <CommentArea>{RanderComments}</CommentArea>
      </SingleContainer>
    </Fragment>
  );
}

export default SingleComment;
const DotsFuncWrapper = styled.button`
  position: relative;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: white;
`;
const UserInfomation = styled.div`
  display: flex;
  align-items: center;
  color: #666775;
`;
const ThreeDots = styled(BsThreeDotsVertical)`
  pointer-events: none;
  width: 18px;
  height: 18px;
  color: lightgray;
`;
const LikeLogicWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ReplyWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const NonMobileWrapper = styled.div`
  @media (min-width: 640px) {
    display: none;
  }
`;
const MobileWrapper = styled.div`
  @media (max-width: 640px) {
    display: none;
  }
`;
const FuncBox = styled.div`
  /* border: 1px solid red; */
  margin-top: 30px;
  @media (max-width: 640px) {
    width: 100%;
  }
`;
const Move = (v) => keyframes`
from{
    width:0%;
}
to {
    width:${v}%;
}
`;
const Dot = styled.div`
  margin: 0px 5px;
`;
const UserAge = styled.div`
  font-size: 14px;
`;
const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  margin-top: 20px;
  width: 100%;
  min-height: 300px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
`;
const FloatBox = styled.div``;
const FilterTitle = styled.div`
  font-weight: bold;
  margin-left: 5px;
  font-size: 16px;
`;
const FilterButtonBox = styled.div`
  display: none;
  @media (max-width: 640px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* border-bottom: 1px solid #f0f0f0; */
    /* margin-bottom: 10px; */
    padding: 0px 0px 10px 0px;
    padding: 0px;
  }
`;
const Shining = keyframes`
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.2;
    }
  `;
const WarningIcon = styled(GrStatusWarning)`
  width: 60px;
  height: 60px;
  animation: ${Shining} 2.5s infinite linear;
`;
const ResetButton = styled.div`
  width: 120px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333335;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
const NoDataTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
`;
const NoDataContainer = styled.div`
  width: 100%;
  height: 450px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Noto Sans KR", sans-serif;
  /* border: 1px solid red; */
`;
const CommentArea = styled.div`
  width: 100%;
  display: flex;
  margin-top: 53px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 640px) {
    margin-top: 5px;
  }
  /* height: auto; */
  /* border: 1px solid red; */
`;
const ValueWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* padding-left: 10px; */
`;
const CommentLike = styled.div`
  margin-top: 5px;
`;
const PostReply = styled.button`
  outline: none;
  padding: 0px;
  text-align: left;
  width: 70px;
  cursor: pointer;

  font-size: 13px;
  color: gray;
  background-color: white;
  border: none;
  font-family: "Noto Sans KR", sans-serif;
`;
const IconBox = styled.div`
  position: absolute;
  z-index: 5;
  top: 7px;
  right: 30px;
  display: flex;
  @media (max-width: 640px) {
    top: 35px;
    right: 5px;
  }
`;
const InputWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;
const ButtonBox = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;
const Button = styled.button`
  margin: 10px 0px 10px 10px;
  cursor: pointer;
  outline: none;
  width: 90px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ededed;
  color: gray;
  padding-bottom: 0px;
  padding-top: 0px;
  font-size: 16px;
  font-family: "Noto Sans KR", sans-serif;
  &:hover {
    opacity: 0.8;
  }
`;
const AiEdit = styled(AiOutlineEdit)`
  pointer-events: none;
`;

const Input = styled.textarea`
  resize: none;
  margin-top: 20px;
  min-height: 40px;
  width: 100%;
  box-sizing: border-box;
  height: 100px;
  outline: none;
  word-break: break-all;
  border-radius: 4px;
  border: 1px solid #ededed;
  padding: 8px;
  font-size: 16px;
  &::placeholder {
    color: lightgray;
    font-size: 16px;
    font-family: "Noto Sans KR", sans-serif;
  }
  &:hover {
    &::placeholder {
      color: gray;
    }
  }
`;
const ValueBox = styled.div`
  margin-top: 20px;
  /* padding-left: 10px; */
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
`;
const TimeValue = styled.div`
  /* margin-top: 10px; */
  font-size: 12px;
  color: gray;
`;
const DeIcon = styled(AiOutlineDelete)`
  pointer-events: none;
`;
const TextData = styled.div`
  padding-left: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const EditIcon = styled.button`
  border: none;
  color: gray;
  background-color: transparent;
  margin-left: 5px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const DeleteIcon = styled.button`
  color: gray;
  border: none;
  background-color: transparent;
  padding: 0px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const UserData = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666775;
`;
const ReviewCheck = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR", sans-serif;
  outline: none;
  /* cursor: pointer; */
  border: 1px solid #ddd;
  height: 25px;
  margin-left: 10px;
  font-size: 12px;
  padding: 1px 10px;
  color: gray;
  color: #666;
  /* width: 65px; */
  font-weight: 600;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: white;
`;

const Hr = styled.div`
  /* border-bottom: 1px solid #f0f0f0; */
`;
const CommentBox = styled.div`
  /* margin-left: 20px; */
  width: 100%;
`;
const User = styled.div`
  margin-left: 15px;
  margin-right: 5px;
  font-size: 16px;
  font-weight: bold;
  color: black;
  /* font-weight: bold; */
`;
const Text = styled.div`
  font-size: 16px;
  color: #666775;
  display: flex;
  align-items: center;
`;
const SingleContainer = styled.div`
  /* box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18); */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  /* margin-top: 20px; */
  font-family: "Noto Sans KR", sans-serif;
  @media (max-width: 640px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const MyIcon = styled.div`
  background-color: lightgray;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;
const MyReply = styled.div`
  padding: 15px;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  @media (max-width: 640px) {
    /* padding: 10px 0px 10px 0px; */
    padding: 10px 0px;
  }
`;
const MyReplyContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /* min-height: 100px; */
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  /* border-right: none;
  border-left: none; */
  font-family: "Noto Sans KR", sans-serif;

  @media (max-width: 640px) {
    margin-top: 0px;
    border-radius: 0px;
    border-right: none;
    border-left: none;
    border-bottom: none;
  }
`;

const Value = styled.div`
  width: ${(props) => props.value}%;
  height: 100%;
  background-image: linear-gradient(45deg, rgb(228, 29, 92), rgb(146, 23, 77));
  border-radius: 20px;
  animation: ${(props) => Move(props.value)} 1.5s backwards;
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ValueBar = styled.div`
  width: 100%;
  height: 15px;
  border: 2px solid lightgray;
  border-radius: 20px;
  @media (max-width: 640px) {
    height: 12px;
  }
`;
const TasteTitle = styled.div`
  margin: 15px 0px;
  font-size: 16px;
  width: 100px;
  @media (max-width: 640px) {
    font-size: 13px;
  }
`;
const ReviewTitle = styled.div`
  margin: 15px 0px;
  font-size: 16px;
  width: 100%;
  @media (max-width: 640px) {
    font-size: 13px;
  }
`;
