import React, { Fragment, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import PostTaste from "./PostUtil/PostTaste";
import PostCircleInfo from "./PostUtil/PostCircleInfo";
import ReviewPresenter from "./PostUtil/ReviewPresenter";
import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
function PostInfo({
  Brand,
  Alcohol,
  Product,
  Image,
  Image2,
  Image3,
  Disc,
  Materials,
  Location,
  Category,
  PostLikeFunc,
  Liked,
  PostLikedNum,
  PostId,
  OriginPostId,
  Id,
  Price,
  Volume,
  Tag,
}) {
  useEffect(() => {
    if (Disc.length > 102) {
      setDiscSub(true);
    }
  }, []);
  // console.log(Image);
  const UserEmail = useSelector((state) => state.user.Email);

  const history = useHistory();
  const [ReviewedNum, setReviewedNum] = useState(0);
  const CommaPrice = Number(Price).toLocaleString();
  const CommaVolume = Number(Volume).toLocaleString();

  const [DiscSub, setDiscSub] = useState(false);
  const [ImageSelect, setImageSelect] = useState(Image);

  const [ReviewData, setReviewData] = useState([]);
  const [ReviewCheck, setReviewCheck] = useState(false);

  // console.log("postId:" + Id);
  const CheckReviewedFunc = () => {
    let result = ReviewData.some((data) => data.email == UserEmail);
    if (!result) {
      history.push(`/PostUpload/:${Id}`);
    }
    if (result) {
      setReviewCheck(true);
    }
  };

  const RanderTag = Tag.split(",").map((data) => {
    return <TagWrapper to={`/Search?term=${data}`}>#{data}</TagWrapper>;
  });
  return (
    <Fragment>
      <Helmet>
        <title>내술 노트 | 술 정보 :: {Product}</title>
      </Helmet>
      <PostInfoContainer>
        <Fragment>
          <MainInfoWrapper>
            <ImageWrapper>
              <ImageArea src={ImageSelect}></ImageArea>
              <ImageSliderBox>
                <ImageSelectBox
                  src={Image}
                  onClick={() => setImageSelect(Image)}
                ></ImageSelectBox>
                <ImageSelectBox
                  src={Image2}
                  onClick={() => setImageSelect(Image2)}
                ></ImageSelectBox>
                <ImageSelectBox
                  src={Image3}
                  onClick={() => setImageSelect(Image3)}
                ></ImageSelectBox>
              </ImageSliderBox>
            </ImageWrapper>
            <InfoArea>
              <BrandTitle>{Brand}</BrandTitle>
              <ProductTitle>{Product}</ProductTitle>
              <ItemData>
                <ProductMainInfo>
                  {CommaVolume}ml&nbsp;/&nbsp;
                  <PriceValue>{CommaPrice}</PriceValue>원
                </ProductMainInfo>
                <RatingInfo>{ReviewedNum}명 리뷰</RatingInfo>
              </ItemData>
              <LikeMobile onClick={PostLikeFunc} value={PostId}>
                {Liked && <Heart />}
                {!Liked && <NonHeart />}
                <LikeNumContainer>{PostLikedNum}</LikeNumContainer>
              </LikeMobile>
              <MobileImageArea src={ImageSelect} />
              <MobileImageWrapper>
                <ImageArea src={ImageSelect}></ImageArea>
                <MobileImageSliderBox>
                  <ImageSelectBox
                    src={Image}
                    onClick={() => setImageSelect(Image)}
                  ></ImageSelectBox>
                  <ImageSelectBox
                    src={Image2}
                    onClick={() => setImageSelect(Image2)}
                  ></ImageSelectBox>
                  <ImageSelectBox
                    src={Image3}
                    onClick={() => setImageSelect(Image3)}
                  ></ImageSelectBox>
                </MobileImageSliderBox>
              </MobileImageWrapper>
              <ReviewLikeContainer>
                <Like onClick={PostLikeFunc} value={PostId}>
                  {Liked && <Heart />}
                  {!Liked && <NonHeart />}
                  <LikeNumContainer>{PostLikedNum}</LikeNumContainer>
                </Like>
                <LinkBox>
                  {ReviewCheck && (
                    <ReviewedPopupBox>
                      <ReviewBoxTitle>
                        이미 평가를 작성하셨습니다{" "}
                      </ReviewBoxTitle>
                      <ReviewEditCheckBox>
                        <CheckEdit to={`/ReviewEdit/:${Id}`}>수정</CheckEdit>
                        <CheckCancel onClick={() => setReviewCheck(false)}>
                          닫기
                        </CheckCancel>
                      </ReviewEditCheckBox>
                    </ReviewedPopupBox>
                  )}
                  <LinkToUpload onClick={CheckReviewedFunc}>
                    평가 작성하기
                  </LinkToUpload>
                </LinkBox>
              </ReviewLikeContainer>
              {/*제품명, 식품유형, 내용량, 원재료명 및 함량, 에탄올 함량, 업소명, 소재지 + 판매처 빼기 */}
              <BrandInfo>
                <BrandName>{Brand}</BrandName>
                <BrandLink to={`/Search?term=${Brand}`}>브랜드홈</BrandLink>
              </BrandInfo>
              <ProductInfoArea>
                {/*<InfoContainer>
                  <InfoTitle>카테고리</InfoTitle>
                  <InfoValue>{Category}</InfoValue>
                </InfoContainer>*/}
                <InfoContainer>
                  <InfoTitle>식품유형</InfoTitle>
                  <InfoValue>{Category}</InfoValue>
                </InfoContainer>
                <InfoContainer>
                  <InfoTitle>원재료명 및 함량</InfoTitle>
                  <InfoValue>{Materials}</InfoValue>
                </InfoContainer>
                <InfoContainer>
                  <InfoTitle>에탄올 함량</InfoTitle>
                  <InfoValue>{Alcohol}%</InfoValue>
                </InfoContainer>
                {/*<InfoContainer>
                  <InfoTitle>업소명</InfoTitle>
                  <InfoValue>{Shop}</InfoValue>
                </InfoContainer>*/}
                <InfoContainer>
                  <InfoTitle>소재지</InfoTitle>
                  <InfoValue>{Location}</InfoValue>
                </InfoContainer>
                <InfoContainer>
                  <InfoTitle>설명</InfoTitle>
                  <MoreInfoBox>
                    {DiscSub && (
                      <InfoValue>{Disc.substring(0, 102) + "..."}</InfoValue>
                    )}
                    {!DiscSub && <InfoValue>{Disc}</InfoValue>}
                    <MoreButtonContainer>
                      {Disc.length > 102 && (
                        <MoreButton onClick={() => setDiscSub(!DiscSub)}>
                          {DiscSub?"더보기" : "닫기" }
                        </MoreButton>
                      )}
                    </MoreButtonContainer>
                  </MoreInfoBox>
                </InfoContainer>
                <InfoContainer>
                  <InfoTitle>태그</InfoTitle>
                  <TagContainer>{RanderTag} </TagContainer>
                </InfoContainer>
              </ProductInfoArea>
            </InfoArea>
          </MainInfoWrapper>
        </Fragment>
        <MoreInfoWrapper>
          <ReviewMobileDisable>
            <ReviewPresenter PostId={OriginPostId} />
          </ReviewMobileDisable>
          <PostTaste
            setReviewedNum={setReviewedNum}
            PostId={OriginPostId}
            ReviewData={ReviewData}
            setReviewData={setReviewData}
            ReviewedNum={ReviewedNum}
          />
        </MoreInfoWrapper>
      </PostInfoContainer>
    </Fragment>
  );
}

export default PostInfo;

const MobileImageWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  @media (min-width: 641px) {
    display: none;
  }
`;
const MobileImageSliderBox = styled.div`
  width: 100%;
  display: flex;
`;
const ReviewMobileDisable = styled.div`
  @media (max-width: 640px) {
    display: none;
  }
`;

const PopUp = keyframes`
from{
    opacity:0;
}
to {
    opacity:1;
}
`;
const ImageSelectBox = styled.div`
  outline: none;
  cursor: pointer;
  width: 65px;
  height: 65px;
  box-sizing: border-box;
  border: 1px solid #f2f2f2;
  margin-right: 10px;
  padding: 5px;
  background-image: url(${(props) => props.src});
  background-size: 60px 60px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
`;
const ImageSliderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 640px) {
    display: none;
  }
`;
const MoreInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* border: 1px solid red; */
  width: 100%;
  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
const MainInfoWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
const TagWrapper = styled(Link)`
  text-decoration: none;
  min-width: 60px;
  height: 28px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 30px;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 700;
  color: #666;
  text-align: center;
  box-sizing: border-box;
  @media (max-width: 640px) {
    margin-bottom: 10px;
  }
`;

const rotation = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

const ReviewBoxTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
`;
const ReviewEditCheckBox = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;
const CheckEdit = styled(Link)`
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 13px;
  width: 40px;
`;
const CheckCancel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 13px;
  width: 40px;
`;

const ReviewedPopupBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px;
  z-index: 10;
  width: 220px;
  box-sizing: border-box;
  border: 1px solid #f2f2f2;
  margin-right: 20px;
  border-radius: 5px;
  animation: ${rotation} 1s backwards;
  background-color: white;
`;

const LinkBox = styled.div`
  display: flex;
  align-items: flex-start;
  height: 40px;
  position: relative;
`;

const LinkToUpload = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #111113;
  color: #111113;
  font-weight: bold;
  background-color: white;
  min-width: 90px;
  height: 32px;
  text-decoration: none;
  font-size: 12px;
  border-radius: 2px;
  &:hover {
    background-color: black;
    color: white;
  }
`;
const ReviewLikeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 640px) {
    margin-top: 20px;
    justify-content: flex-end;
  }
`;
const LikeNumContainer = styled.div`
  margin-left: 10px;
  font-weight: bold;
  font-size: 18px;
  color: white;
`;
const NonHeart = styled(FaRegHeart)`
  width: 20px;
  height: 20px;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;
const Heart = styled(FaHeart)`
  width: 20px;
  height: 20px;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const LikeMobile = styled.button`
  @media (max-width: 640px) {
    outline: none;
    display: flex;
    margin-bottom: 10px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    background-color: #e80521;
    min-width: 60px;
    height: 40px;
    border-radius: 10px;
    border: transparent;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  @media (min-width: 641px) {
    display: none;
  }
`;
const Like = styled.button`
  outline: none;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  background-color: #e80521;
  min-width: 60px;
  height: 40px;
  border-radius: 10px;
  border: transparent;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 640px) {
    display: none;
  }
`;
// const ReviewDataContainer = styled.div`
//   width: 100%;
//   min-height: 300px;
//   border: 1px solid red;
// `;
const MoreButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const MoreButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  color: #111111;
  font-weight: bold;
  text-decoration: underline;
`;

const MoreInfoBox = styled.div`
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 30px 0px;
  width: 100%;
`;
const InfoTitle = styled.div`
  min-width: 120px;
  font-size: 13px;
  color: #84868e;
  font-weight: bold;
`;
const InfoValue = styled.div`
  font-size: 14px;
  color: #343648;
`;

const BrandInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  padding: 20px 0px;
  box-sizing: border-box;

  border-bottom: 1px solid #f0f0f2;
  @media (max-width: 640px) {
    margin-top: 10px;
    border-top: 1px solid #f0f0f2;
  }
`;
const BrandName = styled.div`
  font-weight: bold;
  font-size: 15px;
  color: #111111;
`;
const BrandLink = styled(Link)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #111113;
  color: #111113;
  font-weight: bold;
  background-color: white;
  min-width: 70px;
  height: 28px;
  text-decoration: none;
  font-size: 12px;
  border-radius: 2px;
  &:hover {
    background-color: black;
    color: white;
  }
`;
const RatingInfo = styled.div`
  font-size: 14px;
  color: #666775;
  font-weight: bold;
`;
const PriceValue = styled.div`
  font-size: 14px;
  color: #111111;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ProductMainInfo = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #84868e;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BrandTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #e80521;
`;
const ProductTitle = styled.div`
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: bold;
  color: black;
`;
const ItemData = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ProductInfoArea = styled.div`
  padding: 13px 0px;
`;
const PostInfoContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  /* border: 1px solid blue; */
  padding: 0px 10px;
  /* font-family: "RIDIBatang", sans-serif; */
`;

const ImageArea = styled.div`
  width: 300px;
  height: 350px;
  /* border: 1px solid red; */
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;

  @media (max-width: 640px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  width: 30%;
  @media (max-width: 640px) {
    display: none;
  }
`;
const MobileImageArea = styled.div`
  @media (max-width: 640px) {
    width: 100%;
    height: 250px;
    /* border: 1px solid red; */
    background-image: url(${(props) => props.src});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;
const InfoArea = styled.div`
  padding: 25px 50px;
  box-sizing: border-box;
  min-width: 70%;
  min-height: 350px;
  /* border: 1px solid red; */
  @media (max-width: 640px) {
    width: 100%;
    padding: 25px 10px;
  }
`;
