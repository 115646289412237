import * as Yup from "yup";

import { Button, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";

import { confirmSignUp, verifyUserConfirm } from "../../utils";

import { Loader } from "../../components";
import { MailOutline } from "@material-ui/icons";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "../../theme";
import { useFormik } from "formik";

const labelAuthCode = "인증코드";

const Form = styled.form`
  width: "100%";
  margin-top: ${theme.spacing(1)};
`;

const BoldButton = styled(Button)`
  font-weight: bold;
`;

const ButtonGrid = styled(Grid)`
  margin: ${theme.spacing(2, 0, 2)};
`;

const Message = styled.div`
  display: flex;
  margin: ${theme.spacing(2, 0, 1)};
  padding: 0.5rem;
  border-radius: 0.3rem;
  & :nth-child(1) {
    margin-right: 0.5rem;
  }
`;

const ButtonLoaderWrapper = styled.div`
  position: relative;
`;

const UserFormConfirm = (props) => {
  const { userProfile, onBackAction, goFinish, mode } = props;
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      authcode: "",
    },
    validationSchema: Yup.object({
      authcode: Yup.string().required(
        `${labelAuthCode}가 입력되지 않았습니다.`
      ),
    }),
    onSubmit: () => {},
  });

  const onConfirmSignup = async () => {
    // console.log("onConfirmeSignup", formik);
    // console.log("onConfirmSignup userProfile", userProfile);
    setIsLoading(true);
    formik.submitForm();
    if (!formik.isValid) {
      setIsLoading(false);
      return;
    }

    let status;
    let data;
    if (mode === "modify") {
      const res = await confirmSignUp({
        name: userProfile.email,
        confirmCode: formik.values.authcode,
      });
    } else {
      const res = await confirmSignUp({
        name: userProfile.email,
        confirmCode: formik.values.authcode,
        // userlevel: userProfile.userlevel,
      });
      data = res.data;
      status = res.status;
    }

    setIsLoading(false);
    // console.log("onConfirmSignup/confirm", { status, data });
    if (status === 200) {
      goFinish();
    } else if (status >= 400) {
      if (data.code.indexOf("CodeMismatchException") > -1) {
        formik.setFieldError("authcode", `잘못된 인증번호가 입력되었습니다.`);
      } else if (data.code.indexOf("UserNotFoundException") > -1) {
        formik.setFieldError(
          "authcode",
          `잘못된 요청입니다. 처음 단계부터 다시 진행해주세요.`
        );
      }
      return;
    }
  };

  const onCancel = () => {
    onBackAction();
  };

  return (
    <Form noValidate>
      <Message>
        <MailOutline />
        <span>{`${userProfile.email} 로 인증코드가 발송되었으니, 메일에 적힌 인증코드를 입력해주세요.`}</span>
      </Message>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="authcode"
        label={labelAuthCode}
        name="authcode"
        error={formik.touched.authcode && !!formik.errors.authcode}
        helperText={formik.touched.authcode && formik.errors.authcode}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.authcode}
        autoFocus
      />

      <ButtonGrid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <BoldButton
          color="primary"
          size="large"
          onClick={onCancel}
        >{`뒤로`}</BoldButton>
        <ButtonLoaderWrapper>
          <BoldButton
            color="primary"
            size="large"
            disabled={isLoading}
            onClick={onConfirmSignup}
          >{`회원가입`}</BoldButton>
          {isLoading && <Loader size={24} />}
        </ButtonLoaderWrapper>
      </ButtonGrid>
    </Form>
  );
};

UserFormConfirm.propTypes = {
  userProfile: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    passwordConfirm: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  onBackAction: PropTypes.func,
  goFinish: PropTypes.func,
  mode: PropTypes.string,
};

export { UserFormConfirm };

// UserFormConfirm.defaultProps = {
//   stores: [],
//   userlevle: [],
// };
