/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_identity_pool_id": "ap-northeast-2:92ef9b73-1353-4bd4-aa8c-22d633ab3c64",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_a2xeiiAW0",
    "aws_user_pools_web_client_id": "lbbb2ddgp0g12rftm2k3kr73f",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "CommentApi",
            "endpoint": "https://npfz0cs0tj.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        },
        {
            "name": "CommentLikeApi",
            "endpoint": "https://xwy931buc1.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        },
        {
            "name": "PostLikeApi",
            "endpoint": "https://f0b24dkoid.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        },
        {
            "name": "AlcoholPostApi",
            "endpoint": "https://n0jft2riy2.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        },
        {
            "name": "TodayRecommendApi",
            "endpoint": "https://8g5io92ddc.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        },
        {
            "name": "AlcoholReviewApi",
            "endpoint": "https://3blzqgdm8e.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        },
        {
            "name": "NoticeApi",
            "endpoint": "https://8a48q7icp5.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        },
        {
            "name": "FreeBoardApi",
            "endpoint": "https://pom7qr5dm4.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        },
        {
            "name": "FreeBoardCommentApi",
            "endpoint": "https://ed8t6nj058.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        },
        {
            "name": "NoticeCommentApi",
            "endpoint": "https://du2i8buub7.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "ap-northeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Comment-dev",
            "region": "ap-northeast-2"
        },
        {
            "tableName": "CommentLikeTable-dev",
            "region": "ap-northeast-2"
        },
        {
            "tableName": "PostLikeTable-dev",
            "region": "ap-northeast-2"
        },
        {
            "tableName": "AlcoholPostTable-dev",
            "region": "ap-northeast-2"
        },
        {
            "tableName": "TodayRecommendTable-dev",
            "region": "ap-northeast-2"
        },
        {
            "tableName": "AlcoholReviewTable-dev",
            "region": "ap-northeast-2"
        },
        {
            "tableName": "NoticeTable-dev",
            "region": "ap-northeast-2"
        },
        {
            "tableName": "FreeBoardTable-dev",
            "region": "ap-northeast-2"
        },
        {
            "tableName": "FreeBoardCommentTable-dev",
            "region": "ap-northeast-2"
        },
        {
            "tableName": "NoticeCommentTable-dev",
            "region": "ap-northeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "s3bucket95339-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-2"
};


export default awsmobile;
