const getDashedValue = ({ value }) => {
  const noDashValue = value && value.match(/\d*/g).join('');
  let dashedValue = '';
  // https://ko.wikipedia.org/wiki/대한민국의_전화번호_체계#이동통신_및_부가통신망_등_(01X)
  // local: 010
  // exchange: xxxx
  // individual: yyyy
  // ex) 010-xxxx-yyyy
  if (noDashValue.length > 3 && noDashValue.length < 8) {
    const validTelRegex = /^([0-9]{3})([0-9]+)$/;
    const telValidation = validTelRegex.exec(noDashValue);
    const [stateTel, local, exchange] = telValidation;
    dashedValue = `${local}-${exchange}`;
  } else if (noDashValue.length > 7 && noDashValue.length < 10) {
    const validTelRegex = /^([0-9]{3})([0-9]{3})([0-9]+)$/;
    const telValidation = validTelRegex.exec(noDashValue);
    const [stateTel, local, exchange, individual] = telValidation;
    dashedValue = `${local}-${exchange}-${individual}`;
  } else if (noDashValue.length === 10) {
    const validTelRegex = /^([0-9]{3})([0-9]{3})([0-9]{4})$/;
    const telValidation = validTelRegex.exec(noDashValue.substr(0, 11));
    const [stateTel, local, exchange, individual] = telValidation;
    dashedValue = `${local}-${exchange}-${individual}`;
  } else if (noDashValue.length > 10) {
    const validTelRegex = /^([0-9]{3})([0-9]{4})([0-9]{4})$/;
    const telValidation = validTelRegex.exec(noDashValue.substr(0, 11));
    const [stateTel, local, exchange, individual] = telValidation;
    dashedValue = `${local}-${exchange}-${individual}`;
  } else {
    dashedValue = noDashValue;
  }
  return {
    dashedValue,
    noDashedValue: noDashValue,
  };
};
const validatePhone = ({ value }) => {
  const validTelRegex = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;
  const telValidation = validTelRegex.exec(value);
  const isInValid = value.length > 0 && !telValidation;
  return {
    isValid: !isInValid,
  };
};
export { getDashedValue, validatePhone };
