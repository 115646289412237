import React, { Fragment, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import PostTaste from "./PostUtil/PostTaste";
import MyComment from "./Comment/MyComment";
import PostInfo from "./PostInfo";
import { API } from "@aws-amplify/api";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AiOutlineLoading } from "react-icons/ai";

function PostPresenter({ PostId, Posts, UserInfo, LoginStatus }) {
  // console.log(Posts);
  const UserAge = useSelector((state) => state.user.Age);
  const UserGender = useSelector((state) => state.user.Gender);
  // console.log(UserAge);
  // console.log(UserGender);
  const history = useHistory();
  const [PostLikedNum, setPostLikedNum] = useState(0);
  // const [ReviewData, setReviewData] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [Liked, setLiked] = useState(false);
  const [LikeTarget, setLikeTarget] = useState("");

  let a = [];
  useEffect(() => {
    getPostLike();
  }, [Liked]);

  const getPostLike = async (e) => {
    const ApiResponse = await API.get("PostLikeApi", "/items/", PostId);
    await ApiResponse.map((data) => {
      if (PostId === data.postId) {
        a.push("1");
        if (data.user == UserInfo) {
          setLikeTarget(data.id);
          setLiked(true);
        }
      }
    });

    setPostLikedNum(a.length);
    // console.log(a);
    setIsLoading(true);
  };
  const PostLikeFunc = async (e) => {
    if (UserInfo) {
      if (!Liked) {
        let id = Number(Date.now());
        // console.log(id);
        const ApiResponse = await API.post("PostLikeApi", "/items/", {
          body: {
            id: id,
            user: UserInfo,
            postId: PostId,
          },
        });
        setLiked(true);
        getPostLike();
        // console.log(ApiResponse);
      }
      if (Liked) {
        let id = LikeTarget;
        const ApiResponse = await API.del("PostLikeApi", "/items/" + id);
        setLiked(false);
        getPostLike();
        // console.log(ApiResponse);
      }
    } else {
      history.push("/Signin");
    }
  };
  // console.log(PostId);
  const RanderPosts = Posts.map((data) => {
    if (PostId == data.id) {
      // console.log("yes");
      return (
        <Fragment>
          <PostInfo
            Brand={data.company}
            Product={data.name}
            Image={data.image}
            Image2={data.image2}
            Image3={data.image3}
            Disc={data.disc}
            Materials={data.materials}
            Alcohol={data.alcohol}
            Location={data.location}
            Category={data.category}
            PostLikeFunc={PostLikeFunc}
            Liked={Liked}
            Tag={data.tag}
            PostLikedNum={PostLikedNum}
            PostId={data.PostId}
            OriginPostId={PostId}
            Price={data.price}
            Volume={data.volume}
            Id={data.id}
          />
          {/*
          <Image src={data.image}></Image>
          <InfoBox>
            <Product>{data.name}</Product>
            <Company>{data.company}</Company>
            <Like onClick={PostLikeFunc} value={data.PostId}>
              {Liked && <Heart size={26} />}
              {!Liked && <NonHeart size={26} />}
              <LikeNumContainer>{PostLikedNum}</LikeNumContainer>
            </Like>
            <LinkToUpload to={`/PostUpload/:${data.id}`}>
              평가 작성하기
            </LinkToUpload>
          </InfoBox>
          */}
        </Fragment>
      );
    }
  });

  return (
    <Fragment>
      <PostWrapper>
        {IsLoading && (
          <Fragment>
            <PostContainer>
              <PostInfoBox>{RanderPosts}</PostInfoBox>
              {/*
          <PostTasteMobile>
            <PostTaste />
          </PostTasteMobile>
          */}
            </PostContainer>
            <PostContainer>
              <CommContainer>
                <Title>커뮤니티 리뷰</Title>
                <MyComment
                  UserInfo={UserInfo}
                  LoginStatus={LoginStatus}
                  PostId={PostId}
                  UserAge={UserAge}
                  UserGender={UserGender}
                />
              </CommContainer>
            </PostContainer>
          </Fragment>
        )}
        {!IsLoading && (
          <Fragment>
            <PostContainer>
              <LoadingContainer>
                <LoadingIcon>
                  <AiOutlineLoading size={32} />
                </LoadingIcon>
              </LoadingContainer>
            </PostContainer>
          </Fragment>
        )}
      </PostWrapper>
    </Fragment>
  );
}

export default PostPresenter;
const rotation = keyframes`
    0% {
      transform: rotate(0deg);
      opacity: 0.1;
    }
    25% {
      transform: rotate(90deg);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.1;
    }
    75% {
      transform: rotate(360deg);
      opacity: 1;
    }
  `;
const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoadingIcon = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotation} 1s infinite linear;
`;
const LikeNumContainer = styled.div`
  margin-left: 10px;
  font-size: 20px;
  color: white;
`;
const PostTasteMobile = styled.div`
  /* @media (max-width: 850px) {
    display: none;
  } */
`;
const CommContainer = styled.div`
  width: 100%;
  /* height: 100%; */
  /* padding: 50px; */
  /* padding: 0px 50px; */
  box-sizing: border-box;
  @media (max-width: 640px) {
    padding: 20px 0px 10px 0px;
  }
`;
const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  /* font-family: "RIDIBatang", sans-serif; */
  margin-bottom: 10px;
  @media (max-width: 640px) {
    font-size: 24px;
  }
`;

const NonHeart = styled(FaRegHeart)`
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;
const Heart = styled(FaHeart)`
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;
const Like = styled.button`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eb5065;
  min-width: 60px;
  height: 40px;
  border-radius: 10px;
  border: transparent;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
const InfoBox = styled.div`
  /* width: 110px; */
  padding: 15px 0px;
  height: 100%;
  /* margin-left: 24px; */
  /* border: 2px dashed red; */
  box-sizing: border-box;
  /* font-family: "RIDIBatang", sans-serif; */
`;

const Company = styled.div`
  font-size: 20px;
  @media (max-width: 640px) {
    font-size: 16px;
  }
`;

const Product = styled.div`
  /* width: 150px; */
  font-size: 28px;
  font-weight: bold;
  @media (max-width: 640px) {
    font-size: 20px;
  }
`;

const Rate = styled.div`
  margin-top: 5px;
  font-size: 18px;
`;
const LinkToUpload = styled(Link)`
  text-decoration: none;
  display: flex;
  width: 110px;
  height: 40px;
  color: white;
  background-color: #eb5065;
  border-radius: 10px;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
  padding-top: 3px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Image = styled.div`
  width: 300px;
  height: 450px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  @media (max-width: 1200px) {
    width: 300px;
    height: 370px;
  }
  @media (max-width: 1024px) {
    width: 300px;
    height: 350px;
  }
  @media (max-width: 768px) {
    height: 300px;
    width: 200px;
  }
  @media (max-width: 320px) {
    height: 180px;
    width: 160px;
  }
`;
const PostInfoBox = styled.div`
  /* border: 2px solid green; */
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const PostContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* margin: 20px 0px; */
  min-height: 550px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  /* box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18); */
  border-radius: 10px;
  box-sizing: border-box;
  padding: 25px 90px;
  @media (max-width: 640px) {
    min-height: 300px;
    box-shadow: none;
    margin: 0px;
    flex-direction: column;
    padding: 10px 10px;
    align-items: center;
  }
  @media (max-width: 1200px) {
    min-height: 450px;
  }
  @media (max-width: 1024px) {
    padding: 10px 10px;
  }
`;
const PostWrapper = styled.div`
  margin-top: 100px;
  @media (max-width: 640px) {
    margin-top: 60px;
  }
`;
