import React, { useState, Fragment, useEffect } from "react";
import { API } from "@aws-amplify/api";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import { GoComment } from "react-icons/go";
import { AiOutlineLoading } from "react-icons/ai";
import { GrStatusWarning } from "react-icons/gr";
import SearchBarBlack from "../Components/SearchBarBlack";
function SearchPresenter({ Term }) {
  const [LoadingRander, setLoadingRander] = useState(true);
  const [PostLike, setPostLike] = useState([]);
  const [CommentNum, setCommentNum] = useState([]);

  const [Posts, setPosts] = useState([]);
  useEffect(() => {
    GetPostLike();
    GetComment();
    GetAlcoholPost();
  }, []);
  const GetAlcoholPost = async () => {
    const ApiResponse = await API.get("AlcoholPostApi", "/items/");
    setPosts(ApiResponse);
    setLoadingRander(false);
  };
  const GetPostLike = async () => {
    const ApiResponse = await API.get("PostLikeApi", "/items/");
    setPostLike(ApiResponse);
  };
  const GetComment = async () => {
    const ApiResponse = await API.get("CommentApi", "/items/");
    setCommentNum(ApiResponse);
  };
  const PostSearch = Posts.filter(
    (data) =>
      data.company.includes(Term) ||
      data.name.includes(Term) ||
      data.category.includes(Term)
  );
  // const PostSearch = Posts.filter(
  //   (data) => data.company == Term || data.name == Term || data.category == Term
  // );

  const RanderPosts = PostSearch.map((data) => {
    let like = PostLike.filter((like) => like.postId == data.id);
    let comment = CommentNum.filter((comments) => comments.postId == data.id);

    return (
      <PostBox to={`/Post/:${data.id}`}>
        <Image src={data.image}></Image>
        <Product>{data.name}</Product>
        <Company>{data.company}</Company>
        <InfoBox>
          <Rate>
            <FaHeart
              size={18}
              style={{ color: "#e80521", marginRight: "5px" }}
            />
            {like.length}
          </Rate>
          <Comments>
            <GoComment size={18} style={{ marginRight: "5px" }} />
            {comment.length}
          </Comments>
        </InfoBox>
      </PostBox>
    );
  });
  return (
    <Fragment>
      <SearchContainer>
        <SearchTermBox>
          <SearchTerm>
            검색어 <TermText>"{Term}"</TermText>
          </SearchTerm>
        </SearchTermBox>
        {!LoadingRander && PostSearch.length == 0 && (
          <NoDataContainer>
            <WarningIcon />
            <NoDataTitle>
              검색결과가 없습니다. 다른 키워드로 검색해보세요.
            </NoDataTitle>
            <SearchBarBlack />
          </NoDataContainer>
        )}
        {!LoadingRander && PostSearch.length >= 1 && (
          <ExpContainer>
            <GridContainer>{RanderPosts}</GridContainer>
          </ExpContainer>
        )}
        {LoadingRander && (
          <Fragment>
            <LoadingContainer>
              <LoadingIcon>
                <AiOutlineLoading size={32} />
              </LoadingIcon>
            </LoadingContainer>
          </Fragment>
        )}
      </SearchContainer>
    </Fragment>
  );
}

export default SearchPresenter;
const TermText = styled.div`
  margin-left: 20px;
  font-weight: bold;
  font-size: 24px;
  @media (max-width: 640px) {
    font-size: 20px;
    margin-left: 10px;
  }
`;
const Shining = keyframes`
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.2;
    }
  `;
const WarningIcon = styled(GrStatusWarning)`
  width: 60px;
  height: 60px;
  animation: ${Shining} 2.5s infinite linear;
`;

const NoDataTitle = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
`;
const NoDataContainer = styled.div`
  width: 100%;
  height: 350px;
  font-size: 22px;
  padding: 0px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Noto Sans KR", sans-serif;
  /* border: 1px solid red; */
`;
const rotation = keyframes`
    0% {
      transform: rotate(0deg);
      opacity: 0.1;
    }
    25% {
      transform: rotate(90deg);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.1;
    }
    75% {
      transform: rotate(360deg);
      opacity: 1;
    }
  `;
const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoadingIcon = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotation} 1s infinite linear;
`;
const Product = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: black;
`;
const Company = styled.div`
  color: black;
`;
const InfoBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  color: gray;
`;
const Rate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Comments = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.div`
  width: 150px;
  height: 180px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
`;
const PostBox = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  min-height: 230px;
  margin: 5px 5px;
  padding: 5px 5px;
  border: 1px solid #f2f2f2;

  border-radius: 10px;
  /* border-radius: 20px; */
  box-sizing: border-box;
  transition: transform 0.3s;
  /* -webkit-transform: translate3d(0, 0, 0); */
  &:hover {
    opacity: 0.8;
    /* transform: perspective(600px) rotateY(15deg); */
  }
`;
const GridContainer = styled.div`
  margin-top: 10px;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 850px) {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 650px) {
    width: 100%;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 420px) {
    width: 80%;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(1, 1fr);
  }
`;
const ExpContainer = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 1100px;
  min-height: 500px;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px solid red; */
  box-sizing: border-box;
  font-family: "Noto Sans KR", sans-serif;
  @media (max-width: 640px) {
    margin-top: 40px;
  }
`;
const SearchTerm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  font-size: 22px;
  font-family: "Noto Sans KR", sans-serif;
  /* font-weight: bold; */
  @media (max-width: 640px) {
    font-size: 18px;
  }
`;
const SearchTermBox = styled.div`
  margin-top: 50px;
  /* border: 1px solid red; */
`;

const SearchContainer = styled.div`
  margin-top: 100px;
  max-width: 1200px;
  min-height: 400px;
  margin-left: auto;
  margin-right: auto;
  /* border: 1px solid red; */
  @media (max-width: 640px) {
    margin-top: 80px;
  }
`;
