import React, { Fragment, lazy } from "react";
import styled, { keyframes } from "styled-components";
import { Route } from "react-router-dom";
/////////////////////////////////////////////////////////////////////////////////
import Auth from "./hoc/auth";
import PostContainer from "./Views/Post/PostContainer";
import PostUploadContainer from "./Views/PostUpload/PostUploadContainer";
import ReviewEditContainer from "./Views/PostUpload/ReviewEditContainer";
import PostExploreBySearch from "./Views/PostUpload/PostExploreBySearch";
import SearchContainer from "./Views/Search/SearchContainer";
import NoticeExploreContainer from "./Views/Notice/NoticeExploreContainer";
import NoticeContainer from "./Views/Notice/NoticeContainer";
import UploadByManualMac from "./Views/PostUpload/UploadByManualMac";
import UploadByManualYac from "./Views/PostUpload/UploadByManualYac";

import FreeBoardUploadPresenter from "./Views/FreeBoard/FreeBoardUploadPresenter";
import FreeBoardEditPresenter from "./Views/FreeBoard/FreeBoardEditPresenter";

import NoticeUploadPresenter from "./Views/Notice/NoticeUploadPresenter";
import FreeBoardExploreContainer from "./Views/FreeBoard/FreeBoardExploreContainer";
import TestField from "./Views/TestField";
import SignUp from "./Views/SignUp";
// import SignUp from "./signup";
import ScrollToTop from "./Views/ScrollToTop";
import NewPostUpload from "./Views/Post/NewPostUpload";
import CheckUploadReview from "./Views/PostUpload/UploadUtil/CheckUploadReview";
import BookContainer from "./Views/Book/BookContainer";

import FreeBoardContainer from "./Views/FreeBoard/FreeBoardContainer";
import AdminPresenter from "./Views/AdminFunc/AdminPresenter";

/////////////////////////////////////////////////////////////////////////////////
const SignIn = lazy(() => import("./Views/SignIn"));
const Main = lazy(() => import("./Views/Main"));
// const SignUp = lazy(() => import("./signup"));
const Find = lazy(() => import("./find"));
const Profile = lazy(() => import("./Views/Profile/Profile"));
const PostExplore = lazy(() => import("./Views/Post/PostExplore"));
/////////////////////////////////////////////////////////////////////////////////ㅋ
function Routes() {
  return (
    <Fragment>
      <PageAnimation>
        <ScrollToTop />

        <Route exact path="/" component={Auth(Main, null)} />
        {/* 메인 페이지 */}
        <Route exact path="/Signup" component={Auth(SignUp, false)} />
        {/* 회원가입 페이지*/}
        <Route exact path="/Profile" component={Auth(Profile, true)} />
        {/* 프로필 페이지 */}
        <Route exact path="/Signin" component={Auth(SignIn, false)} />
        {/* 로그인 페이지 */}
        <Route exact path="/Find" component={Auth(Find, false)} />
        {/* 아이디/비밀번호 찾기 페이지 */}
        <Route exact path="/PostExplore" component={Auth(PostExplore, null)} />
        {/* 리뷰 탐색 페이지 */}
        <Route
          exact
          path="/Post/:PostId"
          component={Auth(PostContainer, null)}
        />
        {/* 리뷰 페이지 */}
        <Route exact path="/TestField" component={Auth(TestField, null)} />
        <Route
          exact
          path="/CheckUploadReview"
          component={Auth(CheckUploadReview, true)}
        />
        <Route
          exact
          path="/FreeBoardEdit/:PostId/"
          component={Auth(FreeBoardEditPresenter, true)}
        />

        <Route
          exact
          path="/NewPostUpload"
          component={Auth(NewPostUpload, true)}
        />
        <Route exact path="/AdminPage" component={Auth(AdminPresenter, true)} />

        <Route
          exact
          path="/ReviewEdit/:PostId/"
          component={Auth(ReviewEditContainer, true)}
        />

        <Route
          exact
          path="/PostUpload/:PostId/"
          component={Auth(PostUploadContainer, true)}
        />
        <Route
          exact
          path="/PostExploreBySearch"
          component={Auth(PostExploreBySearch, null)}
        />

        <Route
          exact
          path="/NoticeUpload"
          component={Auth(NoticeUploadPresenter, true)}
        />
        <Route
          exact
          path="/Notice"
          component={Auth(NoticeExploreContainer, null)}
        />
        <Route
          exact
          path="/Notice/:NoticeId"
          component={Auth(NoticeContainer, null)}
        />
        <Route
          exact
          path="/FreeBoard"
          component={Auth(FreeBoardExploreContainer, null)}
        />
        <Route exact path="/Book" component={Auth(BookContainer, true)} />
        <Route
          exact
          path="/FreeBoard/:PostId"
          component={Auth(FreeBoardContainer, null)}
        />
        <Route
          exact
          path="/FreeBoardUpload"
          component={Auth(FreeBoardUploadPresenter, true)}
        />
        <Route
          exact
          path="/UploadByManualMac"
          component={Auth(UploadByManualMac, true)}
        />
        <Route
          exact
          path="/UploadByManualYac"
          component={Auth(UploadByManualYac, true)}
        />
        <Route exact path="/Search" component={Auth(SearchContainer, null)} />
      </PageAnimation>
    </Fragment>
  );
}

export default Routes;
const PopUp = keyframes`
from{
    opacity:0;
}
to {
    opacity:1;
}
`;
const PageAnimation = styled.div`
  animation: ${PopUp} 0.7s backwards;
`;
