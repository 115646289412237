import React, { useState, Fragment, useEffect } from "react";
import { API } from "@aws-amplify/api";
import styled, { keyframes } from "styled-components";
import { AiOutlineLoading } from "react-icons/ai";
import { GrStatusWarning } from "react-icons/gr";
import { Link } from "react-router-dom";
function AutoSearchHearder({ text }) {
  useEffect(() => {
    GetAlcoholPost();
  }, [text]);
  const [Loading, setLoading] = useState(false);
  const [Posts, setPosts] = useState([]);
  const GetAlcoholPost = async () => {
    const ApiResponse = await API.get("AlcoholPostApi", "/items/");
    setPosts(ApiResponse);
    setLoading(true);
  };
  const PostSearch = Posts.filter(
    (data) =>
      data.company.includes(text) ||
      data.name.includes(text) ||
      data.category.includes(text)
  );
  const RanderPosts = PostSearch.map((data) => {
    if (text != "") {
      return (
        <Fragment>
          <PostBox to={`/Post/:${data.id}`}>
            <Image src={data.image}></Image>
            <InfoBox>
              <Product>{data.name}</Product>
              <Company>{data.company}</Company>
            </InfoBox>
          </PostBox>
        </Fragment>
      );
    }
  });
  return (
    <Fragment>
      <AutoContainer>
        {text == "" && (
          <NoBox>
            키워드를 검색해보세요
            <NoBoxDetail>예) 참이슬, 진로, 소주</NoBoxDetail>
          </NoBox>
        )}
        {PostSearch.length == 0 && (
          <NoDataContainer>
            <WarningIcon />
            <NoDataTitle>
              검색결과가 없습니다. <br />
              다른 키워드로 검색해보세요.
            </NoDataTitle>
          </NoDataContainer>
        )}
        {RanderPosts}
      </AutoContainer>
    </Fragment>
  );
}

export default AutoSearchHearder;
const NoBoxDetail = styled.div`
  margin-top: 10px;
  color: lightgray;
  font-size: 13px;
`;
const rotation = keyframes`
    0% {
      transform: rotate(0deg);
      opacity: 0.1;
    }
    25% {
      transform: rotate(90deg);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.1;
    }
    75% {
      transform: rotate(360deg);
      opacity: 1;
    }
  `;
const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoadingIcon = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotation} 1s infinite linear;
`;
const Shining = keyframes`
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.2;
    }
  `;
const WarningIcon = styled(GrStatusWarning)`
  width: 36px;
  height: 36px;
  animation: ${Shining} 2.5s infinite linear;
`;

const NoDataTitle = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
  font-size: 13px;
`;
const NoDataContainer = styled.div`
  width: 100%;
  height: 100%;
  /* height: 350px; */
  font-size: 22px;
  padding: 0px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Noto Sans KR", sans-serif;
  /* border: 1px solid red; */
`;
const NoBox = styled.div`
  /* margin-bottom: 10px; */
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 30px;
`;
const Image = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
`;
const Product = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: black;
  text-decoration: none;
`;
const Company = styled.div`
  font-size: 12px;
  color: gray;
  font-weight: bold;
  text-decoration: none;
`;
const PostBox = styled(Link)`
  text-decoration: none;
  padding: 0px 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  border: 1px solid #f2f2f2;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const AutoContainer = styled.div`
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  padding: 10px 10px;
  position: absolute;
  z-index: 10;
  border: 1px solid lightgray;
  width: 100%;
  height: 250px;
  box-sizing: border-box;
  background-color: white;
  /* border-top: none;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; */
  border-radius: 5px;
`;
