import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GiBeerBottle } from "react-icons/gi";
import { GiWineBottle } from "react-icons/gi";

function CheckUploadReview() {
  return (
    <Fragment>
      <CheckContainer>
        <CheckBox>
          <Fragment>
            <FirstStage>
              <Title>리뷰하실 술의 종류를 선택하세요</Title>
              <SelectContainer>
                <SelectBox to="UploadByManualMac">
                  <GiWineBottle size={64} style={{ color: "black" }} />
                  <SelectTitle>막걸리</SelectTitle>
                </SelectBox>
                <SelectBox to="UploadByManualYac">
                  <GiBeerBottle size={64} style={{ color: "black" }} />
                  <SelectTitle>약주</SelectTitle>
                </SelectBox>
              </SelectContainer>
            </FirstStage>
          </Fragment>
        </CheckBox>
      </CheckContainer>
    </Fragment>
  );
}

export default CheckUploadReview;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: black;
  @media (max-width: 640px) {
    font-size: 21px;
  }
`;
const FirstStage = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  padding: 0px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  /* border: 2px solid red; */
  box-sizing: border-box;
  @media (max-width: 640px) {
    padding: 0px 10px;
  }
`;
const SelectContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SelectTitle = styled.div`
  margin-top: 20px;
  font-size: 16;
  font-weight: 500;
  color: black;
  text-decoration: none;
`;
const SelectBox = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 150px;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  margin: 0px 25px;
  @media (max-width: 640px) {
    margin: 0px 10px;
  }
`;
const CheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 700px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
`;
const CheckContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px solid red; */
  box-sizing: border-box;
  padding: 0px 50px;
  @media (max-width: 768px) {
    padding: 0px 10px;
  }
  @media (max-width: 640px) {
    width: 100%;
    padding: 0px;
    margin-top: 60px;
  }
`;
