import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
/////////////////////////////////////////////////////////////////////////////////
import { Auth } from "@aws-amplify/auth";
import styled, { keyframes, css } from "styled-components";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
/////////////////////////////////////////////////////////////////////////////////
import KeyWordBox from "./Components/KeyWordBox";
import SearchBarHeader from "./Components/SearchBarHeader";
/////////////////////////////////////////////////////////////////////////////////
function Header({ UserName }) {
  let Admin = useSelector((state) => state.user.Admin);
  const [scroll, setscroll] = useState(0);
  setInterval(() => {
    setscroll(window.pageYOffset);
  }, 200);
  // const history = useHistory();
  const [MenuClick, setMenuClick] = useState(false);
  const [KeywordOpen, setKeywordOpen] = useState(false);
  const KeywordBoxOnOff = () => {
    setKeywordOpen(!KeywordOpen);
  };
  const MenuOnClick = () => {
    setMenuClick(!MenuClick);
  };
  const KeywordBoxOff = () => {
    setKeywordOpen(false);
  };
  return (
    <Fragment>
      <HeaderContainer control={KeywordOpen} scroll={scroll}>
        <HeaderBox>
          <TitleBox>
            <Title to="/" scroll={scroll}>
              내술노트
            </Title>
            <MenuLink to="/PostExplore">노트탐색</MenuLink>

            <MenuLink to="/Book" scroll={scroll}>
              리뷰도감
            </MenuLink>
            <MenuLink to="/FreeBoard">자유게시판</MenuLink>
            {Admin && (
              <MenuLink to="/AdminPage" scroll={scroll}>
                관리자 페이지
              </MenuLink>
            )}
          </TitleBox>

          <LinkContainer>
            <SearchBarHeader />
            {UserName && (
              <SignIn to="/Profile" scroll={scroll}>
                {UserName}님
              </SignIn>
            )}
            {!UserName && (
              <SignIn to="/Signin" scroll={scroll}>
                로그인
              </SignIn>
            )}
            {!UserName && (
              <SignIn to="/Signup" scroll={scroll}>
                회원가입
              </SignIn>
            )}
            {UserName && (
              <LogOut
                scroll={scroll}
                onClick={(e) => {
                  Auth.signOut();
                  // console.log("signed out!");
                  window.location.reload();
                }}
              >
                로그아웃
              </LogOut>
            )}
            <Menu onClick={MenuOnClick}>
              <MenuIcon scroll={scroll} />
            </Menu>
          </LinkContainer>
        </HeaderBox>

        {MenuClick && (
          <Fragment>
            <PageBG onClick={() => setMenuClick(false)}></PageBG>
            <MenuContainer>
              <ButtonArea onClick={MenuOnClick}>
                <MdClose size={38} />
              </ButtonArea>
              <PaddingContainer>
                <MenuTitle to="/">내술노트</MenuTitle>
                <MenuArea>
                  {UserName && (
                    <Fragment>
                      <SignInMenu to="/PostExplore" onClick={MenuOnClick}>
                        노트 탐색
                      </SignInMenu>
                      <SignInMenu to="/profile" onClick={MenuOnClick}>
                        마이페이지
                      </SignInMenu>
                      <SignInMenu to="/Profile" onClick={MenuOnClick}>
                        나의 노트
                      </SignInMenu>
                      <SignInMenu to="/FreeBoard" onClick={MenuOnClick}>
                        자유게시판
                      </SignInMenu>
                    </Fragment>
                  )}
                  {!UserName && (
                    <Fragment>
                      <SignInMenu to="/PostExplore" onClick={MenuOnClick}>
                        술 찾기
                      </SignInMenu>
                      <SignInMenu to="/Signin" onClick={MenuOnClick}>
                        마이페이지
                      </SignInMenu>
                      <SignInMenu to="/Signin" onClick={MenuOnClick}>
                        나의 노트
                      </SignInMenu>
                      <SignInMenu to="/FreeBoard" onClick={MenuOnClick}>
                        자유게시판
                      </SignInMenu>
                    </Fragment>
                  )}
                </MenuArea>
                <NoticeArea>
                  <NoticeMenu to="/Notice" onClick={MenuOnClick}>
                    공지사항
                  </NoticeMenu>
                  <NoticeMenu to="/Notice" onClick={MenuOnClick}>
                    친구초대
                  </NoticeMenu>
                  <NoticeMenu to="/Notice" onClick={MenuOnClick}>
                    자주 묻는 질문
                  </NoticeMenu>
                  <NoticeMenu to="/Notice" onClick={MenuOnClick}>
                    이용약관
                  </NoticeMenu>
                  <NoticeMenu to="/Notice" onClick={MenuOnClick}>
                    개인정보취급방침
                  </NoticeMenu>
                </NoticeArea>
                <TutorialBox>
                  <TutorialTitle>혹시 처음 방문하셨나요?</TutorialTitle>
                  <TutorialLink>서비스 안내</TutorialLink>
                </TutorialBox>
              </PaddingContainer>
              <MenuFooter>
                {!UserName && (
                  <FooterMenu to="/Signin" onClick={MenuOnClick}>
                    로그인
                  </FooterMenu>
                )}
                {!UserName && (
                  <div style={{ fontSize: "20px", color: "white" }}>·</div>
                )}
                {!UserName && (
                  <FooterMenu to="/Signup" onClick={MenuOnClick}>
                    회원가입
                  </FooterMenu>
                )}
                {UserName && (
                  <LogOutMenu
                    onClick={(e) => {
                      Auth.signOut();
                      // console.log("signed out!");
                      window.location.reload();
                      setMenuClick(!MenuClick);
                    }}
                  >
                    로그아웃
                  </LogOutMenu>
                )}
              </MenuFooter>
            </MenuContainer>
          </Fragment>
        )}
      </HeaderContainer>
      {KeywordOpen && <KeyWordBox KeywordBoxOnOff={KeywordBoxOnOff} />}
    </Fragment>
  );
}

export default Header;
const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MenuLink = styled(Link)`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 450;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 5px; */
  color: black;
  text-decoration: none;
  margin-right: 30px;
  /* &:hover {
    color: gray;
  } */
  @media (max-width: 640px) {
    display: none;
  }
`;
const MenuIcon = styled(FiMenu)`
  width: 36px;
  height: 36px;

  color: black;
`;
const MenuTitle = styled(Link)`
  width: 220px;
  font-size: 24px;
  /* font-weight: bold; */
  text-decoration: none;
  text-align: center;
  color: black;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: bold;
  @media (max-width: 640px) {
    width: 220px;
    font-size: 20px;
  }
`;
const TutorialLink = styled(Link)`
  color: black;
  font-weight: bold;
  text-decoration: none;
  font-size: 15px;
  &:hover {
    color: gray;
  }
`;
const TutorialTitle = styled.div`
  color: #777777;
  font-size: 15px;
`;
const TutorialBox = styled.div`
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* align-items: center; */
  margin-top: 25px;
  width: 250px;
  height: 80px;
  background-color: white;
  box-sizing: border-box;
  margin-bottom: 100px;
  @media (max-width: 640px) {
    margin-top: 30px;
  }
`;
const NoticeArea = styled.div`
  padding: 20px 15px;
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  /* align-items: center; */
`;
const NoticeMenu = styled(Link)`
  font-size: 14px;
  text-decoration: none;
  color: #777777;
  &:hover {
    color: gray;
  }
`;

const PaddingContainer = styled.div`
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const FooterMenu = styled(Link)`
  /* margin: 5px 0px; */
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  &:hover {
    color: gray;
  }
`;
const MenuFooter = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  justify-content: space-around;
  align-items: center;
  padding: 0px 50px;
  width: 350px;
  height: 80px;
  box-sizing: border-box;
  background-color: #333333;
  @media (max-width: 640px) {
    width: 300px;
  }
`;

const SearchBarContainer = styled.div`
  /* display: flex;
  align-items: flex-start; */
  /* position: relative;
  top: 100; */
  @media (max-width: 850px) {
    display: none;
  }
`;
const MenuMove = keyframes`
from{
  transform:translateX(500px);
  opacity:0;
}
to {
  transform:translateX(0px);
  opacity:1;
}
`;
const BgChange = keyframes`
0%{
  opacity:1;
}
100% {
  opacity:0.7;
}
`;
const PageBG = styled.div`
  position: fixed;
  top: 0;
  z-index: 102;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  animation: ${BgChange} 0.3s backwards;
`;
const SignInMenu = styled(Link)`
  /* margin: 5px 0px; */
  font-size: 18px;
  font-weight: 350;
  text-decoration: none;
  color: #333333;
  &:hover {
    color: gray;
  }
`;
const LogOutMenu = styled.button`
  font-weight: bold;
  color: white;
  margin: 5px 0px;
  padding: 0px;
  text-align: left;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    color: gray;
  }
`;
const MenuArea = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 1px solid #333333;
  border-left: none;
  border-right: none;
  box-sizing: border-box;
  width: 100%;
  height: 200px;
  padding: 15px 15px;
  @media (max-width: 640px) {
    height: 150px;
  }
`;
const ButtonArea = styled.div`
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: lightgray;
  &:hover {
    color: gray;
  }
`;
const MenuContainer = styled.div`
  /* padding: 20px 20px; */
  position: fixed;
  right: 0;
  top: 0;
  width: 350px;
  height: 100%;
  z-index: 9999;
  /* border: 2px solid black; */
  box-sizing: border-box;
  background-color: #f8f8f8;
  /* font-family: "RIDIBatang", sans-serif; */
  font-family: "Noto Sans KR", sans-serif;
  overflow: scroll;
  animation: ${MenuMove} 0.7s backwards;
  @media (max-width: 640px) {
    width: 300px;
  }
`;

const LogOut = styled.button`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR", sans-serif;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  border: none;
  background-color: transparent;
  font-size: 16px;

  color: black;
  text-decoration: none;
  margin-right: 30px;
  &:hover {
    color: gray;
  }
  @media (max-width: 640px) {
    display: none;
  }
`;

const SignIn = styled(Link)`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: "Noto Sans KR", sans-serif;

  font-weight: bold;
  color: black;
  text-decoration: none;
  margin-right: 30px;
  /* &:hover {
    color: gray;
  } */
  @media (max-width: 640px) {
    display: none;
  }
`;
const LinkContainer = styled.div`
  /* width: 230px; */
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 640px) {
    width: 36px;
  }
`;

const Menu = styled.div`
  cursor: pointer;
`;

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1080px;
  height: 100%;
  /* padding: 0px 20px; */
  box-sizing: border-box;
`;
const Title = styled(Link)`
  margin-right: 80px;
  font-size: 26px;
  /* font-weight: bold; */
  text-decoration: none;

  color: black;
  /* font-family: "RIDIBatang", sans-serif; */
  font-family: "Noto Sans KR", sans-serif;
  font-weight: bold;
  @media (max-width: 640px) {
    width: 220px;
    font-size: 20px;
    margin: 0px;
  }
`;
const MenuAnimation = keyframes`
from{
  opacity:0;
}
to {
  opacity:1;
}
`;
const HeaderContainer = styled.div`
  /* position: ${(props) => (props.scroll > 80 ? "fixed" : "absolute")}; */
  position: fixed;
  display: flex;
  top: 0;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;
  border-bottom: 1px solid lightgray;
  box-sizing: border-box;
  /* animation: ${(props) =>
    props.scroll > 80
      ? css`
          ${MenuAnimation} 0.5s backwards
        `
      : ""}; */
  background-color: white;
  @media (max-width: 640px) {
    height: 60px;
    padding: 0px 10px;
  }
  /* height: 60px; */
`;
