import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import React, { Suspense, Fragment, lazy, useEffect, useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { AiOutlineLoading } from "react-icons/ai";
///////////////////////////////////////////////////////////////////
import styled, { keyframes } from "styled-components";
///////////////////////////////////////////////////////////////////
import Header from "./Views/Header";
import Footer from "./Views/Footer";
import FloatingItems from "./Views/Components/FloatingItems";
import Routes from "./Routes";

Amplify.configure(awsExports);

///////////////////////////////////////////////////////////////////
const Index = () => {
  let UserName = useSelector((state) => state.user.Data);
  const [Location, setLocation] = useState("");
  useEffect(() => {
    setLocation(window.location.pathname);
  }, []);
  setInterval(() => {
    setLocation(window.location.pathname);
  }, 200);
  const Id = Location;
  // console.log("location" + Id);
  return (
    <Suspense
      fallback={
        <Fragment>
          <LoadingContainer>
            <Loading>
              <AiOutlineLoading size={32} />
            </Loading>
          </LoadingContainer>
        </Fragment>
      }
    >
      <BrowserRouter>
        {Location == "/" ? null : <Header UserName={UserName} />}

        <Switch>
          <Routes UserInfo={UserName} LoginStatus={UserName} />
        </Switch>
        <Footer />

        <FloatingItems />
      </BrowserRouter>
    </Suspense>
  );
};

export default Index;

const rotation = keyframes`
    0% {
      transform: rotate(0deg);
      opacity: 0.1;
    }
    25% {
      transform: rotate(90deg);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.1;
    }
    75% {
      transform: rotate(360deg);
      opacity: 1;
    }
  `;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Loading = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotation} 1s infinite linear;
`;
