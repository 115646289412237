import { Auth } from "@aws-amplify/auth";

const signUp = async ({
  username,
  name,
  password,
  email,
  gender,
  age,
  groupName,
}) => {
  try {
    const signUpRes = await Auth.signUp({
      username,
      password,
      attributes: {
        name: name,
        email: email,
        "custom:age": age,
        gender: gender,
      },
      validationData: [],
      clientMetadata: {
        GroupName: groupName,
      }, 
    });
    return {
      status: 200,
      data: signUpRes,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};
const signOut = async () => {
  try {
    const signOuttedObj = await Auth.signOut();
    return {
      status: 200,
      data: signOuttedObj,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};

const confirmSignUp = async ({ name, confirmCode }) => {
  try {
    const confirmSignUpRes = await Auth.confirmSignUp(name, confirmCode, {
      forceAliasCreation: true,
      // clinetMetadata: {
      //   GroupName: userlevel,
      // },
    });
    return {
      status: 200,
      data: confirmSignUpRes,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};

const signIn = async ({ userId, userPw }) => {
  try {
    const user = await Auth.signIn(userId, userPw);
    return {
      status: 200,
      data: user,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};

const retrieveCurrentAuthenticatedUser = async (props) => {
  try {
    // https://aws-amplify.github.io/docs/js/authentication#retrieve-current-authenticated-user
    // https://github.com/aws-amplify/amplify-js/issues/2534
    const { bypassCache } = props || false;
    const authUserObj = await Auth.currentAuthenticatedUser({
      bypassCache, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    return {
      status: 200,
      data: authUserObj,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};
const retrieveCurrentSession = async () => {
  try {
    // https://aws-amplify.github.io/docs/js/authentication#retrieve-current-session
    const sessionObj = await Auth.currentSession();
    const idToken = sessionObj && sessionObj.getIdToken();
    const jwtToken = idToken && idToken.getJwtToken();
    return {
      status: 200,
      data: sessionObj,
      authToken: jwtToken,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};

const forgotPw = async ({ username }) => {
  try {
    const forgotPwRes = await Auth.forgotPassword(username);
    return {
      status: 200,
      data: forgotPwRes,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};
const confirmForgotPw = async ({ username, code, newPw, clientMetadata }) => {
  //https://aws-amplify.github.io/docs/js/authentication#forgot-password
  try {
    const forgotPwSubmitRes = await Auth.forgotPasswordSubmit(
      username,
      code,
      newPw,
      clientMetadata
    );
    return {
      status: 200,
      data: forgotPwSubmitRes,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};

export {
  confirmSignUp,
  signIn,
  signUp,
  signOut,
  retrieveCurrentAuthenticatedUser,
  retrieveCurrentSession,
  forgotPw,
  confirmForgotPw,
};
