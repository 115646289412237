import React, { Fragment, useState } from "react";
import styled, { keyframes } from "styled-components";
import { AiOutlineUser } from "react-icons/ai";
import { Review } from "../PostUtil/PostDummy";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import LikeLogic from "./LikeLogic";
import { API } from "@aws-amplify/api";
import LevelCheck from "../../Level/LevelCheck";
import { useSelector } from "react-redux";
import { GiFemale } from "react-icons/gi";
import { GiMale } from "react-icons/gi";
import { BsThreeDotsVertical } from "react-icons/bs";

function ReplyComment({
  CommentsList,
  PostId,
  UserInfo,
  CommentId,
  ReviewedData,
  UserGender
}) {
  const [EditText, setEditText] = useState("");
  const [EditOpen, setEditOpen] = useState(false);
  const [EditTarget, setEditTarget] = useState("");

  /////////////////////////////
  const [DotOpen, setDotOpen] = useState(false);
  const [DotTarget, setDotTarget] = useState("");
  const [ReviewOpen, setReviewOpen] = useState(false);
  const [ReviewTarget, setReviewTarget] = useState("");
  const [ReviewUserTarget, setReviewUserTarget] = useState("");
  const UserEmail = useSelector((state) => state.user.Email);
  const DotOpenControl = (e) => {
    setDotTarget(e.target.value);
    setDotOpen(!DotOpen);
  };
  const EditOpenControl = async (e) => {
    // console.log(e.target.value);
    // console.log(e.target.value);
    setEditTarget(e.target.value);
    setEditOpen(!EditOpen);
  };
  const OpenReviewAction = async (e) => {
    setReviewOpen(!ReviewOpen);
    setReviewTarget(e.target.value.split(",")[0]);
    setReviewUserTarget(e.target.value.split(",")[1]);
    // console.log(ReviewTarget);
  };
  const DeleteAction = async (e) => {
    let id = e.target.value;
    // console.log(id);
    const ApiResponse = await API.del("CommentApi", "/items/" + id);
    // console.log(ApiResponse);
    window.location.reload();
  };
  const EditAction = async (e) => {
    let id = Number(e.target.value);
    let date = new Date();
    let UploadTime =
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + date.getDate()).slice(-2) +
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2);
    // console.log(id);
    const ApiResponse = await API.post("CommentApi", "/items/", {
      body: {
        id: id,
        text: EditText,
        replyId: CommentId,
        user: UserInfo,
        postId: PostId,
        time: UploadTime,
        email: UserEmail,
        Edited: "수정됨",
        age: Number(UserAge),
        gender: UserGender,
      },
    });
    // console.log(ApiResponse);
    window.location.reload();
  };
  const RanderReview = ReviewedData.map((data) => {
    if (data.postId == PostId) {
      if (ReviewUserTarget === data.user) {
        return (
          <Fragment>
            <ValueContainer>
              <ReviewTitle>{data.user}님의 평가</ReviewTitle>
            </ValueContainer>
            <ValueContainer>
              <TasteTitle>당도</TasteTitle>
              <ValueBar>
                <Value value={data.acidClicked} />
              </ValueBar>
            </ValueContainer>
            <ValueContainer>
              <TasteTitle>산도</TasteTitle>
              <ValueBar>
                <Value value={data.alcoholClicked} />
              </ValueBar>
            </ValueContainer>
            <ValueContainer>
              <TasteTitle>쓴맛</TasteTitle>
              <ValueBar>
                <Value value={data.carbonatedClicked} />
              </ValueBar>
            </ValueContainer>
            <ValueContainer>
              <TasteTitle>탄산감</TasteTitle>
              <ValueBar>
                <Value value={data.sweetClicked} />
              </ValueBar>
            </ValueContainer>
            <ValueContainer>
              <TasteTitle>무게감</TasteTitle>
              <ValueBar>
                <Value value={data.weightClicked} />
              </ValueBar>
            </ValueContainer>
          </Fragment>
        );
      }
    }
  });
  const RanderComments = CommentsList.map((data) => {
    if (PostId === data.postId) {
      /// 백엔드에서 CommentsList 받아오고,
      if (CommentId === data.replyId) {
        const ReviewedTag = ReviewedData.map((reviewData) => {
          if (reviewData.email === data.email && reviewData.postId == PostId) {
            return (
              <ReviewCheck
                value={data.id + "," + reviewData.user}
                // onClick={OpenReviewAction}
              >
                평가함
              </ReviewCheck>
            );
          }
          return null;
        });

        return (
          <MyReplyContainer>
            <MyReply>
              <CommentBox>
                <IconContainer>
                  <UserData>
                    <UserInfomation>
                      <MyIcon>
                        <AiOutlineUser size={32} />
                      </MyIcon>
                      {/*<LevelCheck User={data.user} />*/}
                      <User>{data.user}</User>

                      {data.gender === "남" && (
                        <GiMale size={16} style={{ color: "#6098FF" }} />
                      )}
                      {data.gender === "여" && (
                        <GiFemale size={16} style={{ color: "#FF7DB1" }} />
                      )}
                      <Dot>•</Dot>

                      {data.age < 60 && <UserAgeText>{data.age}대</UserAgeText>}
                      {data.age >= 60 && <UserAgeText>60대 이상</UserAgeText>}
                      {/*  나이표시, 일단 Disable*/}
                      {ReviewedTag}
                    </UserInfomation>
                    {DotOpen && DotTarget == data.id && data.user === UserInfo && (
                      <IconBox>
                        <EditIcon onClick={EditOpenControl} value={data.id}>
                          <AiEdit size={20} />
                        </EditIcon>
                        <DeleteIcon onClick={DeleteAction} value={data.id}>
                          <DeIcon size={20} />
                        </DeleteIcon>
                      </IconBox>
                    )}
                    {data.user === UserInfo && (
                      <DotsFuncWrapper onClick={DotOpenControl} value={data.id}>
                        <ThreeDots />
                      </DotsFuncWrapper>
                    )}
                  </UserData>
                  {/* 
                  <TimeValue>
                    {data.time} {data.Edited}
                  </TimeValue>
                  */}
                </IconContainer>
                {ReviewOpen && ReviewTarget == data.id && (
                  <ReviewContainer>{RanderReview}</ReviewContainer>
                )}
                <TextData>
                  <Fragment>
                    <ValueBox>
                      <ValueWrapper>
                        <Text>{data.text}</Text>
                      </ValueWrapper>
                    </ValueBox>
                    <LikeLogicWrapper>
                      <LikeLogic
                        PostId={PostId}
                        UserInfo={UserInfo}
                        CommentId={data.id}
                      />
                      <TimeValue>
                        {data.time} {data.Edited}
                      </TimeValue>
                    </LikeLogicWrapper>
                    {EditOpen && EditTarget == data.id && (
                      <Fragment>
                        <InputWrapper>
                          <Input
                            placeholder={data.text}
                            onChange={(e) => {
                              setEditText(e.target.value);
                            }}
                            value={EditText}
                          ></Input>
                          <ButtonBox>
                            <Button onClick={() => setEditOpen(false)}>
                              취소
                            </Button>
                            <Button onClick={EditAction} value={data.id}>
                              수정
                            </Button>
                          </ButtonBox>
                        </InputWrapper>
                      </Fragment>
                    )}
                  </Fragment>
                </TextData>
              </CommentBox>
            </MyReply>
          </MyReplyContainer>
        );
      }
    }
  });

  return (
    <Fragment>
      <SingleContainer>{RanderComments}</SingleContainer>
    </Fragment>
  );
}

export default ReplyComment;
const UserAgeText = styled.div`
  font-size: 14px;
`;
const DotsFuncWrapper = styled.button`
  position: relative;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;
const UserInfomation = styled.div`
  display: flex;
  align-items: center;
  color: #666775;
`;
const ThreeDots = styled(BsThreeDotsVertical)`
  pointer-events: none;
  width: 18px;
  height: 18px;
  color: lightgray;
  background-color: transparent;
`;
const LikeLogicWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const Dot = styled.div`
  margin: 0px 5px;
`;
const UserAge = styled.div`
  font-size: 14px;
`;
const Move = (v) => keyframes`
from{
    width:0%
}
to {
    width:${v}%
}
`;
const ReviewContainer = styled.div`
  position: absolute;
  z-index: 10;
  margin-top: 20px;
  width: 50%;
  min-height: 300px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  padding: 10px;
  box-sizing: border-box;
`;
const ValueWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
const IconBox = styled.div`
  position: absolute;
  z-index: 5;
  top: 7px;
  right: 30px;
  display: flex;
  @media (max-width: 640px) {
    top: 35px;
    right: 5px;
  }
`;
const InputWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;
const ButtonBox = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;
const Button = styled.button`
  margin: 10px 0px 10px 10px;
  cursor: pointer;
  outline: none;
  width: 90px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ededed;
  color: gray;
  padding-bottom: 0px;
  padding-top: 0px;
  font-size: 16px;
  font-family: "Noto Sans KR", sans-serif;
  &:hover {
    opacity: 0.8;
  }
`;
const AiEdit = styled(AiOutlineEdit)`
  pointer-events: none;
`;

const Input = styled.textarea`
  resize: none;
  margin-top: 20px;
  min-height: 40px;
  width: 100%;
  height: 100px;
  outline: none;
  word-break: break-all;
  border-radius: 4px;
  border: 1px solid #ededed;
  padding: 8px;
  font-size: 16px;
  box-sizing: border-box;
  &::placeholder {
    color: lightgray;
    font-size: 16px;
    font-family: "Noto Sans KR", sans-serif;
  }
  &:hover {
    &::placeholder {
      color: gray;
    }
  }
`;
const ValueBox = styled.div`
  margin-top: 20px;
  /* padding-left: 10px; */
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
`;
const TimeValue = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: gray;
`;
const DeIcon = styled(AiOutlineDelete)`
  pointer-events: none;
`;
const TextData = styled.div`
  padding-left: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const EditIcon = styled.button`
  border: none;
  color: gray;
  background-color: transparent;
  margin-left: 20px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const DeleteIcon = styled.button`
  border: none;
  color: gray;
  background-color: transparent;
  padding: 0px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const UserData = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666775;
`;
const ReviewCheck = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans KR", sans-serif;
  outline: none;
  /* cursor: pointer; */
  border: 1px solid #ddd;
  height: 25px;
  margin-left: 10px;
  font-size: 12px;
  padding: 1px 10px;
  color: gray;
  color: #666;
  /* width: 65px; */
  font-weight: 600;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: white;
`;

const Hr = styled.div`
  border-bottom: 1px solid #f0f0f0;
`;
const CommentBox = styled.div`
  /* margin-left: 20px; */
  width: 100%;
`;
const User = styled.div`
  margin-right: 15px;
  margin-left: 15px;
  font-size: 16px;
  font-weight: bold;
  color: black;
`;
const Text = styled.div`
  font-size: 16px;
  color: #666775;
  display: flex;
  align-items: center;
`;
const SingleContainer = styled.div`
  /* box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18); */

  box-sizing: border-box;
  /* margin-top: 20px; */
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const MyIcon = styled.div`
  background-color: lightgray;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;
const MyReply = styled.div`
  /* padding: 15px 15px 0px 15px; */
  box-sizing: border-box;
  display: flex;
  @media (max-width: 640px) {
    margin-left: 20px;
  }
`;
const MyReplyContainer = styled.div`
  border-top: 1px solid #f2f2f2;
  /* margin: 10px 15px 10px 60px; */
  padding: 15px 15px 10px 60px;
  background-color: #fcfcfc;
  /* border-radius: 5px; */
  @media (max-width: 640px) {
    padding: 15px 15px 10px 15px;
    margin: 10px 0px 10px 10px;
  }
`;
const Value = styled.div`
  width: ${(props) => props.value}%;
  height: 100%;
  background-image: linear-gradient(45deg, rgb(228, 29, 92), rgb(146, 23, 77));
  border-radius: 20px;
  animation: ${(props) => Move(props.value)} 1.5s backwards;
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ValueBar = styled.div`
  width: 100%;
  height: 15px;
  border: 2px solid lightgray;
  border-radius: 20px;
  @media (max-width: 640px) {
    height: 12px;
  }
`;
const TasteTitle = styled.div`
  margin: 15px 0px;
  font-size: 16px;
  width: 100px;
  @media (max-width: 640px) {
    font-size: 13px;
  }
`;
const ReviewTitle = styled.div`
  margin: 15px 0px;
  font-size: 16px;
  width: 100%;
  @media (max-width: 640px) {
    font-size: 13px;
  }
`;
