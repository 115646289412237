import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { AiOutlinePicture } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import { MdArrowBack } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import CheckUploadReview from "./UploadUtil/CheckUploadReview";
function PostExploreBySearch({ UserInfo, LoginStatus }) {
  const [stage, setStage] = useState("");
  const [select, setSelect] = useState("");
  const history = useHistory();
  console.log(UserInfo);
  return (
    <Fragment>
      <UploadWrapper>
        {stage !== "manual" && (
          <UploadContainer>
            <UploadBox>
              {stage === "" && (
                <FirstStage>
                  <Title>
                    리뷰하실 술의 이름을 검색하시거나 사진으로 검색해보세요.
                  </Title>
                  <SelectContainer>
                    {select === "" && (
                      <Fragment>
                        <SelectBox onClick={() => setSelect("text")}>
                          <SearchAiIcon />
                          <SelectTitle>이름으로 검색</SelectTitle>
                        </SelectBox>
                        <SelectBox onClick={() => setSelect("picture")}>
                          <PictureAiIcon />
                          <SelectTitle>사진으로 검색</SelectTitle>
                        </SelectBox>
                      </Fragment>
                    )}
                    {select === "text" && (
                      <Fragment>
                        <SearchContainer>
                          <SearchBarContainer>
                            <SearchBox placeholder="술을 입력하세요." />
                            <SearchIcon>
                              <BsSearch size={24} />
                            </SearchIcon>
                          </SearchBarContainer>
                          <ManualSearch>
                            <AiOutlineQuestionCircle size={24} />
                            <ManualTitleBox>
                              <ManualTitle>검색결과가 없다면</ManualTitle>
                              <ManualLink onClick={() => setStage("manual")}>
                                &nbsp;노트 작성하기
                              </ManualLink>
                            </ManualTitleBox>
                          </ManualSearch>
                          <BackIcon onClick={() => setSelect("")}>
                            <MdArrowBack size={28} />
                            &nbsp;뒤로 가기
                          </BackIcon>
                        </SearchContainer>
                      </Fragment>
                    )}
                    {select === "picture" && (
                      <Fragment>
                        <SearchContainer>
                          <PictureContainer
                            onClick={() => alert("사진 업로드")}
                          >
                            <AiOutlinePlus size={52} />
                          </PictureContainer>
                          <ManualTitleBox>
                            <ManualTitle>검색결과가 없다면</ManualTitle>
                            <ManualLink onClick={() => setStage("manual")}>
                              &nbsp;노트 작성하기
                            </ManualLink>
                          </ManualTitleBox>
                          <BackIcon onClick={() => setSelect("")}>
                            <MdArrowBack size={28} />
                            &nbsp;뒤로 가기
                          </BackIcon>
                        </SearchContainer>
                      </Fragment>
                    )}
                  </SelectContainer>
                </FirstStage>
              )}
            </UploadBox>
          </UploadContainer>
        )}
        {stage === "manual" && <CheckUploadReview />}
      </UploadWrapper>
    </Fragment>
  );
}

export default PostExploreBySearch;
const SearchAiIcon = styled(AiOutlineSearch)`
  width: 64px;
  height: 64px;
`;
const PictureAiIcon = styled(AiOutlinePicture)`
  width: 64px;
  height: 64px;
`;
const PictureContainer = styled.div`
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  background-color: lightgray;
  &:hover {
    opacity: 0.8;
  }
`;
const BackIcon = styled.div`
  cursor: pointer;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: black;
`;
const ManualTitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ManualLink = styled.div`
  cursor: pointer;
  font-size: 16px;
  color: #545454;
  &:hover {
    color: gray;
  }
`;
const ManualTitle = styled.div`
  font-size: 16px;
  color: black;
  margin-left: 10px;
`;
const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ManualSearch = styled.div`
  margin-top: 30px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
`;
const SearchIcon = styled.div`
  cursor: pointer;
`;

const SearchBox = styled.input`
  outline: none;
  border: none;
  box-sizing: border-box;
  /* border-radius: 30px; */
  width: 100%;
  height: 100%;
  /* padding: 0px 20px; */
  font-size: 22px;
  font-family: "Noto Sans KR", sans-serif;
  &::placeholder {
    font-size: 20px;
    @media (max-width: 850px) {
      font-size: 18px;
    }
  }
`;
const SearchBarContainer = styled.form`
  border: 2px solid gray;
  border-right: none;
  border-left: none;
  border-top: none;
  /* border-radius: 30px; */
  padding: 0px 10px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 45px;
  box-sizing: border-box;
`;
const SelectTitle = styled.div`
  font-weight: 500;
  margin-top: 20px;
  font-size: 16;
  color: black;
`;
const SelectBox = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 150px;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  margin: 0px 25px;
  @media (max-width: 640px) {
    margin: 0px 10px;
  }
`;
const SelectContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: black;
  @media (max-width: 640px) {
    font-size: 21px;
  }
`;
const FirstStage = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  padding: 0px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  /* border: 2px solid red; */
  box-sizing: border-box;
  @media (max-width: 640px) {
    padding: 0px 10px;
  }
`;
////
const UploadBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 700px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 10px 50px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px 10px;
    box-shadow: none;
  }
  @media (max-width: 640px) {
    box-shadow: none;
    padding: 10px 10px;
    min-height: 400px;
  }
`;
const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px solid red; */
  box-sizing: border-box;
  padding: 0px 50px;
  @media (max-width: 768px) {
    padding: 0px 10px;
  }
  @media (max-width: 640px) {
    width: 100%;
    padding: 0px;
    margin-top: 60px;
  }
`;
const UploadWrapper = styled.div`
  /* @media (max-width: 640px) {
    width: 100%;
  } */
`;
