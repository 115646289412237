import React, { Fragment } from "react";
import styled from "styled-components";
function Evaluate({
  BalanceClicked,
  setBalanceClicked,
  ComplexityClicked,
  setComplexityClicked,
}) {
  return (
    <Fragment>
      <BarContainer>
        <TasteBox>
          <MainTitle>밸런스</MainTitle>
          <CheckContainer>
            <ArchBar>
              <Choose
                value={25}
                balance={BalanceClicked}
                onClick={() => setBalanceClicked(25)}
              >
                없음
              </Choose>
              <Choose
                value={50}
                balance={BalanceClicked}
                onClick={() => setBalanceClicked(50)}
              >
                적음
              </Choose>
              <Choose
                value={75}
                balance={BalanceClicked}
                onClick={() => setBalanceClicked(75)}
              >
                보통
              </Choose>
              <Choose
                value={100}
                balance={BalanceClicked}
                onClick={() => setBalanceClicked(100)}
              >
                많음
              </Choose>
            </ArchBar>
            <MixDetail>
              {BalanceClicked == 25 && (
                <DetailText>거슬림이 느껴집니다.</DetailText>
              )}
              {BalanceClicked == 50 && (
                <DetailText>술이 거슬리지 않습니다.</DetailText>
              )}
              {BalanceClicked == 75 && (
                <DetailText>술이 모난곳이 없습니다.</DetailText>
              )}
              {BalanceClicked == 100 && (
                <DetailText>술이 완벽하다고 느껴집니다.</DetailText>
              )}
            </MixDetail>
          </CheckContainer>
        </TasteBox>
        {/*///////////////////////////////////////////////////*/}
        <TasteBox>
          <MainTitle>복합성</MainTitle>
          <CheckContainer>
            <ArchBar>
              <ChooseAlcohol
                value={33}
                complexity={ComplexityClicked}
                onClick={() => setComplexityClicked(33)}
              >
                없음
              </ChooseAlcohol>
              <ChooseAlcohol
                value={66}
                complexity={ComplexityClicked}
                onClick={() => setComplexityClicked(66)}
              >
                보통
              </ChooseAlcohol>
              <ChooseAlcohol
                value={100}
                complexity={ComplexityClicked}
                onClick={() => setComplexityClicked(100)}
              >
                많음
              </ChooseAlcohol>
            </ArchBar>
            <MixDetail>
              {ComplexityClicked == 33 && (
                <DetailText>술의 느낌이 심플합니다.</DetailText>
              )}
              {ComplexityClicked == 66 && (
                <DetailText>술의 느낌이 무난, 적당합니다.</DetailText>
              )}
              {ComplexityClicked == 100 && (
                <DetailText>술이 뛰어나다고 느껴집니다.</DetailText>
              )}
            </MixDetail>
          </CheckContainer>
        </TasteBox>
      </BarContainer>
    </Fragment>
  );
}

export default Evaluate;
const MixDetail = styled.div`
  margin-top: 30px;
`;
const DetailText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #4d75f2;
`;
const CheckContainer = styled.div`
  margin-top: 40px;
  width: 100%;
`;
const MainTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;
const TasteBox = styled.div`
  width: 100%;
  margin: 30px 0px;
`;
const ChooseAlcohol = styled.div`
  @media (max-width: 640px) {
    width: 55px;
    height: 55px;
    font-size: 14px;
  }
  cursor: pointer;
  width: 80px;
  height: 80px;
  /* border: 2px solid #f5f6f8; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 50%;
  color: ${(props) => (props.complexity === props.value ? "white" : "#b5bbc7")};
  background-color: ${(props) =>
    props.complexity === props.value ? "#4d75f2" : "#f5f6f8"};
`;
const Choose = styled.div`
  @media (max-width: 640px) {
    width: 55px;
    height: 55px;
    font-size: 14px;
  }
  cursor: pointer;
  width: 80px;
  height: 80px;
  /* border: 2px solid #f5f6f8; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 50%;
  color: ${(props) => (props.balance === props.value ? "white" : "#b5bbc7")};
  background-color: ${(props) =>
    props.balance === props.value ? "#4d75f2" : "#f5f6f8"};
`;
const ArchBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BarContainer = styled.div`
  @media (max-width: 640px) {
    padding: 0px 10px;
  }
  padding: 0px 95px;
  box-sizing: border-box;
  margin: 30px 0px;
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
