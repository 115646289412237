import React, { Fragment } from "react";
import styled, { keyframes } from "styled-components";
function PostCircleInfo() {
  return (
    <Fragment>
      <InfoContainer></InfoContainer>
    </Fragment>
  );
}

export default PostCircleInfo;
const InfoContainer = styled.div`
  width: 100%;
  min-height: 300px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  padding: 10px 20px;
  box-sizing: border-box;
  margin-top: 45px;
`;

const CirCleBox = styled.div`
  cursor: pointer;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  transition: 0.2s cubic-bezier(0.74, 1.13, 0.83, 1.2);
  &:hover {
    transform: scale(1.2);
    transform-origin: center center;
  }
  background-color: #ffeec3;
  clip-path: polygon(50% 50%, 50% 50%, 100% 50%, 100% 0);
  @media (max-width: 640px) {
    &:hover {
      transform: scale(1);
    }
  }
`;
