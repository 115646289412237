import * as Yup from "yup";

import { Button, Chip, Grid, TextField, Typography } from "@material-ui/core";

import { labelName, labelPhone } from "../labels";
import React, { useState } from "react";
import { API } from "@aws-amplify/api";
import { Loader } from "../components";
import PropTypes from "prop-types";
import { getDashedValue } from "../utils";
import styled from "styled-components";
import theme from "../theme";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

// form[0]: step1, form[1]: step2
const form = [
  {
    initialValues: {
      name: "",
      phone: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(`${labelName}은 필수 입력 항목입니다.`),
      phone: Yup.string()
        .min(12, `올바르지 않은 ${labelPhone}입니다.`)
        .required(`${labelPhone}은 필수 입력 항목입니다.`),
    }),
    onSubmit: () => {},
  },
];

const Form = styled.form`
  width: "100%";
  margin-top: ${theme.spacing(1)};
`;
const ButtonLoaderWrapper = styled.div`
  postition: relative;
`;
const BoldButton = styled(Button)`
  font-weight: bold;
`;
const ButtonGrid = styled(Grid)`
  margin: ${theme.spacing(2, 0, 1)};
`;
const AlertMessage = styled(Chip)`
  width: 100%;
`;

const FindEmail = (props) => {
  const { onCancel } = props;
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setUserEmail] = useState(false);
  const [noUser, setNoUser] = useState(false);
  const formik = useFormik(form[0]);
  const history = useHistory();

  const onChangePhone = (e) => {
    let typedValue = e.target.value;
    let { dashedValue } = getDashedValue({ value: typedValue });
    formik.setFieldValue("phone", dashedValue, false);
  };

  const onFindEmail = async () => {
    setNoUser(false);
    setIsLoading(true);
    // console.log('onFindEmail', formik);
    formik.submitForm();
    if (!formik.isValid) {
      setIsLoading(false);
      return;
    }
    let apiName = "em24public";
    let path = "/public";
    let myInit = {
      queryStringParameters: {
        name: formik.values.name,
        phone: formik.values.phone,
      },
    };
    try {
      const resEmail = await API.get(apiName, path, myInit);
      // console.log({ resEmail });
      if (resEmail.username) {
        setUserEmail(resEmail.username);
        setStep(1);
      } else {
        setNoUser(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };
  if (step === 1) {
    const toAdmin = (e) => {
      history.push("/");
    };
    return (
      <>
        <Typography component="h3" variant="subtitle1" gutterBottom>
          회원님의 이메일 주소는
        </Typography>
        <Chip color="primary" label={userEmail || "email@email.com"} />
        <Typography component="h3" variant="subtitle1">
          입니다.
        </Typography>
        <ButtonGrid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <BoldButton
            color="primary"
            size="large"
            onClick={toAdmin}
          >{`로그인 페이지로`}</BoldButton>
        </ButtonGrid>
      </>
    );
  }
  return (
    <Form noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="name"
        label={labelName}
        type="text"
        id="name"
        autoComplete="username"
        error={formik.touched.name && !!formik.errors.name}
        helperText={formik.touched.name && formik.errors.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        autoFocus
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="phone"
        label={labelPhone}
        type="phone"
        id="phone"
        autoComplete="phone"
        error={formik.touched.phone && !!formik.errors.phone}
        helperText={formik.touched.phone && formik.errors.phone}
        onChange={onChangePhone}
        onBlur={formik.handleBlur}
        value={formik.values.phone}
      />
      {noUser && (
        <AlertMessage
          color="secondary"
          label={"해당 정보를 가진 회원이 없습니다"}
        />
      )}
      <ButtonGrid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <BoldButton
          color="primary"
          size="large"
          onClick={onCancel}
        >{`취소`}</BoldButton>
        <ButtonLoaderWrapper>
          <BoldButton
            color="primary"
            size="large"
            disabled={isLoading}
            onClick={onFindEmail}
          >{`이메일 찾기`}</BoldButton>
          {isLoading && <Loader size={24} />}
        </ButtonLoaderWrapper>
      </ButtonGrid>
    </Form>
  );
};

FindEmail.propTypes = {
  step: PropTypes.number,
  onCancel: PropTypes.func,
};

export { FindEmail };
