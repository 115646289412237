import React, { Fragment } from "react";
import styled from "styled-components";
function DashBoard() {
  return (
    <Fragment>
      <Container>대쉬보드</Container>
    </Fragment>
  );
}

export default DashBoard;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
