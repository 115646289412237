const Posts = [
  {
    id: "1595248777498",
    name: "참이슬",
    company: "진로",
    Categoty: "소주",
    New: true,
    image:
      "https://file.mk.co.kr/meet/neds/2019/04/image_readtop_2019_261362_15561720623725165.jpg",
  },
  {
    id: "1595248790071",
    name: "카스",
    company: "하이트",
    Categoty: "맥주",
    New: true,
    image:
      "https://cphoto.asiae.co.kr/listimglink/6/2019032609030122911_1553558581.jpg",
  },
  {
    id: "1595248805309",
    name: "인생 막걸리",
    company: "장수",
    Categoty: "전통주",
    image:
      "https://img.insight.co.kr/static/2018/10/17/700/137o1juzf2l0bj77pz9s.jpg",
  },
  {
    id: "1595248671978",
    name: "금정산성 막걸리",
    company: "금정산성",
    Categoty: "술",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSlK0BhSXwLbSIltLuZSW6_euMfIPFZvsgvqw&usqp=CAU",
  },

  {
    id: "1595250563177",
    name: "국순당 생막걸리",
    company: "국순당",
    Categoty: "술",
    image:
      "https://lh3.googleusercontent.com/proxy/_Y72AgHOIqMjSpahaydIYhaVKs-r9ucVomXxtlqjbcdqCwKN89-CqRuDGvrQ70R-taoJADV4qrCs4P99OzqXg-PhmDfeo5O5qXNQ83Oy",
  },
];
const Comments = [
  {
    User: "김어진",
    Text: "안녕하세요 테스트 댓글입니다.",
  },
];
const Review = [
  {
    User: "김어진",
    Text: "평가함",
  },
];
export { Posts, Comments, Review };
