import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { BsArrowUp } from "react-icons/bs";

import { FiRepeat } from "react-icons/fi";

function CommentFilter({
  sex,
  setSex,
  age,
  setAge,
  FilterSubmit,
  FilterResetFunc,
}) {
  // console.log(sex);
  // console.log(age);
  return (
    <Fragment>
      <FilterContainer>
        <FilterTitle>
          필터
          <ResetBox onClick={FilterResetFunc}>
            <FiRepeat size={16} style={{ marginRight: "7px" }} />
            초기화
          </ResetBox>
        </FilterTitle>
        <FilterSelectBox>
          <FilterValueTitle>성별</FilterValueTitle>
          <FilterValueArea>
            <FilterValueButton onClick={() => setSex("")} sex={sex} value={""}>
              전체
            </FilterValueButton>
            <FilterValueButton
              onClick={() => setSex("여")}
              sex={sex}
              value={"여"}
            >
              여자
            </FilterValueButton>
            <FilterValueButton
              onClick={() => setSex("남")}
              sex={sex}
              value={"남"}
            >
              남자
            </FilterValueButton>
          </FilterValueArea>
        </FilterSelectBox>
        <FilterSelectBox>
          <FilterValueTitle>연령대</FilterValueTitle>
          <FilterValueArea>
            <FilterValueButton onClick={() => setAge(0)} age={age} value={0}>
              전체
            </FilterValueButton>
            <FilterValueButton onClick={() => setAge(20)} age={age} value={20}>
              20대
            </FilterValueButton>
            <FilterValueButton onClick={() => setAge(30)} age={age} value={30}>
              30대
            </FilterValueButton>
            <FilterValueButton onClick={() => setAge(40)} age={age} value={40}>
              40대
            </FilterValueButton>
            <FilterValueButton onClick={() => setAge(50)} age={age} value={50}>
              50대
            </FilterValueButton>
            <FilterValueButton onClick={() => setAge(60)} age={age} value={60}>
              60대
              <BsArrowUp />
            </FilterValueButton>
          </FilterValueArea>
        </FilterSelectBox>
        <ButtonContainer>
          <SubmitButton onClick={FilterSubmit}>필터 적용</SubmitButton>
        </ButtonContainer>
      </FilterContainer>
    </Fragment>
  );
}

export default CommentFilter;
const s = styled.div``;
const FilterValueButton = styled.div`
  display: flex;
  min-width: 75px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  /* margin: 0px 2%; */
  border: ${(props) =>
    props.value === props.age || props.value === props.sex
      ? "1px solid rgb(67, 115, 110)"
      : "1px solid #ccccce"};
  border-radius: 5px;
  background-color: #fff;
  font-size: 15px;
  font-weight: bold;
  color: ${(props) =>
    props.value === props.age || props.value === props.sex
      ? "rgb(67, 115, 110)"
      : "#666"};
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
`;
const SubmitButton = styled.div`
  width: 120px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(67, 115, 110);

  font-size: 18px;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
const FilterSelectBox = styled.div`
  margin-top: 30px;
`;
const FilterValueTitle = styled.div`
  font-size: 16px;
  color: #111116;
  margin-bottom: 16px;
  font-weight: bold;
`;
const FilterValueArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const ResetBox = styled.div`
  cursor: pointer;
  font-size: 13px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FilterTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: #111111;
  font-weight: bold;
`;
const FilterContainer = styled.div`
  padding: 20px 20px;
  margin-top: 23px;
  width: 302px;
  min-height: 420px;
  /* background-color: #fafafc; */
  background-color: white;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: "Noto Sans KR", sans-serif;
  @media (max-width: 1024px) {
    margin-right: 15px;
  }
  @media (max-width: 640px) {
    margin: 0px;
    width: 100%;
    margin-bottom: 20px;
  }
`;
