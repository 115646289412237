import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API } from "@aws-amplify/api";
import { AiOutlineLoading } from "react-icons/ai";
///////////////////////////////////////////////////////////////////
import styled, { keyframes } from "styled-components";
///////////////////////////////////////////////////////////////////
import RecommendLogic from "../../../Components/RecommendLogic";
import { useSelector } from "react-redux";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

///////////////////////////////////////////////////////////////////
function TodayRecommend() {
  let Admin = useSelector((state) => state.user.Admin);
  const [OpenEditBox, setOpenEditBox] = useState(false);
  const [EditPosts, setEditPosts] = useState([]);
  const [Selected, setSelected] = useState([]);
  const [Finish, setFinish] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [TodayRecommend, setTodayRecommend] = useState([]);
  const [LoadingRander, setLoadingRander] = useState(true);

  useEffect(() => {
    GetAlcoholPost();
  }, []);
  const NewSelected = (id) => {
    Selected.map((data) => {
      if (data.id == id) {
        if (data.newthing === "not") {
          return (data.newthing = "new");
        }
        if (data.newthing === "new") {
          return (data.newthing = "not");
        }
      }
    });
    // console.log(Selected);
  };

  const onClose = () => {
    setOpenEditBox(false);
    window.location.reload();
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    EditPosts.map(async (data) => {
      if (data.id) {
        const ApiResponse = await API.del(
          "TodayRecommendApi",
          "/items/" + data.id
        );
        // console.log(ApiResponse);
      }
    });
    setTimeout(() => {
      Selected.map(async (data) => {
        if (data.id) {
          const ApiResponse = await API.post("TodayRecommendApi", "/items/", {
            body: {
              id: data.id,
              name: data.name,
              image: data.image,
              company: data.company,
              new: data.newthing,
              disc: data.disc,
            },
          });
          // console.log(ApiResponse);
        }
      });
      setLoading(false);
      setFinish(true);
    }, 4000);
  };
  const GetAlcoholPost = async () => {
    const ApiResponse2 = await API.get("TodayRecommendApi", "/items/");
    setTodayRecommend(ApiResponse2);
    setLoadingRander(false);
  };
  // let ItemArray = [];
  const ItemAddFunc = (id, name, company, image, newthing, disc) => {
    const item = {
      id: id,
      name: name,
      image: image,
      company: company,
      disc: disc,
      newthing: newthing,
    };
    setSelected([...Selected, item]);
  };
  const ItemDelFunc = (id) => {
    setSelected(Selected.filter((data) => data.id !== id));
  };

  // console.log(Selected);
  const RanderRecommend = TodayRecommend.map((data) => {
    return (
      <Fragment>
        <ItemBox to={`/Post/:${data.id}`}>
          {data.new === "new" && <New>New</New>}
          <ImageBox>
            <Image src={data.image}></Image>
          </ImageBox>
          <ItemInfo>
            <Product>{data.name}</Product>
            <Company>{data.company}</Company>
          </ItemInfo>
        </ItemBox>
      </Fragment>
    );
  });
  const RanderEdit = EditPosts.map((data) => {
    return (
      <RecommendLogic
        id={data.id}
        newthing={data.new}
        name={data.name}
        image={data.image}
        disc={data.disc}
        company={data.company}
        ItemAddFunc={ItemAddFunc}
        ItemDelFunc={ItemDelFunc}
        NewSelected={NewSelected}
      />
    );
  });
  const OpenEditFunc = async () => {
    setOpenEditBox(!OpenEditBox);
    const ApiResponse1 = await API.get("AlcoholPostApi", "/items/");
    setEditPosts(ApiResponse1);
  };
  return (
    <Fragment>
      {!LoadingRander && (
        <Banner>
          {OpenEditBox && (
            <Fragment>
              {Loading && <EditContainer>잠시만 기다려 주세요</EditContainer>}
              {!Finish && !Loading && (
                <EditContainer>
                  <EditExploreBox>
                    <RanderWrapper>{RanderEdit}</RanderWrapper>
                    <SubmitButtonBox>
                      <SubmitButton onClick={onSubmit}>등록</SubmitButton>
                    </SubmitButtonBox>
                  </EditExploreBox>
                  <CloseWapper>
                    <CloseButton onClick={() => setOpenEditBox(false)}>
                      닫기
                    </CloseButton>
                  </CloseWapper>
                </EditContainer>
              )}
              {Finish && (
                <EditContainer>
                  <SuccessTitle>
                    <IoMdCheckmarkCircleOutline
                      size={32}
                      style={{ marginRight: "10px", color: "green" }}
                    />
                    등록이 완료되었습니다.
                  </SuccessTitle>
                  <CloseButton onClick={onClose}>닫기</CloseButton>
                </EditContainer>
              )}
            </Fragment>
          )}
          {!OpenEditBox && (
            <Fragment>
              <ItemContainer>
                <ItemWrapper>{RanderRecommend}</ItemWrapper>
              </ItemContainer>
              {Admin && <Edit onClick={OpenEditFunc}>수정하기</Edit>}
            </Fragment>
          )}
        </Banner>
      )}
      {LoadingRander && (
        <Fragment>
          <LoadingContainer>
            <LoadingIcon>
              <AiOutlineLoading size={32} />
            </LoadingIcon>
          </LoadingContainer>
        </Fragment>
      )}
    </Fragment>
  );
}

export default TodayRecommend;
const CloseWapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;
const rotation = keyframes`
    0% {
      transform: rotate(0deg);
      opacity: 0.1;
    }
    25% {
      transform: rotate(90deg);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.1;
    }
    75% {
      transform: rotate(360deg);
      opacity: 1;
    }
  `;
const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoadingIcon = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotation} 1s infinite linear;
`;
const SuccessTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const RanderWrapper = styled.div`
  overflow: scroll;
  /* overflow-y: hidden;
  overflow-x: hidden; */
  height: 260px;
  padding: 5px;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f2f2;
  /* -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    width: 0px;
    display: none; /* Chrome, Safari, Opera*/
  }
`;
const SubmitButtonBox = styled.div`
  padding: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const CloseButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
  width: 80px;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
  background-color: white;
  color: black;
  font-weight: bold;
`;
const SubmitButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
  width: 80px;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
  background-color: white;
  color: black;
  font-weight: bold;
`;

const EditExploreBox = styled.div`
  width: 450px;
  /* min-height: 350px; */
  /* box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.18); */
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px 10px;
`;
const EditContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 30px; */
  /* position: relative;
  z-index: 100; */
  width: 100%;
  /* margin-top: 10px; */
  /* min-height: 400px; */
  /* border: 1px solid #f2f2f2; */
  /* border-radius: 5px; */
  box-sizing: border-box;
  background-color: white;
`;
const Edit = styled.div`
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f2f2;
  width: 80px;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
  color: black;
  font-weight: bold;
`;
const BannerMove = keyframes`
0%{
  opacity:0;
}
100%{
  opacity:1;
}
`;

const ItemWrapper = styled.div`
  display: flex;
  /* margin-left: 15px; */
  justify-content: center;
  align-items: center;
  /* padding: 0px 5px; */
`;
const New = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 20;
  width: 50px;
  height: 25px;
  /* border: 1px solid gray; */
  /* box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.18); */
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 14px;
  /* font-weight: bold; */
  color: black;
`;
const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 140px;
  /* border: 1px solid blue; */
  box-sizing: border-box;
`;
const Product = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: black;
`;
const Company = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: gray;
`;
const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 60px;
`;
const Image = styled.div`
  width: 110px;
  height: 140px;
  background-image: url(${(props) => props.src});
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
`;
const ItemBox = styled(Link)`
  /* font-family: "RIDIBatang", sans-serif; */
  position: relative;
  width: 100%;
  min-width: 200px;
  height: 200px;
  /* border: 1px solid blue; */
  /* box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.18); */
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  /* background-color: lightgray; */
  box-sizing: border-box;
  text-decoration: none;
  margin-right: 10px;
  @media (max-width: 640px) {
    margin: 0px 5px;
  }
`;
const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* justify-content: space-between; */
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
  box-sizing: border-box;
  /* padding: 0px 10px; */
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  /* overflow-x: hidden; */
  /* -ms-overflow-style: none; IE and Edge */
`;
const TitleBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* text-align: left; */
`;
const Title = styled.div`
  font-size: 22px;
  margin-left: 15px;
  font-weight: bold;
`;

const Banner = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  width: 100%;
  min-height: 200px;
  /* margin-bottom: 50px; */
  /* padding: 10px; */
  @media (max-width: 640px) {
    padding: 0px;
  }
  box-sizing: border-box;
  margin: 20px 0px 0px 0px;
  animation: ${BannerMove} 1.5s backwards;
  /* font-family: "RIDIBatang", sans-serif; */
`;
