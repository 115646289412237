import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
////////////////////////////////////////////////////////////////
import "./Editor.css";
import styled from "styled-components";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MdClose } from "react-icons/md";
import { useHistory } from "react-router-dom";

////////////////////////////////////////////////////////////////
import { API } from "@aws-amplify/api";
////////////////////////////////////////////////////////////////
function FreeBoardEditPresenter({ match }) {
  useEffect(() => {
    GetPost();
  }, []);
  const User = useSelector((state) => state.user.Data);

  const Id = match.params.PostId.split(":")[1];
  const history = useHistory();
  const [TitleValue, setTitleValue] = useState("");
  const [Article, setArticle] = useState("");
  const [Category, setCategory] = useState("");
  const [Writer, setWriter] = useState("");
  const [PreviewControl, setPreviewControl] = useState(true);
  // console.log(Article);
  // console.log(Id);

  const GetPost = async () => {
    let ReviewApiResponse = await API.get("FreeBoardApi", "/items/");
    let Filtered = await ReviewApiResponse.filter(
      (data) => data.user == User && Id == data.id
    );
    await Filtered.map((data) => {
      setArticle(data.article);
      setTitleValue(data.title);
      setWriter(data.user);
    });
  };

  let date = new Date();
  let UploadTime =
    date.getFullYear() +
    "-" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("00" + date.getDate()).slice(-2);
  const onSubmit = async (e) => {
    e.preventDefault();
    const ApiResponse = await API.post("FreeBoardApi", "/items/", {
      body: {
        id: Number(Id),
        title: TitleValue,
        category: Category,
        article: Article,
        user: User,
        time: UploadTime,
        edit: "수정됨",
      },
    });
    // console.log(ApiResponse);
    history.push(`/FreeBoard/:${Id}`);
  };

  return (
    <Fragment>
      {PreviewControl && (
        <BoardContainer>
          {Writer == User && (
            /* 직접링크타고 오는 유저 방지 */
            <Fragment>
              <BoardUploadTitle to="/FreeBoard">자유게시판</BoardUploadTitle>
              <UploadContainer>
                <UploadBasicBox>
                  <BasicContainer>
                    <BasicName>제목</BasicName>
                    <TitleInputBox
                      placeholder="제목을 입력하세요"
                      value={TitleValue}
                      onChange={(e) => setTitleValue(e.target.value)}
                    ></TitleInputBox>
                  </BasicContainer>
                </UploadBasicBox>
                <ArticleContainer>
                  <CKEditor
                    editor={ClassicEditor}
                    data={Article}
                    onInit={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      //   console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      setArticle(editor.getData());

                      //   console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      //   console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      //   console.log("Focus.", editor);
                    }}
                  />
                </ArticleContainer>
                <ButtonContainer>
                  {/*
                <Button onClick={() => setPreviewControl(false)}>
                  미리보기
                </Button>
                */}
                  <Button onClick={onSubmit}>수정하기</Button>
                </ButtonContainer>
              </UploadContainer>
            </Fragment>
          )}
        </BoardContainer>
      )}
      {!PreviewControl && (
        <Bg>
          <PreviewContainer>
            <CloseContainer>
              <CloseButton onClick={() => setPreviewControl(true)}>
                <MdClose size={22} />
              </CloseButton>
            </CloseContainer>
            <UploadBasicBox>
              <BasicContainer>
                <BasicName>제목</BasicName>
                {TitleValue}
              </BasicContainer>
            </UploadBasicBox>
            <PreviewArticle dangerouslySetInnerHTML={{ __html: Article }} />
          </PreviewContainer>
        </Bg>
      )}
    </Fragment>
  );
}

export default FreeBoardEditPresenter;
const CloseButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
`;
const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
`;
const Bg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 55;
  width: 100%;
  height: 100%;
  background-color: gray;
`;
const PreviewContainer = styled.div`
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  z-index: 40;
  padding: 20px;
  min-height: 300px;
  border: 1px solid white;
  border-radius: 20px;

  box-sizing: border-box;
  background-color: white;
`;
const PreviewArticle = styled.div`
  padding: 20px;
`;
const Button = styled.div`
  margin: 0px 10px;
  cursor: pointer;
  font-family: "Noto Sans KR", sans-serif;
  text-decoration: none;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  width: 75px;
  height: 40px;
  border-radius: 3px;
  color: black;
  background-color: white;
`;
const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ArticleContainer = styled.div`
  margin-top: 30px;
  /* padding: 20px 0px; */
  width: 100%;
  /* min-height: 500px; */
  /* border: 1px solid blue; */
  box-sizing: border-box;
`;
const Option = styled.option``;
const SelectBox = styled.select`
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  padding: 0px 10px;
  outline: none;
  cursor: pointer;
  border: 1px solid gray;
  border-top: none;
  border-bottom: none;
  font-size: 16px;
  font-family: "RIDIBatang", sans-serif;
  box-sizing: border-box;
  @media (max-width: 515px) {
    font-size: 14px;
  }
`;
const BasicContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px;
`;
const BasicName = styled.div`
  width: 100px;
  font-size: 16px;
`;
const TitleInputBox = styled.input`
  color: black;
  font-size: 16px;
  width: 50%;
  height: 35px;
  border: 1px solid #f2f2f2;
  padding: 0px 10px;
  &::placeholder {
    font-size: 16px;
    font-family: "Noto Sans KR", sans-serif;
  }
`;
const UploadBasicBox = styled.div`
  display: flex;
  align-items: center;
  font-family: "Noto Sans KR", sans-serif;
  width: 100%;
  min-height: 70px;
  border: 1px solid #f2f2f2;
  border-left: none;
  border-right: none;
  padding: 0px 20px;
  box-sizing: border-box;
`;
const UploadContainer = styled.div`
  width: 100%;
  min-height: 500px;
  /* border: 1px solid red; */
  box-sizing: border-box;
  margin-top: 30px;
`;
const BoardUploadTitle = styled(Link)`
  font-family: "Noto Sans KR", sans-serif;
  text-decoration: none;
  font-weight: bold;
  font-size: 24px;
  color: black;
  width: 100%;
  text-align: center;
`;
const BoardContainer = styled.div`
  position: relative;
  z-index: 0;
  margin-top: 80px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px red solid; */
  box-sizing: border-box;
  padding: 20px 20px;
  @media (max-width: 640px) {
    padding: 0px 10px;
  }
`;
