import React, { Fragment, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { FaOutdent } from "react-icons/fa";
import Helmet from "react-helmet";
import { FaChalkboardTeacher } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { useSelector } from "react-redux";
import BannerManage from "./AdminComponents/BannerManage";
import DashBoard from "./AdminComponents/DashBoard";
import PostManage from "./AdminComponents/PostManage";
function AdminPresenter() {
  const [MenuSelect, setMenuSelect] = useState("배너관리");
  const Admin = useSelector((state) => state.user.Admin);
  return (
    
    <Fragment>
    {Admin &&(
      <Fragment>
      <Helmet>
        <title>내술 노트 | 관리자 페이지</title>
      </Helmet>
      <Container>
        <AdminPage>
          <LeftBar>
            <LeftBarItem
              onClick={() => setMenuSelect("대쉬보드")}
              value={"대쉬보드"}
              MenuSelect={MenuSelect}
            >
              <MdDashboard size={23} style={{ marginRight: "10px" }} />
              대쉬보드
            </LeftBarItem>
            <LeftBarItem
              onClick={() => setMenuSelect("배너관리")}
              value={"배너관리"}
              MenuSelect={MenuSelect}
            >
              <FaOutdent size={22} style={{ marginRight: "10px" }} />
              배너관리
            </LeftBarItem>
            <LeftBarItem
              onClick={() => setMenuSelect("게시물 관리")}
              value={"게시물 관리"}
              MenuSelect={MenuSelect}
            >
              <FaChalkboardTeacher size={22} style={{ marginRight: "10px" }} />
              게시물 관리
            </LeftBarItem>
          </LeftBar>
          <MainBar>
            <RightBarTitle>{MenuSelect}</RightBarTitle>
            {MenuSelect === "대쉬보드" && <DashBoard />}
            {MenuSelect === "배너관리" && <BannerManage />}
            {MenuSelect === "게시물 관리" && <PostManage />}
          </MainBar>
        </AdminPage>
      </Container>
      </Fragment>
      )}
    </Fragment>
    
  );
}

export default AdminPresenter;
const a = styled.div``;
const RightBarTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 60px;
  padding: 0px 30px;
  box-sizing: border-box;
  /* border: 1px solid #f2f2f2; */
  font-size: 18px;
  color: black;
  font-weight: bold;
  background-color: white;
`;

const LeftBarTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #2f434c;
  font-size: 18px;
  color: white;
  font-weight: bold;
`;

const LeftBarItem = styled.button`
  cursor: pointer;
  outline: none;
  display: flex;
  /* margin: 10px 0px; */
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  color: white;
  font-weight: bold;
  height: 60px;
  width: 100%;
  padding: 0px 0px 0px 40px;
  background-color: ${(props) =>
    props.MenuSelect === props.value ? "#2f434c" : "#253337"};
  border: none;
  box-sizing: border-box;
`;

const AdminPage = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;
const LeftBar = styled.div`
  /* padding: 30px 0px; */
  width: 250px;
  height: 100%;
  background-color: #253337;
  box-sizing: border-box;
`;
const MainBar = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #eceff1;
  /* border: 1px solid blue; */
  box-sizing: border-box;
`;

const Container = styled.div`
  /* padding: 30px 0px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid red; */
  box-sizing: border-box;
  /* margin: 0px auto; */
  height: 800px;
  width: 100%;
  margin-top: 70px;
`;
