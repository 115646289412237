import React, { Fragment } from "react";
import Helmet from "react-helmet";
import FreeBoardExplorePresenter from "./FreeBoardExplorePresenter";
function FreeBoardExploreContainer() {
  return (
    <Fragment>
      <Helmet>
        <title>내술 노트 | 자유게시판</title>
      </Helmet>
      <FreeBoardExplorePresenter />
    </Fragment>
  );
}

export default FreeBoardExploreContainer;
