/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  AuthUser,
  TestUser,
  UserEmail,
  UserPhone,
  UserAdmin,
  UserGender,
  UserAge,
} from "../_actions/user_actions";
import { useSelector, useDispatch } from "react-redux";

export default function (ComposedClass, reload, adminRoute = null) {
  function AuthenticationCheck(props) {
    let user = useSelector((state) => state.Data);
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(TestUser());
      dispatch(UserEmail());
      dispatch(UserPhone());
      dispatch(UserAdmin());
      dispatch(UserAge());
      dispatch(UserGender());
      dispatch(AuthUser()).then((response) => {
        if (response.payload !== 200) {
          if (reload) {
            props.history.push("/Signin");
          }
        } else {
          if (adminRoute) {
            props.history.push("/");
          } else {
            if (reload === false) {
              props.history.push("/");
            }
          }
        }
      });
    }, []);

    return <ComposedClass {...props} user={user} />;
  }
  return AuthenticationCheck;
}
