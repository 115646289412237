import React, { Fragment } from "react";
import { Link } from "react-router-dom";
/////////////////////////////////////////////////////////////////////////////////
import styled from "styled-components";
/////////////////////////////////////////////////////////////////////////////////

function KeyWordBox({ KeywordBoxOnOff }) {
  const onRemove = () => {
    // window.sessionStorage.removeItem("latestSearchKeyword");
    // window.sessionStorage.setItem("latestSearchKeyword", "최근검색어");
    // window.location.reload();
  };
  let SessionKeyword = window.localStorage
    .getItem("latestSearchKeyword")
    .split(",");

  const RenderKeyword = SessionKeyword.map((text) => {
    if (text === "최근검색어") {
      return (
        <RecentKeyWordBox>
          <RecentKeyWord>{text}</RecentKeyWord>
          <Close onClick={KeywordBoxOnOff}>닫기</Close>
        </RecentKeyWordBox>
      );
    }
    return (
      <Keyword to={`Search?term=${text}`} onClick={KeywordBoxOnOff}>
        {text}
      </Keyword>
    );
  });

  return (
    <Fragment>
      <Container>
        <KeyBox>
          {RenderKeyword}
          <Delete>
            <Close onClick={onRemove}>전체삭제</Close>
          </Delete>
        </KeyBox>
      </Container>
    </Fragment>
  );
}

export default KeyWordBox;
const Delete = styled.div`
  margin: 20px 0px;

  /* border: 1px solid blue; */
`;
const Close = styled.div`
  cursor: pointer;
  text-align: right;
  color: black;
  font-size: 14px;
`;
const RecentKeyWordBox = styled.div`
  /* border: 1px solid blue; */
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const KeyBox = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 400px;
  height: 100%;
  /* border: 1px solid red; */
  font-family: "RIDIBatang", sans-serif;
  overflow: scroll;
  padding: 0px 15px;
  box-sizing: border-box;
`;
const Container = styled.div`
  position: relative;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  /* border: 1px solid red; */
  position: fixed;
  display: flex;
  top: 80px;
  background-color: white;
  border-bottom: 1px solid lightgray;
`;
const Keyword = styled(Link)`
  margin: 3px 0px;
  font-size: 16px;
  color: black;
  text-decoration: none;

  &:hover {
    color: black;
    opacity: 0.7;
  }
`;

const RecentKeyWord = styled.div`
  display: flex;
  align-items: center;
  /* font-weight: bold; */
  font-size: 22px;

  color: black;
`;
