import React, { Fragment, useState } from "react";
import styled, { keyframes } from "styled-components";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { RiCheckboxCircleLine } from "react-icons/ri";
function RecommendLogic({
  id,
  name,
  company,
  image,
  disc,
  newthing,
  ItemAddFunc,
  ItemDelFunc,
  NewSelected,
}) {
  const [Selected, setSelected] = useState(false);
  const [CheckNew, setCheckNew] = useState(false);
  const Cliked = () => {
    setSelected(false);
    ItemDelFunc(id);
  };
  const NonCliked = () => {
    setSelected(true);
    let newthing = "not";
    ItemAddFunc(id, name, company, image, newthing,disc);
  };
  const NewSelect = () => {
    setCheckNew(!CheckNew);
    NewSelected(id);
  };
  return (
    <Fragment>
      <EditItemBox>
        {!Selected && (
          <CheckIcon value={id} onClick={NonCliked}>
            <NonCheckIcon />
          </CheckIcon>
        )}
        {Selected && (
          <CheckIcon value={id} onClick={Cliked}>
            <CheckedIcon />
          </CheckIcon>
        )}
        <EditNameValue>{name}</EditNameValue>
        <EditValue>{company}</EditValue>
        <NewCheckBox
          onClick={NewSelect}
          disabled={!Selected}
          check={CheckNew}
          select={Selected}
        >
          신규
        </NewCheckBox>
      </EditItemBox>
    </Fragment>
  );
}

export default RecommendLogic;
const NewCheckBox = styled.button`
  cursor: ${(props) => (props.select ? "pointer" : "not-allowed")};
  outline: none;
  color: ${(props) => (props.check ? "white" : "lightgray")};
  padding: 5px;
  border: 1px solid #f2f2f2;
  background-color: ${(props) => (props.check ? "lightgray" : "white")};
  border-radius: 5px;
`;
const CheckIcon = styled.button`
  outline: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;
const CheckedIcon = styled(RiCheckboxCircleLine)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  pointer-events: none;
`;
const NonCheckIcon = styled(RiCheckboxBlankCircleLine)`
  width: 20px;
  height: 20px;
  color: #f2f2f2;
  cursor: pointer;
  pointer-events: none;
`;
const EditValue = styled.div`
  min-width: 80px;
  font-size: 14px;
`;
const EditNameValue = styled.div`
  min-width: 120px;
  font-size: 14px;
  font-weight: bold;
`;
const EditItemBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  padding: 0px 20px;
  box-sizing: border-box;
  margin-bottom: 10px;

  /* background-color: white; */
`;
