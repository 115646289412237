import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { API } from "@aws-amplify/api";
import Storage from "@aws-amplify/storage";
import { RiErrorWarningLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
function NewPostUpload() {
  // const [ImageSrc, setImageSrc] = useState("");
  const history = useHistory();
  const [Name, setName] = useState("");
  const [Company, setCompany] = useState("");
  const [Disc, setDisc] = useState("");
  const [Category, setCategory] = useState("");
  const [Shop, setShop] = useState("");

  const [Price, setPrice] = useState(null);
  const [Volume, setVolume] = useState(null);
  const [ImageFile, setImageFile] = useState("");
  const [ThumbFile1, setThumbFile1] = useState("");
  const [ThumbFile2, setThumbFile2] = useState("");
  const [ThumbFile3, setThumbFile3] = useState("");

  const [Alcohol, setAlcohol] = useState(null);
  const [Location, setLocation] = useState("");
  const [Materials, setMaterials] = useState("");
  const [Tag, setTag] = useState("");

  // const [ThumbnailId, setThumbnailId] = useState("");

  // console.log(ImageSrc);
  // console.log(Name);
  // console.log(Company);
  let id = Number(Date.now());
  const onSubmit = async (e) => {
    e.preventDefault();

    if (
      // ImageSrc == "" ||
      Name == "" ||
      Company == "" ||
      Disc == "" ||
      Category == "" ||
      Alcohol == null ||
      Location == "" ||
      Materials == "" ||
      Tag == "" ||
      Price == null ||
      Volume == null
    ) {
      alert("모든 정보를 입력하세요");
    } else {
      const ApiResponse = await API.post("AlcoholPostApi", "/items/", {
        body: {
          id: id,
          name: Name,
          company: Company,
          image: ThumbFile1,
          image2: ThumbFile2,
          image3: ThumbFile3,
          disc: Disc,
          alcohol: Alcohol,
          category: Category,
          location: Location,
          materials: Materials,
          tag: Tag,
          price: Price,
          volume: Volume,
        },
      });
      Storage.remove(`thumbnail${id}.png`)
        .then((result) => console.log(result))
        .catch((err) => console.log(err));
      history.push("/postExplore");
    }
  };

  const onChange1 = async (data) => {
    let id = Number(Date.now());
    // console.log(data);
    await setImageFile(data);
    // setThumbnailId1(id);
    // console.log("thumb" + ImageFile);
    const ImageResponse = await Storage.put(`thumbnail${id}.png`, data, {
      ACL: "public-read",
      contentType: "image/png",
      level: "public",
    });

    setThumbFile1(
      `https://s3bucket95339-dev.s3.ap-northeast-2.amazonaws.com/public/thumbnail${id}.png`
    );
  };
  const onChange2 = async (data) => {
    let id = Number(Date.now());
    // console.log(data);
    await setImageFile(data);
    // setThumbnailId2(id);
    // console.log("thumb" + ImageFile);
    const ImageResponse = await Storage.put(`thumbnail${id}.png`, data, {
      ACL: "public-read",
      contentType: "image/png",
      level: "public",
    });

    setThumbFile2(
      `https://s3bucket95339-dev.s3.ap-northeast-2.amazonaws.com/public/thumbnail${id}.png`
    );
  };
  const onChange3 = async (data) => {
    let id = Number(Date.now());
    // console.log(data);
    await setImageFile(data);
    // setThumbnailId3(id);
    // console.log("thumb" + ImageFile);
    const ImageResponse = await Storage.put(`thumbnail${id}.png`, data, {
      ACL: "public-read",
      contentType: "image/png",
      level: "public",
    });

    setThumbFile3(
      `https://s3bucket95339-dev.s3.ap-northeast-2.amazonaws.com/public/thumbnail${id}.png`
    );
  };

  // onChange={(e) => setImageFile(e.target.files[0])}
  return (
    <Fragment>
      <UploadContainer>
        <UploadBox>
          <UploadTitle>술 업로드</UploadTitle>
          <DataWapper>
            <ImageBox>
              <ImageArea src={ThumbFile1}></ImageArea>
              <ImageArea src={ThumbFile2}></ImageArea>
              <ImageArea src={ThumbFile3}></ImageArea>
            </ImageBox>
            <DataBox>
              <DataArea>
                <ValueTitle>제품명</ValueTitle>
                <InputBox
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="예) 참이슬"
                />
              </DataArea>
              <DataArea>
                <ValueTitle>제조사</ValueTitle>
                <InputBox
                  value={Company}
                  onChange={(e) => setCompany(e.target.value)}
                  placeholder="예) 진로"
                />
              </DataArea>
              <DataArea>
                <ValueTitle>메인 이미지</ValueTitle>
                <InputBox
                  type="file"
                  accept="image/png"
                  onChange={(e) => onChange1(e.target.files[0])}
                />
              </DataArea>
              <DataArea>
                <ValueTitle>추가 이미지</ValueTitle>
                <InputBox
                  type="file"
                  accept="image/png"
                  onChange={(e) => onChange2(e.target.files[0])}
                />
              </DataArea>
              <DataArea>
                <ValueTitle>추가 이미지</ValueTitle>
                <InputBox
                  type="file"
                  accept="image/png"
                  onChange={(e) => onChange3(e.target.files[0])}
                />
              </DataArea>
              <DataArea>
                <ValueTitle>식품유형</ValueTitle>
                <SelectBox onChange={(e) => setCategory(e.target.value)}>
                  <option value="">종류</option>
                  <option value="막걸리">막걸리</option>
                  <option value="약주">약주</option>
                </SelectBox>
              </DataArea>
              {/*<DataArea>
                <ValueTitle>판매처</ValueTitle>
                <InputBox
                  value={Shop}
                  onChange={(e) => setShop(e.target.value)}
                  placeholder="예) GS25,CU"
                />
              </DataArea>
              <Notice>
                <RiErrorWarningLine size={18} style={{ marginRight: "10px" }} />
                두개 이상의 판매처의 경우 "," 구분자를 써서 입력하세요.
              </Notice>
              */}
              <DataArea>
                <ValueTitle>소재지</ValueTitle>
                <InputBox
                  value={Location}
                  onChange={(e) => setLocation(e.target.value)}
                  placeholder="예) 서울"
                />
              </DataArea>
              <DataArea>
                <ValueTitle>원재료 및 함량</ValueTitle>
                <InputBox
                  value={Materials}
                  onChange={(e) => setMaterials(e.target.value)}
                  placeholder="예) ..."
                />
              </DataArea>
              <DataArea>
                <ValueTitle>에탄올 함량</ValueTitle>
                <InputBox
                  value={Alcohol}
                  onChange={(e) => setAlcohol(e.target.value)}
                  placeholder="예) 5"
                />
              </DataArea>
              <Notice>
                <RiErrorWarningLine size={18} style={{ marginRight: "10px" }} />{" "}
                "%"를 쓰지 않고 입력하세요.
              </Notice>
              <DataArea>
                <ValueTitle>용량</ValueTitle>
                <InputBox
                  value={Volume}
                  onChange={(e) => setVolume(e.target.value)}
                  placeholder="예) 1000"
                />
                ml
              </DataArea>
              <Notice>
                <RiErrorWarningLine size={18} style={{ marginRight: "10px" }} />{" "}
                "," 구분자를 쓰지 않고 입력하세요.
              </Notice>
              <DataArea>
                <ValueTitle>가격</ValueTitle>
                <InputBox
                  value={Price}
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder="예) 2000"
                />
                원
              </DataArea>
              <Notice>
                <RiErrorWarningLine size={18} style={{ marginRight: "10px" }} />{" "}
                "," 구분자를 쓰지 않고 입력하세요.
              </Notice>
              <DataArea>
                <ValueTitle>태그</ValueTitle>
                <InputBox
                  value={Tag}
                  onChange={(e) => setTag(e.target.value)}
                  placeholder="예) 막걸리,금정산성"
                />
                ml
              </DataArea>
              <Notice>
                <RiErrorWarningLine size={18} style={{ marginRight: "10px" }} />{" "}
                두개 이상의 태그를 입력 할 경우 "," 구분자를 써서 입력하세요.
              </Notice>
              <DataArea>
                <ValueTitle>설명</ValueTitle>
                <InputArea
                  value={Disc}
                  onChange={(e) => setDisc(e.target.value)}
                />
              </DataArea>
            </DataBox>
          </DataWapper>
          <UploadButtonBox>
            <UploadButton onClick={onSubmit}>등록하기</UploadButton>
          </UploadButtonBox>
        </UploadBox>
      </UploadContainer>
    </Fragment>
  );
}

export default NewPostUpload;
const SelectBox = styled.select`
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border: none;
  font-size: 16px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 2px solid gray;
  width: 200px;
  font-family: "Noto Sans KR", sans-serif;
`;
const Notice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: red;
`;
const UploadButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const UploadButton = styled.div`
  cursor: pointer;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  color: white;
  border-radius: 5px;
  background-color: lightgray;
`;
const DataWapper = styled.div`
  display: flex;
  /* justify-content: flex-start; */
  @media (max-width: 640px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const DataArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px;
  width: 100%;
  @media (max-width: 640px) {
    justify-content: center;
  }
`;

const ValueTitle = styled.div`
  width: 100px;
`;
const InputBox = styled.input`
  height: 30px;
  width: 200px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 2px solid gray;
  padding: 0px;
  outline: none;
  font-size: 16px;
  font-family: "Noto Sans KR", sans-serif;
`;
const InputArea = styled.textarea`
  height: 200px;
  width: 350px;
  font-size: 16px;
  font-family: "Noto Sans KR", sans-serif;

  margin-left: 5px;
  padding: 0px;
  /* box-sizing: border-box; */
  @media (max-width: 640px) {
    width: 100%;
    margin-top: 5px;
  }
`;
const ImageArea = styled.div`
  width: 250px;
  height: 250px;
  margin-bottom: 20px;
  background-color: gray;
  background-image: url(${(props) => props.src});
  background-size: 250px 250px;
  background-position: center;
  background-repeat: no-repeat;
`;
const ImageBox = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  /* height: 250px; */
  box-sizing: border-box;
`;
const DataBox = styled.div`
  padding: 20px 20px 20px 140px;
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 640px) {
    padding: 0px;
    width: 100%;
  }
`;

const UploadTitle = styled.div`
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const UploadBox = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 100%;
  min-height: 200px;
  /* box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18); */
  border: 1px solid #f2f2f2;
  padding: 30px 60px;
  box-sizing: border-box;
  @media (max-width: 640px) {
    padding: 30px 5px;
  }
`;
const UploadContainer = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: 100px auto 0px auto;
  min-height: 500px;
`;
