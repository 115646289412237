import React, { Fragment } from "react";

import FreeBoardPresenter from "./FreeBoardPresenter";
function FreeBoardContainer({ match }) {
  const PostId = match.params.PostId.split(":")[1];
  // console.log(PostId);
  return (
    <Fragment>
      <FreeBoardPresenter PostId={PostId} />
    </Fragment>
  );
}

export default FreeBoardContainer;
