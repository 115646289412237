import React, { Fragment } from "react";
import styled from "styled-components";
function Texture({
  WeightClicked,
  setWeightClicked,
  CarbonatedClicked,
  setCarbonatedClicked,
}) {
  return (
    <Fragment>
      <BarContainer>
        <TasteBox>
          <MainTitle>무게감</MainTitle>
          <CheckContainer>
            <ArchBar>
              <Choose
                value={20}
                weight={WeightClicked}
                onClick={() => setWeightClicked(20)}
              >
                없음
              </Choose>
              <Choose
                value={40}
                weight={WeightClicked}
                onClick={() => setWeightClicked(40)}
              >
                적음
              </Choose>
              <Choose
                value={60}
                weight={WeightClicked}
                onClick={() => setWeightClicked(60)}
              >
                보통
              </Choose>
              <Choose
                value={80}
                weight={WeightClicked}
                onClick={() => setWeightClicked(80)}
              >
                충분
              </Choose>
              <Choose
                value={100}
                weight={WeightClicked}
                onClick={() => setWeightClicked(100)}
              >
                많음
              </Choose>
            </ArchBar>
            <MixDetail>
              {WeightClicked == 20 && (
                <DetailText>술이 물같이 느껴집니다.</DetailText>
              )}
              {WeightClicked == 40 && (
                <DetailText>술에 점도가 약간 있습니다.</DetailText>
              )}
              {WeightClicked == 60 && (
                <DetailText>술의 점도가 보통입니다.</DetailText>
              )}
              {WeightClicked == 80 && (
                <DetailText>술의 점도가 보통 이상입니다.</DetailText>
              )}
              {WeightClicked == 100 && (
                <DetailText>술이 죽 혹은 시럽처럼 느껴집니다.</DetailText>
              )}
            </MixDetail>
          </CheckContainer>
        </TasteBox>
        {/*///////////////////////////////////////////////////*/}
        <TasteBox>
          <MainTitle>탄산감</MainTitle>
          <CheckContainer>
            <ArchBar>
              <ChooseAlcohol
                value={33}
                carbonated={CarbonatedClicked}
                onClick={() => setCarbonatedClicked(33)}
              >
                없음
              </ChooseAlcohol>
              <ChooseAlcohol
                value={66}
                carbonated={CarbonatedClicked}
                onClick={() => setCarbonatedClicked(66)}
              >
                보통
              </ChooseAlcohol>
              <ChooseAlcohol
                value={100}
                carbonated={CarbonatedClicked}
                onClick={() => setCarbonatedClicked(100)}
              >
                많음
              </ChooseAlcohol>
            </ArchBar>
            <MixDetail>
              {CarbonatedClicked == 33 && (
                <DetailText>술에 탄산감이 없습니다.</DetailText>
              )}
              {CarbonatedClicked == 66 && (
                <DetailText>술의 탄산감이 보통입니다.</DetailText>
              )}
              {CarbonatedClicked == 100 && (
                <DetailText>술이 톡 쏜다고 느껴집니다.</DetailText>
              )}
            </MixDetail>
          </CheckContainer>
        </TasteBox>
      </BarContainer>
    </Fragment>
  );
}

export default Texture;
const MixDetail = styled.div`
  margin-top: 30px;
`;
const DetailText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #4d75f2;
`;
const CheckContainer = styled.div`
  margin-top: 40px;
  width: 100%;
`;
const MainTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;
const TasteBox = styled.div`
  width: 100%;
  margin: 30px 0px;
`;
const ChooseAlcohol = styled.div`
  @media (max-width: 640px) {
    width: 55px;
    height: 55px;
    font-size: 14px;
  }
  cursor: pointer;
  width: 80px;
  height: 80px;
  /* border: 2px solid #f5f6f8; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 50%;
  color: ${(props) => (props.carbonated === props.value ? "white" : "#b5bbc7")};
  background-color: ${(props) =>
    props.carbonated === props.value ? "#4d75f2" : "#f5f6f8"};
`;
const Choose = styled.div`
  @media (max-width: 640px) {
    width: 55px;
    height: 55px;
    font-size: 14px;
  }
  cursor: pointer;
  width: 80px;
  height: 80px;
  /* border: 2px solid #f5f6f8; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 50%;
  color: ${(props) => (props.weight === props.value ? "white" : "#b5bbc7")};
  background-color: ${(props) =>
    props.weight === props.value ? "#4d75f2" : "#f5f6f8"};
`;
const ArchBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BarContainer = styled.div`
  @media (max-width: 640px) {
    padding: 0px 10px;
  }
  padding: 0px 95px;
  box-sizing: border-box;
  margin: 30px 0px;
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
