import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { GoTriangleDown } from "react-icons/go";
import MoreColorSelect from "./MoreColorSelect";
import { RiArrowRightSLine } from "react-icons/ri";

function ColorSelectLogic({
  MixClicked,
  setMixClicked,
  ColorClicked,
  setColorClicked,
}) {
  const [MoreClick, setMoreClick] = useState(false);
  return (
    <Fragment>
      <ColorBarContainer>
        <SelectBox>
          <Select size={24} value="1" colors={ColorClicked} />
          <Select size={24} value="2" colors={ColorClicked} />
          <Select size={24} value="3" colors={ColorClicked} />
          <Select size={24} value="4" colors={ColorClicked} />
          <Select size={24} value="5" colors={ColorClicked} />
          <Select size={24} value="6" colors={ColorClicked} />
        </SelectBox>
        <ColorBar>
          <Fragment>
            <ColorSelect>
              <Color
                color={"white"}
                check={ColorClicked}
                value="1"
                onClick={() => setColorClicked("1")}
              >
                <ClickedColor>백색</ClickedColor>
              </Color>
            </ColorSelect>
            <ColorSelect>
              <Color
                color={"#FCFCDC"}
                check={ColorClicked}
                onClick={() => setColorClicked("2")}
                value="2"
              >
                <ClickedColor>아이보리</ClickedColor>
              </Color>
            </ColorSelect>
            <ColorSelect>
              <Color
                value="3"
                color={"#FFFF9A"}
                check={ColorClicked}
                onClick={() => setColorClicked("3")}
              >
                <ClickedColor>요구르트</ClickedColor>
              </Color>
            </ColorSelect>
            <ColorSelect>
              <Color
                value="4"
                color={"#FFFF77"}
                check={ColorClicked}
                onClick={() => setColorClicked("4")}
              >
                <ClickedColor>연노랑</ClickedColor>
              </Color>
            </ColorSelect>
            <ColorSelect>
              <Color
                value="5"
                color={"#FCFC00"}
                check={ColorClicked}
                onClick={() => setColorClicked("5")}
              >
                <ClickedColor>노랑</ClickedColor>
              </Color>
            </ColorSelect>
            <ColorSelect>
              <Color
                value="6"
                color={"#DEDEB8"}
                check={ColorClicked}
                onClick={() => setColorClicked("6")}
              >
                <ClickedColor>회갈색</ClickedColor>
              </Color>
            </ColorSelect>
          </Fragment>
        </ColorBar>
        <MoreColor onClick={() => setMoreClick(!MoreClick)}>
          색상 더보기
          <RiArrowRightSLine
            size={16}
            style={{ marginLeft: "5px", marginBottom: "1px" }}
          />
        </MoreColor>
        {MoreClick && (
          <MoreColorSelect
            setColorClicked={setColorClicked}
            ColorClicked={ColorClicked}
          />
        )}
        <CheckContainer>
          <MixChoose>
            <Choose
              value="1"
              mixs={MixClicked}
              onClick={() => setMixClicked("1")}
            >
              잘안섞임
            </Choose>

            <Choose
              value="2"
              mixs={MixClicked}
              onClick={() => setMixClicked("2")}
            >
              보통
            </Choose>

            <Choose
              value="3"
              mixs={MixClicked}
              onClick={() => setMixClicked("3")}
            >
              잘섞임
            </Choose>
          </MixChoose>
          <MixDetail>
            {MixClicked == 1 && (
              <DetailText>침전물이 잘 섞이지않습니다.</DetailText>
            )}
            {MixClicked == 2 && (
              <DetailText>섞임 정도가 보통입니다.</DetailText>
            )}
            {MixClicked == 3 && <DetailText>침전물이 잘 섞입니다.</DetailText>}
          </MixDetail>
        </CheckContainer>
      </ColorBarContainer>
    </Fragment>
  );
}

export default ColorSelectLogic;
const DetailText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #4d75f2;
`;
const CheckContainer = styled.div`
  margin-top: 40px;
  width: 100%;
`;
const MixDetail = styled.div`
  margin-top: 30px;
`;
const MixChoose = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const MoreColor = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  color: #b5bbc7;
  margin-top: 10px;
  font-size: 14px;
`;
const ClickedColor = styled.div`
  /* @media (max-width: 640px) {
    border: ${(props) => (props.check === props.value ? "2px solid gray" : "")};
    padding: 3px;
    border-radius: 10px;
  } */
`;
const MixTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  font-size: 16px;
  @media (max-width: 640px) {
    font-size: 13px;
  }
`;
const MixBarTitle = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 10px;

  @media (max-width: 640px) {
    width: 300px;
  }
`;
const Choose = styled.div`
  cursor: pointer;
  width: 80px;
  height: 80px;
  /* border: 2px solid #f5f6f8; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 50%;
  color: ${(props) => (props.mixs === props.value ? "white" : "#b5bbc7")};
  background-color: ${(props) =>
    props.mixs === props.value ? "#4d75f2" : "#f5f6f8"};
  @media (max-width: 640px) {
    width: 55px;
    height: 55px;
    font-size: 13px;
  }
`;
const Mix = styled.div`
  /* margin-top: 50px; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 10px;
`;
const MixBar = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 10px;
  border: none;
  border: 2px solid lightgray;
  border-radius: 20px;
  @media (max-width: 640px) {
    width: 300px;
    margin-top: 30px;
  }
`;
const Select = styled(GoTriangleDown)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  color: ${(props) => (props.colors === props.value ? "#b5bbc7" : "white")};
  &:hover {
    color: lightgray;
  }
  @media (max-width: 640px) {
    display: none;
  }
`;
const SelectBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
`;
const ColorSelect = styled.div`
  display: flex;
  outline: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Color = styled.div`
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  background-color: ${(props) => props.color};
  @media (max-width: 640px) {
    width: 80px;
    height: 50px;
    margin: 10px;
    border-radius: 10px;
    border: ${(props) =>
      props.check === props.value ? "3px solid #4d75f2" : ""};
    box-sizing: border-box;
  }
`;
const ColorBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 40px;
  border: 1px solid #f5f6f8;
  border-radius: 20px;
  overflow: hidden;
  @media (max-width: 640px) {
    margin-top: 20px;
    width: 100%;
    height: 150px;
    border-radius: none;
    border: none;
    flex-wrap: wrap;
  }
`;
const ColorBarContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 95px;
  box-sizing: border-box;
  @media (max-width: 640px) {
    padding: 0px 10px;
  }
`;
