import React, { useState, Fragment, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { API } from "@aws-amplify/api";
import { AiOutlineLoading } from "react-icons/ai";
import FreeBoardComment from "./FreeBoardComment";
import { AiOutlineUser } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
function FreeBoardPresenter({ PostId }) {
  const User = useSelector((state) => state.user.Data);
  const [Posts, setPosts] = useState([]);
  const [Writer, setWriter] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    GetNoticePost();
  }, []);
  const GetNoticePost = async () => {
    const ApiResponse = await API.get("FreeBoardApi", "/items/");
    setPosts(ApiResponse);
    setIsLoading(true);
  };

  const onDelete = async (e) => {
    const ApiResponse = await API.del("FreeBoardApi", "/items/" + PostId);
    // console.log(ApiResponse);
    history.push("/FreeBoard");
  };
  const RanderButton = Posts.map((data) => {
    if (PostId == data.id && data.user == User) {
      return (
        <Fragment>
          <ListButton to={`/FreeBoardEdit/:${PostId}`}>수정</ListButton>
          <FuncButton onClick={onDelete}>삭제</FuncButton>
        </Fragment>
      );
    }
  });
  const RanderNotices = Posts.map((data) => {
    if (PostId == data.id) {
      return (
        <Fragment>
          <Helmet>
            <title>내술 노트 | 자유게시판 :: {data.title}</title>
          </Helmet>
          <NoticeTableTitle>
            <TableTitle>
              {data.title}
              <NoticeTime>
                {data.time}
                &nbsp;
                {data.edit}
              </NoticeTime>
            </TableTitle>
            <UserTitle>
              <MyIcon>
                <AiOutlineUser size={32} />
              </MyIcon>
              {data.user}
            </UserTitle>
          </NoticeTableTitle>

          <NoticeArticle dangerouslySetInnerHTML={{ __html: data.article }} />
        </Fragment>
      );
    }
  });
  return (
    <Fragment>
      <NoticeContainer>
        <NoticeWrapper>
          <NoticeTitle to={"/FreeBoard"}>자유게시판</NoticeTitle>
          {IsLoading && (
            <Fragment>
              {RanderNotices}
              <ButtonContainer>
                <ListButton to="/FreeBoard">목록</ListButton>
                <FuncButtonWrapper>{RanderButton}</FuncButtonWrapper>
              </ButtonContainer>
              <FreeBoardComment PostId={PostId} Posts={Posts} />
            </Fragment>
          )}
          {!IsLoading && (
            <Fragment>
              <LoadingContainer>
                <LoadingIcon>
                  <AiOutlineLoading size={32} />
                </LoadingIcon>
              </LoadingContainer>
            </Fragment>
          )}
        </NoticeWrapper>
      </NoticeContainer>
    </Fragment>
  );
}

export default FreeBoardPresenter;
const FuncButtonWrapper = styled.div`
  display: flex;
`;
const MyIcon = styled.div`
  background-color: lightgray;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 10px;
`;
const UserTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  color: gray;
  font-weight: 580;
`;
const rotation = keyframes`
    0% {
      transform: rotate(0deg);
      opacity: 0.1;
    }
    25% {
      transform: rotate(90deg);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.1;
    }
    75% {
      transform: rotate(360deg);
      opacity: 1;
    }
  `;
const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoadingIcon = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotation} 1s infinite linear;
`;
const NoticeTime = styled.div`
  font-size: 13px;
  color: black;
  font-weight: 500;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FuncButton = styled.button`
  text-decoration: none;
  outline: none;
  cursor: pointer;
  padding: 0px;
  margin-top: 20px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
  border-radius: 5px;
  background-color: white;
  color: black;
  /* font-weight: bold; */
  border: 1px solid #f2f2f2;
  margin-left: 10px;
  font-family: "Noto Sans KR", sans-serif;
`;
const ListButton = styled(Link)`
  text-decoration: none;
  margin-top: 20px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
  border-radius: 5px;
  color: black;
  /* font-weight: bold; */
  border: 1px solid #f2f2f2;
`;
const NoticeArticle = styled.div`
  padding: 50px 20px;
  width: 100%;
  min-height: 300px;
  box-sizing: border-box;
  border-bottom: 1px solid lightgray;
`;

const TableTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: black;
  text-decoration: none;
`;

const NoticeTableTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: space-between;
  flex-direction: column;
  padding: 10px 20px;
  margin-top: 20px;
  width: 100%;
  /* height: 70px; */
  border: 1px solid #f2f2f2;
  border-left: none;
  border-right: none;
  box-sizing: border-box;
`;

const NoticeTitle = styled(Link)`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  color: black;
  font-weight: bold;
  margin-bottom: 30px;
  text-decoration: none;
`;
const NoticeWrapper = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* border: 2px solid red; */
  box-sizing: border-box;
`;
const NoticeContainer = styled.div`
  margin-top: 80px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px red solid; */
  box-sizing: border-box;
  padding: 20px 20px;
  @media (max-width: 640px) {
    padding: 0px 10px;
  }
`;
