import React, { useRef, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
/////////////////////////////////////////////////////////////////////////////////
import styled from "styled-components";
import { BsSearch } from "react-icons/bs";
/////////////////////////////////////////////////////////////////////////////////
function SearchBarBlack({ KeywordBoxOnOff, KeywordBoxOff }) {
  const history = useHistory();
  const inputSearch = useRef("");
  if (!window.localStorage.getItem("latestSearchKeyword")) {
    window.localStorage.setItem("latestSearchKeyword", "KeyWordList$%");
  }
  const [text, setText] = useState("");

  const onSubmit = (e) => {
    // alert(inputSearch.current);
    e.preventDefault();
    // console.log("onEnter");
    if (KeywordBoxOnOff) {
      KeywordBoxOnOff();
    }
    let Keywords = window.localStorage.getItem("latestSearchKeyword");
    if (Keywords === null) {
      window.localStorage.setItem("latestSearchKeyword", text);
    } else {
      window.localStorage.setItem(
        "latestSearchKeyword",
        Keywords + "," + text
      );
    }

    history.push(`/Search?term=${text}`);
    setText("");
  };

  const onChangeSearch = (e) => {
    // inputSearch.current = e.target.value;
    setText(e.target.value);
  };

  const KeyboardEvt = (e) => {
    if (e && e.keyCode === 13 && e.target.value !== "") {
      onSubmit();
    }
  };
  // onBlur={KeywordBoxOff}

  return (
    <Fragment>
      <SearchBarContainer onSubmit={onSubmit}>
        <SearchIcon onClick={onSubmit}>
          <BsSearch size={24} />
        </SearchIcon>
        <SearchBox
          placeholder="검색어를 입력하세요."
          onChange={onChangeSearch}
          onKeyPress={KeyboardEvt}
          onClick={KeywordBoxOnOff}
          value={text}
        />
      </SearchBarContainer>
    </Fragment>
  );
}
export default SearchBarBlack;

const SearchIcon = styled.div`
  cursor: pointer;
  color: black;
`;

const SearchBox = styled.input`
  outline: none;
  border: none;
  box-sizing: border-box;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  font-size: 22px;
  /* font-family: "RIDIBatang", sans-serif; */
  background-color: transparent;
  color: black;
  font-weight: bold;
  &::placeholder {
    font-size: 20px;
    color: black;
    font-weight: bold;
    @media (max-width: 850px) {
      font-size: 18px;
    }
  }
`;
const SearchBarContainer = styled.form`
  margin-top: 30px;
  border-bottom: 2px solid black;
  /* border-radius: 30px; */
  /* padding: 0px 15px; */
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 45px;
  box-sizing: border-box;
  @media (max-width: 640px) {
    width: 100%;
  }
`;
