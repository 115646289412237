import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import theme from '../theme';

const Loader = styled(CircularProgress)`
  color: ${theme.palette.primary.dark};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: ${(props) =>
    props && props.size ? (Number(props.size) / 2) * -1 : 0}px;
  margin-left: ${(props) =>
    props && props.size ? (Number(props.size) / 2) * -1 : 0}px;
`;

export { Loader };
