import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { API } from "@aws-amplify/api";
import { useHistory } from "react-router-dom";
import SingleComment from "./SingleComment";
function FreeBoardComment({ PostId, Posts }) {
  const history = useHistory();

  const User = useSelector((state) => state.user.Data);
  const UserEmail = useSelector((state) => state.user.Email);
  const [text, setText] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  const [Comments, setComments] = useState([]);
  useEffect(() => {
    GetComment();
  }, []);
  const GetComment = async () => {
    const ApiResponse = await API.get("FreeBoardCommentApi", "/items/");
    if (ApiResponse) {
      setIsLoading(true);
    }
    setComments(ApiResponse);
  };
  const onSubmit = async (e) => {
    let date = new Date();
    let UploadTime =
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + date.getDate()).slice(-2) +
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2);
    if (User) {
      e.preventDefault();
      const ApiResponse = await API.post("FreeBoardCommentApi", "/items/", {
        body: {
          id: Number(Date.now()),
          text: text,
          user: User,
          postId: PostId,
          email: UserEmail,
          time: UploadTime,
        },
      });
      // console.log(ApiResponse);
      setText("");
      window.location.reload();
    } else {
      alert("로그인 후 이용가능합니다.");
      history.push("/Signin");
    }
  };
  return (
    <Fragment>
      <CommentInput>
        <Input
          placeholder="댓글을 남기세요."
          onChange={(e) => setText(e.target.value)}
          value={text}
        ></Input>
        <ButtonBox>
          <Button onClick={onSubmit}>등록</Button>
        </ButtonBox>
      </CommentInput>
      <CommentsBox>
        <SingleComment
          CommentsList={Comments}
          PostId={PostId}
          UserInfo={User}
          Posts={Posts}
        />
      </CommentsBox>
    </Fragment>
  );
}

export default FreeBoardComment;
const CommentsBox = styled.div`
  width: 100%;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Button = styled.button`
  cursor: pointer;
  outline: none;
  width: 90px;
  height: 40px;
  border-radius: 4px;
  margin-top: 20px;
  border: 1px solid #ededed;
  color: gray;
  padding-bottom: 0px;
  padding-top: 0px;
  font-size: 16px;
  font-family: "Noto Sans KR", sans-serif;
  &:hover {
    opacity: 0.8;
  }
`;
const CommentInput = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-top: 20px;
`;
const Input = styled.textarea`
  resize: none;
  box-sizing: border-box;
  min-height: 40px;
  width: 100%;
  height: 70px;
  outline: none;
  word-break: break-all;
  border-radius: 4px;
  border: 1px solid #ededed;
  padding: 8px;
  font-size: 16px;
  &::placeholder {
    color: lightgray;
    font-size: 16px;
    font-family: "Noto Sans KR", sans-serif;
  }
  &:hover {
    &::placeholder {
      color: gray;
    }
  }
  @media (max-width: 640px) {
    margin-left: 0px;
  }
`;
