import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { API } from "@aws-amplify/api";
import { AiOutlineLike } from "react-icons/ai";
import { AiFillLike } from "react-icons/ai";
import { useSelector } from "react-redux";
function LikeLogic({ PostId, UserInfo, CommentId }) {
  const history = useHistory();
  let UserEmail = useSelector((state) => state.user.Email);
  // console.log(Number(UserId));
  useEffect(() => {
    GetLikeFunc();
  }, [Liked]);
  const [GetLiked, setGetLiked] = useState([]);
  const [Liked, setLiked] = useState(false);
  const [LikeId, setLikeId] = useState("");
  const [LikeNum, setLikeNum] = useState(0);
  let a = [];
  const GetLikeFunc = async () => {
    const ApiResponse = await API.get(
      "CommentLikeApi",
      "/CommentLikeItems/",
      PostId
    );
    await ApiResponse.map((data) => {
      if (PostId === data.postId) {
        if (data.CommentId === CommentId) {
          a.push("1");
          setGetLiked(ApiResponse);
          // console.log(CommentId);
          if (data.user === UserEmail) {
            setLikeId(data.id);
            setLiked(true);
          }
        }
      }
    });
    setLikeNum(a.length);
  };
  // console.log("Id" + CommentId + "," + "num :" + LikeNum);
  // console.log(LikeId);

  const setLikeFunc = async (e) => {
    if (Liked) {
      if (UserInfo) {
        e.preventDefault();
        let id = Number(LikeId);
        // console.log(id);
        const ApiResponse = await API.del(
          "CommentLikeApi",
          "/CommentLikeItems/" + id
        );
        // console.log(ApiResponse);
        setLiked(!Liked);
        setLikeNum(LikeNum - 1);
        GetLikeFunc();
      }
      if (!UserEmail) {
        alert("로그인 후 이용가능합니다.");
        history.push("/Signin");
      }
    }
    if (!Liked) {
      if (UserEmail) {
        e.preventDefault();
        const ApiResponse = await API.post(
          "CommentLikeApi",
          "/CommentLikeItems/",
          {
            body: {
              id: Number(Date.now()),
              user: UserEmail,
              CommentId: CommentId,
              postId: PostId,
            },
          }
        );
        setLiked(!Liked);
        setLikeNum(LikeNum + 1);
        // console.log(ApiResponse);
        GetLikeFunc();
      }
    }
    if (!UserEmail) {
      alert("로그인 후 이용가능합니다.");
      history.push("/Signin");
    }
  };

  return (
    <Fragment>
      <LikeContainer>
        <LikeButton onClick={setLikeFunc}>
          {Liked && <FillLike size={20} />}
          {!Liked && <OutlineLike size={20} />}
        </LikeButton>
        <LikeNumber>{LikeNum !== 0 && LikeNum}</LikeNumber>
      </LikeContainer>
    </Fragment>
  );
}

export default LikeLogic;
// const Shine = keyframes`
//     0% {
//       opacity: 0.1;
//     }
//     100% {
//       opacity: 1;
//     }
//   `;
const FillLike = styled(AiFillLike)`
  pointer-events: none;
  color: #666775;
`;
const OutlineLike = styled(AiOutlineLike)`
  pointer-events: none;
  color: #666775;
`;
const LikeContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18); */
  /* padding: 5px; */
  box-sizing: border-box;
  /* min-width: 30px;
  max-width: 30px;
  border-radius: 10px; */
  /* width: 100px; */
`;
const LikeNumber = styled.div`
  color: #666775;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: "Helvetica Neue", sans-serif; */
  font-size: 14px;
  font-family: "Noto Sans KR", sans-serif;
  /* font-weight: bold; */
`;
const LikeButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;
