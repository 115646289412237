import React, { Fragment, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { Data } from "./ColorSelectData";
function MoreColorSelect({ setColorClicked, ColorClicked }) {
  const RanderColor = Data.map((data) => {
    return (
      <Color
        value={data.value}
        ColorClicked={ColorClicked}
        onClick={(e) => setColorClicked(e.target.value)}
      >
        {data.name}
      </Color>
    );
  });
  return (
    <Fragment>
      <ColorContainer>{RanderColor}</ColorContainer>
    </Fragment>
  );
}

export default MoreColorSelect;
const FadeIn = keyframes`
    0% {
     opacity:0;
    };
    1OO% {
      opacity: 1;
    }
  `;
const Color = styled.button`
  outline: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  font-size: 0px;
  opacity: 1;
  border: none;
  background-color: ${(props) => props.value};
  animation: ${(props) =>
    props.value === props.ColorClicked
      ? css`
          ${FadeIn} 1.2s infinite linear
        `
      : "none"};
  &:hover {
    transform: scale(1.8);
    border-radius: 10px;
    font-size: 14px;
  }
`;
const ColorContainer = styled.div`
/* animation:${FadeIn} 1.5s  infinite ease-in */
  display: grid;
  margin-top: 30px;
  width: 300px;
  height: 300px;
  grid-template-rows: repeat(5, 60px);
  grid-template-columns: repeat(5, 60px);
  /* border: 1px solid lightgray; */
  box-sizing:border-box;
  /* border-radius:10px; */
  /* overflow:hidden; */
`;
