import React, { Fragment, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
// import { Review } from "./PostDummy";
import { AiOutlineUser } from "react-icons/ai";
import { API } from "@aws-amplify/api";
import { Link } from "react-router-dom";
function ReviewPresenter({ PostId }) {
  useEffect(() => {
    GetReview();
  }, []);
  const [ReviewedData, setReviewedData] = useState([]);
  const [DiscSub1, setDiscSub1] = useState(false);
  const [DiscSub2, setDiscSub2] = useState(false);

  const GetReview = async () => {
    const ReviewApiResponse = await API.get("AlcoholReviewApi", "/items/");
    setReviewedData(ReviewApiResponse.filter((data) => data.postId == PostId));
    // console.log("PostId" + PostId);
  };
  // console.log("PostId" + PostId);
  // console.log(ReviewedData);
  const RanderReview = ReviewedData.map((data) => {
    if (ReviewedData) {
      if (data.postId == PostId) {
        return (
          <Fragment>
            <DataBox>
              <UserInfo>
                <UserIcon>
                  <AiOutlineUser size={20} style={{ color: "white" }} />
                </UserIcon>
                <User>{data.user}</User>
              </UserInfo>
              <ValueBox>
                <ValueTitle>당도</ValueTitle>
                {data.sweetClicked == 20 && (
                  <DetailText>술이 달지 않습니다.</DetailText>
                )}
                {data.sweetClicked == 40 && (
                  <DetailText>술이 안 달지는 않습니다.</DetailText>
                )}
                {data.sweetClicked == 60 && (
                  <DetailText>술이 적당히 달다고 느껴집니다.</DetailText>
                )}
                {data.sweetClicked == 80 && (
                  <DetailText>술이 달다고 느껴집니다.</DetailText>
                )}
                {data.sweetClicked == 100 && (
                  <DetailText>술이 많이 달다고 느껴집니다.</DetailText>
                )}
              </ValueBox>
              <ValueBox>
                <ValueTitle>산도</ValueTitle>
                {data.acidClicked == 20 && (
                  <DetailText>술에 신맛이 없습니다.</DetailText>
                )}
                {data.acidClicked == 40 && (
                  <DetailText>술에 신맛이 조금 느껴집니다.</DetailText>
                )}
                {data.acidClicked == 60 && (
                  <DetailText>술에 적당한 신맛이 느껴집니다.</DetailText>
                )}
                {data.acidClicked == 80 && (
                  <DetailText>술이 시다고 느껴집니다.</DetailText>
                )}
                {data.acidClicked == 100 && (
                  <DetailText>술이 많이 시다고 느껴집니다.</DetailText>
                )}
              </ValueBox>
              <ValueBox>
                <ValueTitle>쓴맛</ValueTitle>
                {data.alcoholClicked == 33 && (
                  <DetailText>술에 쓴맛이 없습니다.</DetailText>
                )}
                {data.alcoholClicked == 66 && (
                  <DetailText>술의 쓴맛이 보통입니다.</DetailText>
                )}
                {data.alcoholClicked == 100 && (
                  <DetailText>술이 쓰다고 느껴집니다.</DetailText>
                )}
              </ValueBox>
              <ValueBox>
                <ValueTitle>무게감</ValueTitle>
                {data.weightClicked == 20 && (
                  <DetailText>술이 물같이 느껴집니다.</DetailText>
                )}
                {data.weightClicked == 40 && (
                  <DetailText>술에 점도가 약간 있습니다.</DetailText>
                )}
                {data.weightClicked == 60 && (
                  <DetailText>술의 점도가 보통입니다.</DetailText>
                )}
                {data.weightClicked == 80 && (
                  <DetailText>술의 점도가 보통 이상입니다.</DetailText>
                )}
                {data.weightClicked == 100 && (
                  <DetailText>술이 죽 혹은 시럽처럼 느껴집니다.</DetailText>
                )}
              </ValueBox>
              <ValueBox>
                <ValueTitle>탄산감</ValueTitle>
                {data.carbonatedClicked == 33 && (
                  <DetailText>술에 탄산감이 없습니다.</DetailText>
                )}
                {data.carbonatedClicked == 66 && (
                  <DetailText>술의 탄산감이 보통입니다.</DetailText>
                )}
                {data.carbonatedClicked == 100 && (
                  <DetailText>술이 톡 쏜다고 느껴집니다.</DetailText>
                )}
              </ValueBox>
              <ValueBox>
                <ValueTitle>밸런스</ValueTitle>
                {data.balanceClicked == 25 && (
                  <DetailText>거슬림이 느껴집니다.</DetailText>
                )}
                {data.balanceClicked == 50 && (
                  <DetailText>술이 거슬리지 않습니다.</DetailText>
                )}
                {data.balanceClicked == 75 && (
                  <DetailText>술이 모난곳이 없습니다.</DetailText>
                )}
                {data.balanceClicked == 100 && (
                  <DetailText>술이 완벽하다고 느껴집니다.</DetailText>
                )}
              </ValueBox>
              <ValueBox>
                <ValueTitle>복합성</ValueTitle>
                {data.complexityClicked == 33 && (
                  <DetailText>술의 느낌이 심플합니다.</DetailText>
                )}
                {data.complexityClicked == 66 && (
                  <DetailText>술의 느낌이 무난, 적당합니다.</DetailText>
                )}
                {data.complexityClicked == 100 && (
                  <DetailText>술이 뛰어나다고 느껴집니다.</DetailText>
                )}
              </ValueBox>
              <ValueBox>
                <ValueTitle>풍미</ValueTitle>
                <FlavorWrapper>
                  {data.flavor1 && <DetailText>{data.flavor1}</DetailText>}
                  {data.flavor2 && (
                    <DetailText>,&nbsp;{data.flavor2}</DetailText>
                  )}
                  {data.flavor3 && (
                    <DetailText>,&nbsp;{data.flavor3}</DetailText>
                  )}
                </FlavorWrapper>
              </ValueBox>
              <ValueBox>
                <ValueTitle>코멘트</ValueTitle>
                <MoreTextBox>
                  {!DiscSub1 && data.comment.length > 33 && (
                    <DetailText>
                      {data.comment.substring(0, 33) + "..."}
                    </DetailText>
                  )}
                  {DiscSub1 && <DetailText>{data.comment}</DetailText>}
                  {!DiscSub1 && data.comment.length < 33 && (
                    <DetailText>{data.comment}</DetailText>
                  )}

                  {!DiscSub1 && data.comment.length > 33 && (
                    <MoreButton onClick={() => setDiscSub1(!DiscSub1)}>
                      더보기
                    </MoreButton>
                  )}
                  {DiscSub1 && data.comment.length > 33 && (
                    <MoreButton onClick={() => setDiscSub1(!DiscSub1)}>
                      닫기
                    </MoreButton>
                  )}
                </MoreTextBox>
              </ValueBox>
            </DataBox>
          </Fragment>
        );
      }
    }
  });
  return (
    <Fragment>
      <FlexWrapper>
        <Title>
          사용자 리뷰 {ReviewedData.length !== 0 && ReviewedData.length}
        </Title>
        <Fragment>
          {ReviewedData.length >= 1 && (
            <ReviewedDataBox check={ReviewedData.length}>
              {RanderReview}
            </ReviewedDataBox>
          )}
          {ReviewedData.length == 0 && (
            <NoReviewedDataBox>
              <NoData>
                등록된 리뷰가 없습니다.
                <br />
                평가 작성하기를 눌러 노트를 작성해보세요.
              </NoData>
              <LinkToUpload to={`/PostUpload/:${PostId}`}>
                평가 작성하기
              </LinkToUpload>
            </NoReviewedDataBox>
          )}
        </Fragment>
      </FlexWrapper>
    </Fragment>
  );
}

export default ReviewPresenter;
const FlexWrapper = styled.div`
  margin-right: 20px;
`;
const MoreTextBox = styled.div``;
const MoreButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: lightgray;
  font-weight: bold;
  /* text-decoration: underline; */
`;
const LinkToUpload = styled(Link)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dedede;
  color: #111113;
  font-weight: bold;
  background-color: white;
  min-width: 90px;
  height: 32px;
  text-decoration: none;
  font-size: 12px;
  border-radius: 2px;
  &:hover {
    background-color: black;
    color: white;
  }
`;
const NoData = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
`;
const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const UserIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: lightgray;
  margin-right: 10px;
`;
const FlavorWrapper = styled.div`
  display: flex;
`;
const ValueTitle = styled.div`
  font-size: 13px;
  min-width: 40px;
  font-weight: bold;
  margin-right: 15px;
`;
const DetailText = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: rgb(67, 115, 110);
  @media (max-width: 340px) {
    font-size: 12px;
  }
`;

const ValueBox = styled.div`
  padding: 0px 5px;
  margin: 5px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const DataBox = styled.div`
  padding: 10px 10px;
  /* border-bottom: 1px solid #f2f2f2; */
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  margin-bottom: 15px;
  @media (max-width: 640px) {
    min-width: 80%;
    padding: 10px 10px;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 0px;
  }
`;
const User = styled.div`
  font-size: 14px;
  font-weight: bold;
`;
const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  @media (max-width: 640px) {
    font-size: 18px;
  }
`;
const ReviewedDataBox = styled.div`
  padding: 15px 15px;
  box-sizing: border-box;
  width: 400px;
  height: ${(props) => (props.check === 1 ? "305px" : "500px")};
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  margin-right: 20px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  @media (max-width: 640px) {
    padding: 10px 0px;
    border: none;
    margin-right: 0px;
    width: 100%;
    display: flex;
    height: auto;
  }
`;
const NoReviewedDataBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  width: 302px;
  /* height: 100px; */
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  margin-right: 20px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  @media (max-width: 640px) {
    margin-right: 0px;
    width: 100%;
  }
`;
