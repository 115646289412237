import {
  AUTH_USER,
  TEST_USER,
  USER_EMAIL,
  USER_PHONE,
  USER_ADMIN,
  USER_AGE,
  USER_GENDER,
} from "../_actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, UserInfo: action.payload };
    case TEST_USER:
      return { ...state, Data: action.payload };
    case USER_EMAIL:
      return { ...state, Email: action.payload };
    case USER_PHONE:
      return { ...state, Phone: action.payload };
    case USER_ADMIN:
      return { ...state, Admin: action.payload };
    case USER_AGE:
      return { ...state, Age: action.payload };
    case USER_GENDER:
      return { ...state, Gender: action.payload };

    default:
      return state;
  }
}
