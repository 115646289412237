const Data = [
  { value: "#fff9c4" },
  { value: "#fffde7" },
  { value: "#fff59d" },
  { value: "#fff176" },
  { value: "#ffee58" },
  { value: "#ffeb3b" },
  { value: "#fdd835" },
  { value: "#fbc02d" },
  { value: "#f9a825", name: "살구색" },
  { value: "#f57f17" },
  { value: "#ffff8d" },
  { value: "#ffff00" },
  { value: "#ffea00" },
  { value: "#ffd600" },
  { value: "#fff8e1" },
  { value: "#ffecb3" },
  { value: "#ffe082" },
  { value: "#ffd54f" },
  { value: "#ffca28" },
  { value: "#ffc107" },
  { value: "#ffb300" },
  { value: "#ffa000" },
  { value: "#ff8f00" },
  { value: "#ff6f00" },
  { value: "#ffe57f" },
];
export { Data };
