import React, { Fragment } from "react";
/////////////////////////////////////////////////////////////////////////////////
import styled from "styled-components";
import { AiOutlineToTop } from "react-icons/ai";
/////////////////////////////////////////////////////////////////////////////////

function FloatingItems() {
  const TapToTopFunc = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <Fragment>
      <TapToTop onClick={TapToTopFunc}>
        <AiOutlineToTop size={30} />
      </TapToTop>
      {/*<FloatingBanner></FloatingBanner>*/}
    </Fragment>
  );
}

export default FloatingItems;
// const FloatingBanner = styled.div`
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: fixed;
//   top: 15%;
//   right: 1%;
//   width: 150px;
//   height: 200px;
//   box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18);
//   background-color: white;
//   border-radius: 20px;
// `;
const TapToTop = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 5%;
  right: 1%;
  width: 50px;
  height: 50px;
  border: 1px solid #f2f2f2;
  background-color: white;
  border-radius: 50%;
  z-index: 8888;
`;
