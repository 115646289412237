import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Prompt } from "react-router-dom";
import { useSelector } from "react-redux";
import Helmet from "react-helmet";
/////////////////////////////////////////////////////////
import styled, { keyframes } from "styled-components";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { MdCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
/////////////////////////////////////////////////////////
import CircleChart from "./UploadUtil/CircleChart";
import ColorSelectLogic from "./UploadUtil/ColorSelectLogic";
import Architecture from "./UploadUtil/Architecture";
import Texture from "./UploadUtil/Texture";
import Evaluate from "./UploadUtil/Evaluate";
////////////////////////////////////////

import Storage from "@aws-amplify/storage";

/////////////////////////////////////////////////////////
function UploadByManualMac() {
  const history = useHistory();
  const [SCheck, setSCheck] = useState("0");
  const [CCheck, setCCheck] = useState("0");
  const [ColorClicked, setColorClicked] = useState("1");
  const [MixClicked, setMixClicked] = useState("1");
  const [SweetClicked, setSweetClicked] = useState("1");
  const [AcidClicked, setAcidClicked] = useState("1");
  const [AlcoholClicked, setAlcoholClicked] = useState("1");
  const [WeightClicked, setWeightClicked] = useState("1");
  const [CarbonatedClicked, setCarbonatedClicked] = useState("1");
  const [BalanceClicked, setBalanceClicked] = useState("1");
  const [ComplexityClicked, setComplexityClicked] = useState("1");
  const [Flavor1, setFlavor1] = useState("");
  const [Flavor2, setFlavor2] = useState("");
  const [Flavor3, setFlavor3] = useState("");
  const [Market, setMarket] = useState("");
  const [Name, setName] = useState("");
  const [Price, setPrice] = useState("");
  const [Finish, setFinish] = useState(null);
  const [FlavorAdd, setFlavorAdd] = useState("1");
  const [FocusOn, setFocusOn] = useState("");
  const [Clicked1, setClicked1] = useState("");
  const [Clicked2, setClicked2] = useState("");
  const [Clicked3, setClicked3] = useState("");
  const [ImageFile, setImageFile] = useState("");
  const [Text, setText] = useState("");
  console.log(FocusOn);
  const onClickFlavor1 = () => {
    setFlavorAdd("");
    setClicked1("");
    setFlavor1("");
  };
  const onClickFlavor2 = () => {
    setFlavorAdd("1");
    setFlavor2("");
    setClicked2("");
  };
  const onClickFlavor3 = () => {
    setFlavorAdd("2");
    setFlavor3("");
    setClicked3("");
  };
  const SubmitonClick = async () => {
    await setFinish(true);
    onChange();
    history.push("/PostExplore");
  };
  // {
  //   Price && (
  //     <Prompt
  //       when={prompt}
  //       message="작성중인 게시물이 있습니다. 나가시겠습니까?"
  //     />
  //   );
  // }
  console.log(FlavorAdd);
  // const AddImageFunc = () => {
  //   Amplify.configure({
  //     Auth: {
  //       identityPoolId: "ap-northeast-2:8b16af9d-0e8e-49a8-946a-e053c7cd14a2", //REQUIRED - Amazon Cognito Identity Pool ID
  //       region: "AP_NORTHEAST_2", // REQUIRED - Amazon Cognito Region
  //       userPoolId: "XX-XXXX-X_abcd1234", //OPTIONAL - Amazon Cognito User Pool ID
  //       userPoolWebClientId: "XX-XXXX-X_abcd1234", //OPTIONAL - Amazon Cognito Web Client ID
  //     },
  //     Storage: {
  //       bucket: "bucket195339-dev", //REQUIRED -  Amazon S3 bucket
  //       region: "XX-XXXX-X", //OPTIONAL -  Amazon service region
  //     },
  //   });
  //   Storage.put("test.txt", "Private Content", {
  //     level: "private",
  //     contentType: "text/plain",
  //   })
  //     .then((result) => console.log(result))
  //     .catch((err) => console.log(err));
  // };
  const onChange = async () => {
    let id = Number(Date.now());
    const { key } = await Storage.put(`${id}.png`, ImageFile, {
      level: "public",
      contentType: "image/png",
    });

    console.log("S3 Object key", key);
  };
  return (
    <Fragment>
      <Helmet>
        <title>내술 노트 | 노트 작성 : 막걸리</title>
      </Helmet>
      <UploadContainer>
        <UploadBox>
          <TasteNote>테이스팅 노트</TasteNote>
          <TasteCategory>(막걸리)</TasteCategory>
          <InfoBox>
            <MainTitle>
              <MainValue>
                Ⅰ. 기본 정보
                <QuestionIcon
                  onMouseEnter={() => {
                    setFocusOn("1");
                  }}
                  onMouseLeave={() => {
                    setFocusOn("");
                  }}
                >
                  <AiOutlineQuestionCircle size={24} />
                </QuestionIcon>
              </MainValue>
              {FocusOn === "1" && (
                <QuestionBox>
                  <ul>
                    <li>순미주 : 쌀, 국(누룩, 곡자), 물로만 빚은 술</li>
                    <p />
                    <li>
                      첨가물 : 아스파탐, 아세설팜칼륨, 스테비오사이드 등 -
                      합성감미료
                    </li>
                  </ul>
                  <p>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp;&nbsp; 물엿, 과당 등 - 비발효당 &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </p>
                  <p>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;구연산 등 - 산도조절제
                  </p>
                </QuestionBox>
              )}
            </MainTitle>

            <BasicInfoContainer>
              <PicWrapper>
                <BasicPicThumbBox>
                  <AiOutlinePlus size={48} />
                </BasicPicThumbBox>
                <BasicPic
                  type="file"
                  accept="image/png"
                  onChange={(e) => setImageFile(e.target.files[0])}
                ></BasicPic>
              </PicWrapper>
              <BasicInfo>
                <TextInput
                  placeholder="제품명"
                  value={Name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <TextInput
                  placeholder="구입처"
                  value={Market}
                  onChange={(e) => {
                    setMarket(e.target.value);
                  }}
                />
                <TextInput
                  placeholder="구입가격"
                  value={Price}
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                />
              </BasicInfo>
              <CheckBoxContainer>
                <BoxWrapper>
                  <CheckTitle>순미주 여부</CheckTitle>
                  <CheckBoxWrapper>
                    {SCheck === "1" && (
                      <MdCheckBox size={24} onClick={() => setSCheck("0")} />
                    )}
                    {SCheck === "0" && (
                      <MdCheckBoxOutlineBlank
                        size={24}
                        onClick={() => setSCheck("1")}
                      />
                    )}
                  </CheckBoxWrapper>
                </BoxWrapper>
                <BoxWrapper>
                  <CheckTitle>첨가물 여부</CheckTitle>
                  <CheckBoxWrapper>
                    {CCheck === "1" && (
                      <MdCheckBox size={24} onClick={() => setCCheck("0")} />
                    )}
                    {CCheck === "0" && (
                      <MdCheckBoxOutlineBlank
                        size={24}
                        onClick={() => setCCheck("1")}
                      />
                    )}
                  </CheckBoxWrapper>
                </BoxWrapper>
              </CheckBoxContainer>
            </BasicInfoContainer>
          </InfoBox>
          <InfoBox>
            <MainTitle>
              <MainValue>
                Ⅱ. 외관
                <QuestionIcon
                  onMouseEnter={() => {
                    setFocusOn("2");
                  }}
                  onMouseLeave={() => {
                    setFocusOn("");
                  }}
                >
                  <AiOutlineQuestionCircle size={24} />
                </QuestionIcon>
              </MainValue>
              {FocusOn === "2" && (
                <QuestionBox>외관 추가정보~~~~~~~~~~~~~~~~</QuestionBox>
              )}
            </MainTitle>

            <ColorSelectLogic
              setColorClicked={setColorClicked}
              setMixClicked={setMixClicked}
              ColorClicked={ColorClicked}
              MixClicked={MixClicked}
            />
          </InfoBox>
          <InfoBox>
            <MainTitle>
              <MainValue>
                Ⅲ. 구조
                <QuestionIcon
                  onMouseEnter={() => {
                    setFocusOn("3");
                  }}
                  onMouseLeave={() => {
                    setFocusOn("");
                  }}
                >
                  <AiOutlineQuestionCircle size={24} />
                </QuestionIcon>
              </MainValue>
              {FocusOn === "3" && (
                <QuestionBox>
                  <ul>
                    <li>
                      당도 : 입에서 느껴지는 잔당감으로 판별. 합성감미료의 단
                      맛은 삼킨 후에도 입 안을 코팅하는 듯한 단 맛으로,
                      자연적으로 발생하는 잔당(Residual Sugar) 와는 관능적으로
                      차이가 있다.
                    </li>
                    <p />
                    <li>
                      산미 : 신 맛의 정도(산도 와는 다른 기준임, 산도는 삼킨 후
                      입에 고이는 침의 양과 정도로 판별)
                    </li>
                    <p />
                    <li>
                      알코올 느낌 : 객관적인 알코올의 양은 Alcohol Warmth로 표현
                      되며 입 안과 식도를 타고 넘어가는 뜨거운 느낌으로 판별.
                      내술노트에서 ‘알코올 느낌’ 은 알코올의 양과는 별개의
                      알코올 부즈에 가까운 느낌으로 보면 되겠고, 술에서 느껴지는
                      알코올의 향 또는 맛의 강도를 판별.
                    </li>
                  </ul>
                </QuestionBox>
              )}
            </MainTitle>

            <Architecture
              SweetClicked={SweetClicked}
              setSweetClicked={setSweetClicked}
              AcidClicked={AcidClicked}
              setAcidClicked={setAcidClicked}
              AlcoholClicked={AlcoholClicked}
              setAlcoholClicked={setAlcoholClicked}
            />
          </InfoBox>
          <InfoBox>
            <MainTitle>
              <MainValue>
                Ⅳ. 감촉
                <QuestionIcon
                  onMouseEnter={() => {
                    setFocusOn("4");
                  }}
                  onMouseLeave={() => {
                    setFocusOn("");
                  }}
                >
                  <AiOutlineQuestionCircle size={24} />
                </QuestionIcon>
              </MainValue>
              {FocusOn === "4" && (
                <QuestionBox>
                  <ul>
                    <li>
                      무게감 : 바디감과 거의 같은 의미. 총체적인 술의 질감과
                      무게감의 정성적 합.
                    </li>
                  </ul>
                </QuestionBox>
              )}
            </MainTitle>
            <Texture
              WeightClicked={WeightClicked}
              setWeightClicked={setWeightClicked}
              CarbonatedClicked={CarbonatedClicked}
              setCarbonatedClicked={setCarbonatedClicked}
            />
          </InfoBox>
          <InfoBoxFlavor>
            <MainTitle>
              <MainValue>
                Ⅴ. 풍미
                <QuestionIcon
                  onMouseEnter={() => {
                    setFocusOn("5");
                  }}
                  onMouseLeave={() => {
                    setFocusOn("");
                  }}
                >
                  <AiOutlineQuestionCircle size={24} />
                </QuestionIcon>
              </MainValue>
              {FocusOn === "5" && (
                <QuestionBox>풍미 추가정보~~~~~~~~~~~~~~~~</QuestionBox>
              )}
            </MainTitle>

            <ChartContainer>
              <ChartBox control={Flavor1}>
                {FlavorAdd === "1" && (
                  <Fragment>
                    <div></div>
                    <CircleChart
                      Flavor={Flavor1}
                      Flavor2={Flavor2}
                      setFlavor={setFlavor1}
                      setFlavorAdd={setFlavorAdd}
                      FlavorAdd={FlavorAdd}
                      Clicked={Clicked1}
                      setClicked={setClicked1}
                    />
                  </Fragment>
                )}
                {FlavorAdd === "2" && (
                  <AddFlavorBox>
                    <Fragment>
                      <div></div>
                      <CircleChart
                        Flavor={Flavor2}
                        Flavor3={Flavor3}
                        setFlavor={setFlavor2}
                        setFlavorAdd={setFlavorAdd}
                        FlavorAdd={FlavorAdd}
                        Clicked={Clicked2}
                        setClicked={setClicked2}
                      />
                    </Fragment>
                  </AddFlavorBox>
                )}
                {FlavorAdd === "3" && (
                  <AddFlavorBox>
                    <Fragment>
                      <div></div>
                      <CircleChart
                        Flavor={Flavor3}
                        setFlavor={setFlavor3}
                        setFlavorAdd={setFlavorAdd}
                        FlavorAdd={FlavorAdd}
                        Clicked={Clicked3}
                        setClicked={setClicked3}
                      />
                    </Fragment>
                  </AddFlavorBox>
                )}
              </ChartBox>
              {Flavor1 !== "" && (
                <SelectedBox control={Flavor1}>
                  <SelectedArea>
                    {Flavor1 !== "" && (
                      <SelectedFlavor onClick={() => setFlavorAdd("1")}>
                        <SelectedInfo>
                          <SelectColor color={Clicked1.split(",")[1]} />
                          {Clicked1.split(",")[0]} - {Flavor1}
                        </SelectedInfo>
                        {Flavor2 === "" && (
                          <DeleteBox>
                            <DeleteFlavorButton onClick={onClickFlavor1}>
                              <MdClose size={20} />
                            </DeleteFlavorButton>
                          </DeleteBox>
                        )}
                      </SelectedFlavor>
                    )}
                    {Flavor2 !== "" && (
                      <SelectedFlavor onClick={() => setFlavorAdd("2")}>
                        <SelectedInfo>
                          <SelectColor color={Clicked2.split(",")[1]} />
                          {Clicked2.split(",")[0]} - {Flavor2}
                        </SelectedInfo>
                        {Flavor3 === "" && (
                          <DeleteBox>
                            <DeleteFlavorButton onClick={onClickFlavor2}>
                              <MdClose size={20} />
                            </DeleteFlavorButton>
                          </DeleteBox>
                        )}
                      </SelectedFlavor>
                    )}
                    {Flavor3 !== "" && (
                      <SelectedFlavor onClick={() => setFlavorAdd("3")}>
                        <SelectedInfo>
                          <SelectColor color={Clicked3.split(",")[1]} />
                          {Clicked3.split(",")[0]} - {Flavor3}
                        </SelectedInfo>
                        <DeleteBox>
                          <DeleteFlavorButton onClick={onClickFlavor3}>
                            <MdClose size={20} />
                          </DeleteFlavorButton>
                        </DeleteBox>
                      </SelectedFlavor>
                    )}
                  </SelectedArea>
                </SelectedBox>
              )}
            </ChartContainer>
          </InfoBoxFlavor>
          <InfoBox>
            <MainTitle>
              <MainValue>
                Ⅵ. 평가
                <QuestionIcon
                  onMouseEnter={() => {
                    setFocusOn("6");
                  }}
                  onMouseLeave={() => {
                    setFocusOn("");
                  }}
                >
                  <AiOutlineQuestionCircle size={24} />
                </QuestionIcon>
              </MainValue>
              {FocusOn === "6" && (
                <QuestionBox>
                  <ul>
                    <li>
                      음용성 : 얼마나 부담없이 계속 마실 수 있는가? 의 척도로,
                      가격과는 무관한 술 자체의 풍미로만 판별함. 향이나 맛의
                      강도가 강할 수록 대체로 음용성은 떨어진다.
                    </li>
                  </ul>
                </QuestionBox>
              )}
            </MainTitle>
            <Evaluate
              BalanceClicked={BalanceClicked}
              setBalanceClicked={setBalanceClicked}
              ComplexityClicked={ComplexityClicked}
              setComplexityClicked={setComplexityClicked}
            />
          </InfoBox>
          <InfoBox>
            <MainTitle>
              <MainValue>
                Ⅶ. 코멘트
                <QuestionIcon
                  onMouseEnter={() => {
                    setFocusOn("6");
                  }}
                  onMouseLeave={() => {
                    setFocusOn("");
                  }}
                >
                  <AiOutlineQuestionCircle size={24} />
                </QuestionIcon>
              </MainValue>
              {FocusOn === "6" && <QuestionBox></QuestionBox>}
            </MainTitle>
            <CommentBox
              placeholder="코멘트를 남기세요."
              onChange={(e) => setText(e.target.value)}
              value={Text}
            ></CommentBox>
          </InfoBox>
          <SubmitContainer onClick={SubmitonClick}>
            <SubmitButton>평가 완료</SubmitButton>
          </SubmitContainer>
        </UploadBox>
      </UploadContainer>
    </Fragment>
  );
}
// Ⅰ Ⅱ Ⅲ Ⅳ Ⅴ Ⅵ

export default UploadByManualMac;
const CommentBox = styled.textarea`
  border: none;
  margin-top: 80px;
  width: 100%;
  height: 200px;
  font-size: 16px;
`;
const PicWrapper = styled.div`
  z-index: 3;
  position: relative;
`;
const TasteCategory = styled.div`
  margin: 0px 0px 20px 0px;
  text-align: center;
  font-size: 20px;
`;
const FadeIn = keyframes`
    0% {
     opacity:0;
    1OO% {
      opacity: 1;
    }
  `;

const MainValue = styled.div`
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const SelectedArea = styled.div`
  min-width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const SelectedInfo = styled.div`
  min-width: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #ebebeb;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 10px;
`;
const SelectColor = styled.div`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
`;
const SelectedFlavor = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${FadeIn} 1s backwards;
`;
const SelectedBox = styled.div`
  width: 50%;
  display: ${(props) => (props.control === "" ? "none" : "")};
  margin-left: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: 640px) {
    margin-top: 20px;
    width: 100%;
    margin-left: 0px;
    align-items: center;
  }
`;
const ChartContainer = styled.div`
  display: flex;
  padding: 0px 100px;
  @media (max-width: 640px) {
    flex-direction: column;
    padding: 0px;
  }
`;
const ChartBox = styled.div`
  margin-top: 50px;
  width: ${(props) => (props.control === "" ? "100%" : "50%")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 640px) {
    width: 100%;
  }
`;
const QuestionBox = styled.div`
  margin-left: 10px;
  min-width: 320px;
  max-width: 600px;
  min-height: 50px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px 50px 10px 10px;
  font-size: 16px;
  background-color: white;
  @media (max-width: 640px) {
    padding: 5px 45px 5px 5px;
    margin-top: 10px;
    margin-left: 0px;
    /* min-width: 230px; */
    max-width: 230px;
    box-sizing: border-box;
  }
`;
const QuestionIcon = styled.div`
  width: 50px;
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 640px) {
    justify-content: center;
  }
`;
const DeleteBox = styled.div`
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const DeleteFlavorButton = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  /* box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.18);
  border-radius: 50%; */
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AddFlavorBox = styled.div``;

const SubmitButton = styled.div`
  cursor: pointer;
  width: 100px;
  height: 40px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SubmitContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TasteNote = styled.div`
  font-weight: 600;
  margin: 30px 0px 10px 0px;
  text-align: center;
  font-size: 28px;
`;
const CheckTitle = styled.div`
  font-size: 18px;
  /* margin-right: 30px; */
`;
const CheckBoxWrapper = styled.div`
  width: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 640px) {
    justify-content: center;
  }
`;
const BoxWrapper = styled.div`
  margin-bottom: 50px;
  /* width: 200px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 640px) {
    margin-bottom: 30px;
  }
`;
const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 200px;
  height: 200px;
  @media (max-width: 640px) {
    margin: 20px 0px;
    height: auto;
  }
`;
const TextInput = styled.input`
  /* margin: 10px 0px; */
  width: 150px;
  font-family: "Noto Sans KR", sans-serif;
  outline: none;
  border: 1px solid gray;
  /* border-bottom: none; */
  border-top: none;
  border-right: none;
  border-left: none;
  height: 30px;
  font-size: 18px;
  color: black;
  &::placeholder {
    text-align: left;
    font-size: 18px;
    color: black;
  }
  @media (max-width: 640px) {
    width: 80%;
  }
`;
const BasicInfo = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 640px) {
    margin: 20px 0px;
    width: 100%;
  }
`;
const BasicInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  @media (max-width: 640px) {
    flex-direction: column;
  }
`;
const BasicPic = styled.input`
  position: relative;
  z-index: 5;
  top: 0;
  /* margin-top: 10px; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  font-size: 13px;
  /* height: 50px; */
  /* background-color: lightgray; */
`;

const BasicPicThumbBox = styled.div`
  position: absolute;
  z-index: 4;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background-color: lightgray;
`;
const MainTitle = styled.div`
  position: absolute;
  z-index: 40;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 22px;
  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

const InfoBoxFlavor = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  background-color: white;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  min-height: 300px;
  width: 100%;
  margin: 10px 0px;
  /* @media (max-width: 640px) {
    padding: 10px;
    min-height: 300px;
  } */
`;
const InfoBox = styled.div`
  background-color: white;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  min-height: 300px;
  width: 100%;
  margin: 10px 0px;
  /* @media (max-width: 640px) {
    padding: 10px;
    min-height: 300px;
  } */
`;
const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 700px;
  border: 1px solid #ebebeb;
  padding: 10px 50px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px 10px;
    box-shadow: none;
  }
  @media (max-width: 640px) {
    box-shadow: none;
    padding: 10px 10px;
    min-height: 400px;
  }
`;
const UploadContainer = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px solid red; */
  box-sizing: border-box;
  padding: 0px 50px;
  @media (max-width: 768px) {
    padding: 0px 10px;
  }
  @media (max-width: 640px) {
    width: 100%;
    padding: 0px;
    margin-top: 60px;
  }
`;
